/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

export const MUTATIONS = {
  SET_SHOW_LOADER: 'SET_SHOW_LOADER',
  SET_CUSTOM_LOADER_TEXT_KEY: 'SET_CUSTOM_LOADER_TEXT_KEY',
  SET_SUBMIT_ERRORS: 'SET_SUBMIT_ERRORS',
  SET_CONFIRMED_APPLICATION_UUID: 'SET_CONFIRMED_APPLICATION_UUID',
  RESET_STATE: 'RESET_STATE',
}

export const state = () => ({
  showLoader: false,
  customLoaderTextKey: null,
  submitErrors: [],
  confirmedApplicationUuid: null,
})

export const getters = {
  loaderTextKey(state) {
    return state.customLoaderTextKey || 'newApplication.labels.loaderText'
  },
}

export const mutations = {
  [MUTATIONS.SET_SHOW_LOADER](state, showLoader) {
    state.showLoader = showLoader
  },
  [MUTATIONS.SET_CUSTOM_LOADER_TEXT_KEY](state, customLoaderTextKey) {
    state.customLoaderTextKey = customLoaderTextKey
  },
  [MUTATIONS.SET_SUBMIT_ERRORS](state, submitErrors) {
    state.submitErrors = submitErrors
  },
  [MUTATIONS.SET_CONFIRMED_APPLICATION_UUID](state, confirmedApplicationUuid) {
    state.confirmedApplicationUuid = confirmedApplicationUuid
  },
  [MUTATIONS.RESET_STATE](prevState) {
    Object.assign(prevState, state())
  },
}

export const actions = {
  setShowLoader({ commit }, showLoader) {
    commit(MUTATIONS.SET_SHOW_LOADER, showLoader)
  },
  setCustomLoaderTextKey({ commit }, customLoaderTextKey) {
    commit(MUTATIONS.SET_CUSTOM_LOADER_TEXT_KEY, customLoaderTextKey)
  },
  setLoaderWithTextKey({ dispatch }, { showLoader, textKey }) {
    dispatch('setShowLoader', showLoader)
    dispatch('setCustomLoaderTextKey', textKey || null)
  },
  setSubmitErrors({ commit }, submitErrors) {
    commit(MUTATIONS.SET_SUBMIT_ERRORS, submitErrors)
  },
  setConfirmedApplicationUuid({ commit }, confirmedApplicationUuid) {
    commit(MUTATIONS.SET_CONFIRMED_APPLICATION_UUID, confirmedApplicationUuid)
  },
  resetState({ commit }) {
    commit(MUTATIONS.RESET_STATE)
  },
}
