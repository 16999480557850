/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import SalesReportFilterOptions from '~/models/sales-report/SalesReportFilterOptions'
import SalesReportFilterSelection from '~/models/sales-report/SalesReportFilterSelection'
import { setItem, getItem, STORAGE_TYPE } from '~/utils/StorageUtils'

export const STORAGE_KEY = {
  FILTER_OPTIONS: 'sales_report_filter_options',
  FILTER_SELECTION: 'sales_report_filter_selection',
  RESULT: 'sales_report_result',
}

export const MUTATIONS = {
  SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
  SET_FILTER_SELECTION: 'SET_FILTER_SELECTION',
  SET_RESULT: 'SET_RESULT',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
}

export const state = () => ({
  filterOptions: null,
  filterSelection: null,
  result: null,
  currentPage: 1,
  pageSize: 30,
})

export const getters = {
  hasData(state) {
    return !!state.filterOptions
      && !!state.filterSelection
      && !!state.result
  },
}

export const mutations = {
  [MUTATIONS.SET_FILTER_OPTIONS](state, filterOptions) {
    state.filterOptions = filterOptions
  },
  [MUTATIONS.SET_FILTER_SELECTION](state, filterSelection) {
    state.filterSelection = filterSelection
  },
  [MUTATIONS.SET_RESULT](state, result) {
    state.result = result
  },
  [MUTATIONS.SET_CURRENT_PAGE](state, currentPage) {
    state.currentPage = currentPage
  },
}

export const actions = {
  setFilterOptions({ commit }, filterOptions) {
    commit(MUTATIONS.SET_FILTER_OPTIONS, filterOptions)
  },
  setFilterSelection({ commit }, filterSelection) {
    commit(MUTATIONS.SET_FILTER_SELECTION, filterSelection)
  },
  setResult({ commit }, result) {
    commit(MUTATIONS.SET_RESULT, result)
  },
  setCurrentPage({ commit }, currentPage) {
    commit(MUTATIONS.SET_CURRENT_PAGE, currentPage)
  },
  pushNextPage({ dispatch, state }, nextPage) {
    const newResult = {
      ...nextPage,
      content: [...state.result.content, ...nextPage.content],
    }
    dispatch('setResult', newResult)
    dispatch('setCurrentPage', state.currentPage + 1)
  },
  storeState({ state }, newWindow) {
    setItem(STORAGE_KEY.FILTER_OPTIONS, state.filterOptions, STORAGE_TYPE.SESSION, newWindow)
    setItem(STORAGE_KEY.FILTER_SELECTION, state.filterSelection, STORAGE_TYPE.SESSION, newWindow)
    setItem(STORAGE_KEY.RESULT, state.result, STORAGE_TYPE.SESSION, newWindow)
  },
  restoreState({ dispatch }) {
    dispatch('setFilterOptions', new SalesReportFilterOptions(getItem(STORAGE_KEY.FILTER_OPTIONS, STORAGE_TYPE.SESSION)))
    dispatch('setFilterSelection', new SalesReportFilterSelection(getItem(STORAGE_KEY.FILTER_SELECTION, STORAGE_TYPE.SESSION)))
    dispatch('setResult', getItem(STORAGE_KEY.RESULT, STORAGE_TYPE.SESSION))
  },
}
