/* eslint-disable no-magic-numbers */
import _ from 'lodash'

import PrometheusService from '~/services/nuxt-api/PrometheusService'
import { EVENT_RESULTS } from '~/constants/prometheus'

export default (context, inject) => {
  inject('prometheus', {
    broadcast(name, payload = {}) {
      PrometheusService.broadcast(name, payload).catch((error) => {
        // Failing Prometheus brodcast should die quietly
        console.error(error)
      })
    },

    getResultType(error) {
      const status = _.get(error, 'status')
      if (status) {
        if (status >= 500) {
          return EVENT_RESULTS.SERVER_ERROR
        }
        if (status >= 400) {
          return EVENT_RESULTS.CLIENT_ERROR
        }
      }
      return EVENT_RESULTS.APP_ERROR
    },
  })
}
