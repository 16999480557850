import axios from 'axios'
import Vue from 'vue'
import _ from 'lodash'

import LOCALES from '../locales'

const DUMMY_LOCALE = 'zh'

Vue.use({
  install: function (Vue) {
    Vue.prototype.t = function (key, substitions = {}, defaultValue) {
      if (!this.$t || !this.$i18n) {
        // Translation function does not exist. Just return default.
        return defaultValue ? substitute(defaultValue, substitions) : key
      }

      if (this.$i18n.locale === DUMMY_LOCALE) {
        return key
      }

      // get country based translation
      let translated = _.get(LOCALES[this.$env.NODE_COUNTRY_ISO_CODE], `${this.$i18n.locale}.${key}`)
      if (translated) {
       return substitute(translated, substitions)
      } else {
        translated =  this.$t(key, substitions)
      }

      if (translated !== key) {
        return translated
      }

      // No translation for this key. Add key.
      const body = {
        key: {
          name: key,
        },
      }
      if (defaultValue) {
        body.translation = {
          content: defaultValue,
        }
      }
      //If autosync is enabled and phraseapp config contains correct keys, post the translation key
      if (shouldPostTranslationKey()) {
        const baseURL = process.server ? process.env.appServerUrl : ''

        axios({
          baseURL: baseURL, // This is defined only on server side. On client side it will be empty string.
          method: 'post',
          url: '/nuxt-api/translations',
          data: body,
        })
      }

      // Return default
      return defaultValue ? substitute(defaultValue, substitions) : key
    }
  },
})

function substitute(text, substitutions) {
  Object.keys(substitutions).forEach(key => {
    text = text.replace(`{${key}}`, substitutions[key])
  })
  return text
}

function shouldPostTranslationKey() {
  return (
    !!process.env &&
    !!process.env.i18n.autoSync &&
    !!process.env.phraseapp.accessToken &&
    !!process.env.phraseapp.projectId &&
    !!process.env.appServerUrl
  )
}
