import {
  reactive,
  toRefs,
  onBeforeUnmount,
  watch,
  computed,
} from '@nuxtjs/composition-api'

import * as StorageUtils from '~/utils/StorageUtils'

import NotificationService from '~/services/api/NotificationService'

import { Poller } from '~/utils/PollingUtils'

import { useCurrentUser } from '~/use/CurrentUser'

const POLL_NOTIFICATIONS_INTERVAL = 60000

let poller

export function useNotificationFetch(context) {
  const { currentShopUuid, currentUser, deselectShop } = useCurrentUser(context)

  function cancelPoller() {
    if (poller) {
      poller.cancel()
      poller = null
    }
  }

  async function fetchNotifications() {
    context.root.$store.dispatch('notifications/setIsLoading', true)
    try {
      const notifications = await NotificationService.getNotifications(currentShopUuid.value)
      context.root.$store.dispatch('notifications/setNotifications', notifications)
    } catch (error) {
      // Nothing to do here
      console.error(error)
    }
    context.root.$store.dispatch('notifications/setIsLoading', false)
  }

  async function startNotificationFetch() {
    cancelPoller()
    poller = new Poller({
      fetchMethod: fetchNotifications,
      validator: () => ({ hasFinished: false }),
      timeout: () => POLL_NOTIFICATIONS_INTERVAL,
      autoPauseExecution: true,
    })
    poller.start()
  }

  function stopNotificationFetch() {
    cancelPoller()
    context.root.$store.dispatch('notifications/clearCheckedNotificationIds')
    context.root.$store.dispatch('notifications/clearNotifications')
  }

  async function deselectShopIfNeeded() {
    if (
      currentUser.value
      && currentUser.value.viewingAllShops
      && currentUser.value.tempSelectedShop
    ) {
      try {
        await deselectShop()
      } catch (error) {
        console.error(error)
      }
    }
  }

  return {
    fetchNotifications,
    startNotificationFetch,
    stopNotificationFetch,
    cancelPoller,
    deselectShopIfNeeded,
  }
}

export function useNotificationFetchLifeCycle(context) {
  const { currentShopUuid, currentUser } = useCurrentUser(context)
  const {
    fetchNotifications,
    startNotificationFetch,
    stopNotificationFetch,
    deselectShopIfNeeded,
  } = useNotificationFetch(context)

  const shouldPoll = computed(() => {
    if (!currentUser.value?.user.PERMISSIONS.PP_NOTIFICATIONS.VIEW_NOTIFICATIONS) {
      return false
    }

    const { viewingAllShops } = currentUser.value
    const { path } = context.root.$route

    return currentShopUuid.value && (
      !viewingAllShops
      || (viewingAllShops && path.includes('/notifications'))
    )
  })

  const cancelFn = context.root.$router.beforeEach(async (to, from, next) => {
    next()
    if ([from.path, to.path].some((p) => p.includes('/notifications'))
    && !to.path.includes('/application')) {
      await deselectShopIfNeeded()
    }
  })

  watch([shouldPoll, currentShopUuid],
    async ([nextShould, nextShopUuid], [prevShould, prevShopUuid]) => {
      if ((nextShould !== prevShould) || (nextShopUuid !== prevShopUuid)) {
        if (nextShould && nextShopUuid) {
          await fetchNotifications()
          startNotificationFetch()
        } else {
          stopNotificationFetch()
        }
      }
    })

  onBeforeUnmount(() => {
    cancelFn()
    stopNotificationFetch()
  })
}

// Used for displaying notifications after page load.
// dataVariableNames are mapped into component data variables, accessible in the render context.
export function useAfterReloadNotification(dataVariableNames = []) {
  const data = reactive({})
  dataVariableNames.forEach((name) => {
    data[name] = false
  })

  const getStorageKey = (dataVariableName) => {
    return `tmp_notification_${dataVariableName}`
  }

  const getTmpUuids = (storageKey) => {
    try {
      const uuids = StorageUtils.getItem(storageKey, StorageUtils.STORAGE_TYPE.SESSION)
      if (uuids && uuids instanceof Array) {
        return uuids
      }
    } catch (error) {
      console.error(error)
    }
    return []
  }

  const removeStoredUuid = (storageKey, uuidToRemove) => {
    const uuids = getTmpUuids(storageKey)
    const newUuids = uuids.filter((uuid) => uuid !== uuidToRemove)
    StorageUtils.setItem(storageKey, newUuids, StorageUtils.STORAGE_TYPE.SESSION)
  }

  const setReloadNotification = (dataVariableName, currentUuid) => {
    const storageKey = getStorageKey(dataVariableName)
    const uuids = getTmpUuids(storageKey)
    if (uuids && uuids.includes(currentUuid)) {
      data[dataVariableName] = true
      removeStoredUuid(storageKey, currentUuid)
    }
  }

  const storeTmpUuid = (dataVariableName, uuid) => {
    const storageKey = getStorageKey(dataVariableName)
    const uuids = getTmpUuids(storageKey)
    const newUuids = [...uuids, uuid]
    StorageUtils.setItem(storageKey, newUuids, StorageUtils.STORAGE_TYPE.SESSION)
  }

  return {
    ...toRefs(data),
    storeTmpUuid,
    setReloadNotification,
  }
}

export default {}
