import API from '~/services/api'
import User from '~/models/User'
import LendifyUser from '~/models/LendifyUser'

export default {
  async getCurrentUser(store) {
    const response = await API.get('/users/self')
    const user = new User(response.data)
    if (store) {
      store.dispatch('auth/setUser', user)
    }
    return user
  },
  async refreshToken() {
    const response = await API.post('/auth/refresh_token')
    return response.data
  },
  createPassword(payload) {
    return API.patch('/users/passwords', payload)
  },
  resetPassword(email) {
    return API.post('/users/password_reset_tokens', {
      email,
      channel: 'partner_portal',
    })
  },
  selectShop(uuid, temp) {
    return API.post('/auth/select_shop', { uuid, temp })
  },
  deselectShop() {
    return API.delete('/auth/select_shop')
  },
  changePassword(payload) {
    return API.patch('/current_user/passwords', payload)
  },
  async getUser(uuid) {
    const response = await API.get(`/users/${uuid}`)
    return new LendifyUser(response.data.user)
  },
  async getUsers(uuids) {
    if (uuids.length === 1) {
      const user = await this.getUser(uuids[0])
      return [user]
    }

    const response = await API.get('/users', { params: { uuids } })
    return response.data.map((user) => new LendifyUser(user))
  },
}
