import * as changeCase from 'change-case'

import ProductParameters from '~/models/ProductParameters'
import ProductMinimal from '~/models/ProductMinimal'

export default class Product extends ProductMinimal {
  constructor(args = {}) {
    super(args)
    this.parameters = new ProductParameters(args.parameters)
    this.validFrom = args.validFrom || null
    this.validTo = args.validTo || null
  }

  get applicationRequiredParams() {
    if (!this.parameters.applicationRequiredParams) {
      return []
    }
    return this.parameters.applicationRequiredParams
      .map((param) => {
        const path = param.split('.')
        const camelCasePath = path.map((p) => changeCase.camelCase(p))
        return camelCasePath.join('.')
      })
  }
}
