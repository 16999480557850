<template>
  <div class="d-flex flex-column mb-2">
    <div class="d-flex flex-row">
      <div
        :class="{ small, 'font-weight-medium': labelBold }"
        class="mr-auto"
      >
        {{ label }}:
      </div>

      <div
        :class="{ small }"
        class="text-right"
      >
        <template v-if="bold">
          <strong :class="dataClass">{{ data }}</strong>
        </template>
        <template v-else-if="dataClass">
          <span :class="dataClass">{{ data }}</span>
        </template>
        <template v-else>
          {{ data }}
        </template>
      </div>
    </div>
    <div v-if="sub" class="small ml-auto">
      {{ sub }}
    </div>
    <div v-if="secondarySub" class="small ml-auto">
      {{ secondarySub }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    data: {
      type: String,
      default: null,
    },
    sub: {
      type: String,
      default: null,
    },
    secondarySub: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    dataClass: {
      type: String,
      default: null,
    },
    labelBold: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
