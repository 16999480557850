<template>
  <b-modal
    ref="confirmModal"
    :centered="true"
    tabindex="-1"
    role="dialog"
    footer-class="d-flex justify-content-between"
  >
    <h5 class="text-center">
      {{ text }}
    </h5>
    <div v-if="showCheckbox" class="form-group mb-1">
      <div class="custom-control custom-checkbox js-check-all">
        <input
          id="confirm-modal-checkbox"
          v-model="checked"
          type="checkbox"
          class="custom-control-input"
        >
        <label class="custom-control-label" for="confirm-modal-checkbox">{{ checkboxText }}</label>
      </div>
    </div>
    <div slot="modal-footer" class="col-12">
      <inb-button
        id="confirm"
        variant="primary"
        @click="confirm()"
      >
        {{ confirmButton }}
      </inb-button>
      <inb-button
        id="dismiss"
        variant="outline-primary"
        class="float-right"
        @click="dismiss()"
      >
        {{ cancelButton }}
      </inb-button>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'

import { InbButton } from 'inbank-frontend-commons/components/base-components'
import { onMounted, ref } from '@nuxtjs/composition-api'

export default {
  name: 'ConfirmModalCheckbox',
  components: { InbButton },
  props: {
    bus: {
      type: Vue,
      default: () => new Vue(),
    },
    confirmButton: {
      type: String,
      default: 'OK',
    },
    cancelButton: {
      type: String,
      default: 'Cancel',
    },
    text: {
      type: String,
      default: 'Confirm action?',
    },
    checkboxText: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const showModal = ref(false)
    const checked = ref(true)
    const showCheckbox = ref(true)

    function hideCheckbox() {
      showCheckbox.value = false
      checked.value = false
    }

    function confirm() {
      context.emit('confirmed', { checked: checked.value })
    }

    function dismiss() {
      context.emit('dismissed')
      context.refs.confirmModal.hide()
    }

    function show() {
      context.refs.confirmModal.show()
    }

    onMounted(() => {
      props.bus.$on('hide-checkbox', () => {
        hideCheckbox()
      })

      props.bus.$on('show', () => {
        show()
      })

      props.bus.$on('hide', () => {
        context.refs.confirmModal.hide()
      })
    })
    return {
      showModal,
      checked,
      showCheckbox,
      hideCheckbox,
      confirm,
      dismiss,
      show,
    }
  },
}
</script>
