<template>
  <div id="sms-block" class="row">
    <div class="col-12 mt-3">
      <alert variant="success" icon="icon-check-circle">
        {{ t('signing.message.smsCodeSent', { phoneNumber }) }}.
        <br>
        {{ t('signing.message.enterSmsCode') }}
      </alert>
      <alert v-if="showSmsResent" variant="info" icon="icon-check-circle">
        {{ t('signing.message.smsCodeResent') }}
      </alert>
      <alert v-for="(error, index) in errors" :key="error.text + index" variant="danger" icon="icon-ban">
        {{ error.text }}
      </alert>
    </div>
    <div class="col-12">
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-sm-6 col-md-4">
          <div class="form-group">
            <label for="confirmation-code">{{ t('signing.labels.confirmationCode') }}</label>
            <input id="confirmation-code" :value="value" type="text" class="form-control" @input="$emit('input', $event.target.value)">
          </div>
        </div>
        <div class="col-12 text-center">
          <p class="text-muted">
            {{ t('signing.message.smsNotReceived') }} <a id="resend-sms-btn" href="javascript:void(0)" @click="resendSms()">{{ t('signing.buttons.resendSms') }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const SMS_RESEND_NOTIFICATION_TIMEOUT = 3000

export default {
  name: 'SmsResender',
  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      showSmsResent: false,
      dismissResentTimeout: null,
    }
  },
  methods: {
    resendSms() {
      clearTimeout(this.dismissResentTimeout)

      this.showSmsResent = true
      this.dismissResentTimeout = setTimeout(() => {
        this.showSmsResent = false
      }, SMS_RESEND_NOTIFICATION_TIMEOUT)

      this.$emit('resend')
    },
  },
}
</script>
<style>
  #sms-block{
    width: 100% !important;
  }
</style>
