import _ from 'lodash'
import moment from 'moment'
import { localToIBAN } from 'inbank-frontend-commons/utils/BankAccountUtils'

import ApiModel from '~/models/ApiModel'

import { COUNTRY_CODE } from '~/constants'

function getEmploymentDate(value) {
  if (typeof value === 'string') {
    return value
  }
  return value ? moment(value).format('YYYY-MM') : null
}

function localiseIBAN(value, localisation) {
  if (!localisation) {
    return value
  }
  if (localisation.isoCode() === COUNTRY_CODE.PL) {
    // eslint-disable-next-line no-magic-numbers
    if (!value.substring(0, 2).match(/[a-z]{2}/i)) {
      return `PL${value}`
    }
  }
  if (localisation.isoCode() === COUNTRY_CODE.CZ) {
    return localToIBAN(COUNTRY_CODE.CZ, value)
  }
  return value
}

export default class CreditApplication extends ApiModel {
  constructor(args = {}) {
    super(args)
    this.productCode = args.productCode || null
    this.uuid = args.uuid || null
    this.posSessionUuid = args.posSessionUuid || null
    this.amount = args.amount || null
    this.changedConditions = args.changedConditions != null
      ? args.changedConditions
      : false
    this.civilStatus = args.civilStatus || null
    this.confirmedAt = args.confirmedAt || null
    this.createdAt = args.createdAt || null
    this.creatorName = args.creatorName || null
    this.creditApplicationStatus = args.creditApplicationStatus || null
    this.decisionMessages = args.decisionMessages || []
    this.decisionStatus = args.decisionStatus || null
    this.decisionUuid = args.decisionUuid || null
    this.dependantsCount = args.dependantsCount != null
      ? args.dependantsCount
      : null
    this.dependantsOver_18 = args.dependantsOver_18 != null
      ? args.dependantsOver_18
      : null
    this.downPaymentAmount = args.downPaymentAmount || null
    this.downPaymentAmountPrc = args.downPaymentAmountPrc != null
      ? args.downPaymentAmountPrc
      : null
    this.downPaymentAmountTotal = args.downPaymentAmountTotal || null
    this.employerName = args.employerName || null
    this.employerPhone = args.employerPhone || null
    this.employerRegistryCode = args.employerRegistryCode || null
    this.employmentEndMonth = args.employmentEndMonth || null
    this.employmentEndYear = args.employmentEndYear || null
    this.employmentStartMonth = args.employmentStartMonth || null
    this.employmentStartYear = args.employmentStartYear || null
    this.estimatedMonthlyPayment = args.estimatedMonthlyPayment || null
    this.incomeProofTypes = args.incomeProofTypes || []
    this.housingStatus = args.housingStatus || null
    this.incomeSource = args.incomeSource || null
    this.maxLimit = args.maxLimit != null
      ? args.maxLimit
      : null
    this.monthlyFinancialObligations = args.monthlyFinancialObligations != null
      ? args.monthlyFinancialObligations
      : null
    this.monthlyHouseholdCosts = args.monthlyHouseholdCosts != null
      ? args.monthlyHouseholdCosts
      : null
    this.monthlyIncome = args.monthlyIncome || null
    this.number = args.number || null
    this.offerValidTo = args.offerValidTo || null
    this.paymentDay = args.paymentDay != null
      ? args.paymentDay
      : null
    this.period = args.period != null
      ? args.period
      : null
    this.previousUuid = args.previousUuid || null
    this.processStatus = args.processStatus || null
    this.salespersonReference = args.salespersonReference || null
    // payoutAccountNumber is returned as payoutBankAccount from get application by uuid endpoint
    this.payoutAccountNumber = args.payoutAccountNumber || args.payoutBankAccount || null
    this.einvoiceAccountNumber = args.einvoiceAccountNumber || null
    this.creditPurpose = args.creditPurpose || null
    this.underageDependantsCount = args.underageDependantsCount != null
      ? args.underageDependantsCount
      : null
    this.employmentStart = args.employmentStart || null
    this.employmentEnd = args.employmentEnd || null
    this.employmentDuration = args.employmentDuration || null
    this.employmentPosition = args.employmentPosition || null
    this.downpayment = args.downpayment || null
    this.vehicleMake = args.vehicleMake || null
    this.vehicleModel = args.vehicleModel || null
    this.rentalQuoteUuid = args.rentalQuoteUuid || null
  }

  getApiData(options) {
    const data = super.getApiData(options)
    if (data.amount != null) {
      // eslint-disable-next-line no-magic-numbers
      data.amount = _.round(data.amount, 2)
    }
    if (data.employmentStart) {
      data.employmentStart = getEmploymentDate(data.employmentStart)
    }
    if (data.employmentEnd) {
      data.employmentEnd = getEmploymentDate(data.employmentEnd)
    }
    if (data.payoutAccountNumber) {
      data.payoutAccountNumber = localiseIBAN(data.payoutAccountNumber, options.localisation)
    }
    delete data.incomeProofTypes
    delete data.decisionMessages
    delete data.changedConditions
    return data
  }
}
