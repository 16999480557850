export interface SalesReportFilterProduct {
  code: string
  name: string
  subtype: string
  type: string
  validFrom: string
  validTo: string | null
}

export interface SalesReportFilterUser {
  createdAt: string | null
  firstName: string
  fullName: string
  lastName: string
  uuid: string
}

export interface SalesReportFilterShop {
  createdAt: string
  name: string
  organizationManager: string
  products: SalesReportFilterProduct[]
  users: SalesReportFilterUser[]
  uuid: string
}

export default class SalesReportFilterOptions {
  products: SalesReportFilterProduct[]
  shops: SalesReportFilterShop[]

  static getEmployees(shops: SalesReportFilterShop[]): SalesReportFilterUser[] {
    const allUsers = shops.map((shop) => shop.users).flat()
    const uniqueUserUuids = [
      ...new Set(allUsers.map((user) => user.uuid)),
    ]
    return uniqueUserUuids
      .map((uuid) => allUsers.find((user) => user.uuid === uuid)!)
  }

  static getShopsProducts(shops: SalesReportFilterShop[]): SalesReportFilterProduct[] {
    const shopsProducts = shops.map((shop) => shop.products).flat()
    const uniqueProductCodes = [
      ...new Set(shopsProducts.map((product) => product.code)),
    ]
    return uniqueProductCodes
      .map((code) => shopsProducts.find((prod) => prod.code === code)!)
  }

  static getProductSubtypes(products: SalesReportFilterProduct[]): string[] {
    return [...new Set(products.map((product) => product.subtype))]
  }

  constructor(args: any = {}) {
    this.products = args.products || []
    this.shops = args.shops || []

    this.products.sort((a, b) => a.name.localeCompare(b.name))
    this.shops.sort((a, b) => a.name.localeCompare(b.name))
  }

  get employees(): SalesReportFilterUser[] {
    return SalesReportFilterOptions.getEmployees(this.shops)
  }

  get productSubtypes(): string[] {
    return SalesReportFilterOptions.getProductSubtypes(this.products)
  }

  getShopsForUuids(shopUuids: string[]): SalesReportFilterShop[] {
    return this.shops.filter((shop) => shopUuids.includes(shop.uuid))
  }

  getUsersForUuids(userUuids: string[]): SalesReportFilterUser[] {
    return this.employees.filter((user) => userUuids.includes(user.uuid))
  }

  getProductsForCodes(productCodes: string[]): SalesReportFilterProduct[] {
    return this.products.filter((product) => productCodes.includes(product.code))
  }
}
