/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import RentalAsset from '~/models/rental/RentalAsset'
import RentalCalculation from '~/models/rental/RentalCalculation'
import { RentalQuote } from '~/models/rental/RentalQuote'

export interface State {
  inputValidForQuote: boolean
  tradeInValue: number | null
  totalAmount: number,
  calculation: RentalCalculation | null
  assets: RentalAsset[]
  quote: RentalQuote | null
}

export const state = (): State => ({
  inputValidForQuote: false,
  calculation: null,
  tradeInValue: null,
  totalAmount: 0,
  assets: [],
  quote: null,
})

export const mutations = {
  setInputValidForQuote(state: State, isValid: boolean) {
    state.inputValidForQuote = isValid
  },
  setCalculation(state: State, calculation: RentalCalculation) {
    state.calculation = calculation
  },
  setTradeInValue(state: State, tradeInValue: number | null) {
    state.tradeInValue = tradeInValue
  },
  setTotalAmount(state: State, totalAmount: number) {
    state.totalAmount = totalAmount
  },
  setAssets(state: State, assets: RentalAsset[]) {
    state.assets = assets
  },
  setQuote(state: State, quote: RentalQuote) {
    state.quote = quote
  },
}

export const actions = {
  setInputValidForQuote({ commit }: { commit: Function }, isValid: boolean) {
    commit('setInputValidForQuote', isValid)
  },
  setCalculation({ commit }: { commit: Function }, calculation: RentalCalculation) {
    commit('setCalculation', calculation)
  },
  setTradeInValue({ commit }: { commit: Function }, tradeInValue: number | null) {
    commit('setTradeInValue', tradeInValue)
  },
  setTotalAmount({ commit }: { commit: Function }, totalAmount: number) {
    commit('setTotalAmount', totalAmount)
  },
  setAssets({ commit }: { commit: Function }, assets: RentalAsset[]) {
    commit('setAssets', assets)
  },
  setQuote({ commit }: { commit: Function }, quote: RentalQuote) {
    commit('setQuote', quote)
  },
}
