/* eslint-disable max-classes-per-file */
import {
  COOKIE_CONSENT_ITEM_NAME,
  COOKIE_CONSENT_NAME,
  COOKIE_CONSENT_DESCRIPTION,
  COOKIE_CONSENT_TYPE,
  COOKIE_CONSENT_CATEGORY,
  COOKIE_CONSENT_BINDING,
  COOKIE_CONSENT_DEFAULT_DISABLED,
  COOKIE_CONSENT_DEFAULT_STATE,
} from '~/constants/cookieConsent'

class CookieConsent {
  constructor(type) {
    this.name = COOKIE_CONSENT_NAME[type] || null
    this.description = COOKIE_CONSENT_DESCRIPTION[type] || null
    this.binding = COOKIE_CONSENT_BINDING[type] || null
    this.disabled = COOKIE_CONSENT_DEFAULT_DISABLED[type] || false
    this.category = COOKIE_CONSENT_CATEGORY[type] || null
    this.state = COOKIE_CONSENT_DEFAULT_STATE[type] || false
    this.updatedAt = null
  }
}

export default class CookieConsents {
  constructor(args = {}) {
    this.functional = args.functional || new CookieConsent(COOKIE_CONSENT_TYPE.FUNCTIONAL)
    this.analytical = args.analytical || new CookieConsent(COOKIE_CONSENT_TYPE.ANALYTICAL)

    this.sync()
  }

  sync() {
    try {
      const restoredConsents = JSON.parse(localStorage.getItem(COOKIE_CONSENT_ITEM_NAME))
      Object.keys(restoredConsents).forEach((consent) => {
        this[consent] = restoredConsents[consent]
      })
    } catch (err) {
      // Nothing to do as the consents don't exist
    }
  }

  consentGiven(consent) {
    try {
      return this[consent].state
    } catch (err) {
      console.error('Consent not found')
    }
    return null
  }
}
