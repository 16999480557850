import API from '~/services/api'

const config = {
  header : {
    'Content-Type' : 'application/x-www-form-urlencoded',
  },
}

export default {
  upload (path, formData) {
    return API.post(path, formData, config)
    .then(response => {
      return response
    })
  },
}
