import Vue from 'vue'

import { DURATION_SECONDS, PERCENTAGE } from '~/constants'

// GENERAL FILTERS APPLICABLE TO ALL COUNTRIES
// Country specific filters are defined in localisation.js

Vue.filter('percentage', (value, decimals = PERCENTAGE.DECIMALS) => {
  if (decimals > 2) {
    const noZeroes = Number((value * PERCENTAGE.MULTIPLIER).toFixed(decimals))
    const decimalsPart = noZeroes.toString().split('.')[1]
    return decimalsPart?.length > 2
      ? noZeroes.toString()
      : noZeroes.toFixed(2)
  }
  return (value * PERCENTAGE.MULTIPLIER).toFixed(decimals)
})

Vue.filter('ceilPercentage', (value) => `${Math.ceil(value * PERCENTAGE.MULTIPLIER)}%`)

Vue.filter('truncate', (value, length) => {
  const defaultTruncateLength = 15
  if (!length) {
    // eslint-disable-next-line no-param-reassign
    length = defaultTruncateLength
  }
  if (value) {
    return `${value.slice(0, length)}${(value.length > length) ? '...' : ''}`
  }
  return ''
})

Vue.filter('duration', (value) => {
  if (!value) {
    return '-'
  }

  const parsedSeconds = Math.ceil(parseFloat(value))

  const minutes = Math.floor(parsedSeconds / DURATION_SECONDS.MINUTE)
  const seconds = parsedSeconds % DURATION_SECONDS.MINUTE

  let formatted = ''

  if (minutes) {
    formatted += `${minutes}m `
  }

  formatted += `${seconds}s`

  return formatted
})
