export const GAUGE_PREFIX = 'pp_metrics'

export const GAUGE = {
  LOGIN: {
    name: `${GAUGE_PREFIX}_login_total`,
    help: 'Count of login events',
    labelNames: [
      'method',
      'result',
    ],
  },
  PASSWORD_RECOVERY: {
    name: `${GAUGE_PREFIX}_password_recovery_total`,
    help: 'Total count of password recoveries',
    labelNames: [
      'result',
    ],
  },
  APPLICATION: {
    name: `${GAUGE_PREFIX}_application_creation_total`,
    help: 'Count of application submits events',
    labelNames: [
      'result',
    ],
  },
  INVOICE_APPLICATION: {
    name: `${GAUGE_PREFIX}_invoice_application_creation_total`,
    help: 'Count of invoice application submits events',
    labelNames: [
      'result',
    ],
  },
  CREDIT_PURCHASE_APPLICATION: {
    name: `${GAUGE_PREFIX}_credit_purchase_application_creation_total`,
    help: 'Count of credit purchase application submits events',
    labelNames: [
      'result',
    ],
  },
  CONTRACT_SIGNING: {
    name: `${GAUGE_PREFIX}_contracts_signed_total`,
    help: 'Count of contracts signed',
    labelNames: [
      'method',
      'result',
    ],
  },
  MANUAL_INCOME_VERIFICATION: {
    name: `${GAUGE_PREFIX}_manual_income_verification_total`,
    help: 'Count of manual income verifications',
    labelNames: [
      'result',
    ],
  },
  LOADS_HP_APPLICATIONS: {
    name: `${GAUGE_PREFIX}_loads_overview_hp_applications_total`,
    help: 'Total of loading overview page hp applications events',
    labelNames: [
      'result',
    ],
  },
  LOADS_CREDIT_PURCHASE_APPLICATIONS: {
    name: `${GAUGE_PREFIX}_loads_overview_credit_purchase_applications_total`,
    help: 'Total of loading overview page credit_purchase applications events',
    labelNames: [
      'result',
    ],
  },
  EPOS_LINK_GENERATION: {
    name: `${GAUGE_PREFIX}_creates_correct_epos_link`,
    help: 'Total of creating correct epos posSession links',
    labelNames: [
      'result',
    ],
  },
  EPOS_LINK_GENERATION_SENT_TO_CUSTOMER: {
    name: `${GAUGE_PREFIX}_sends_epos_link_to_customer`,
    help: 'Total of sending epos link to customer',
    labelNames: [
      'result',
      'method',
    ],
  },
}

export const EVENT_RESULTS = {
  SUCCESS: 'success',
  SERVER_ERROR: 'server_error', // 5xx type errors
  CLIENT_ERROR: 'client_error', // 4xx type errors
  CLIENT_CANCEL: 'client_cancel', // operation cancelled by user
  APP_ERROR: 'app_error', // application error
}

export const EVENT = {
  LOGIN: {
    NAME: GAUGE.LOGIN.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      CLIENT_CANCEL: EVENT_RESULTS.CLIENT_CANCEL,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  PASSWORD_RECOVERY: {
    NAME: GAUGE.PASSWORD_RECOVERY.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  APPLICATION: {
    NAME: GAUGE.APPLICATION.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  INVOICE_APPLICATION: {
    NAME: GAUGE.INVOICE_APPLICATION.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  CREDIT_PURCHASE_APPLICATION: {
    NAME: GAUGE.CREDIT_PURCHASE_APPLICATION.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  CONTRACT_SIGNING: {
    NAME: GAUGE.CONTRACT_SIGNING.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_CANCEL: EVENT_RESULTS.CLIENT_CANCEL,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  MANUAL_INCOME_VERIFICATION: {
    NAME: GAUGE.MANUAL_INCOME_VERIFICATION.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  LOADS_HP_APPLICATIONS: {
    NAME: GAUGE.LOADS_HP_APPLICATIONS.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  LOADS_CREDIT_PURCHASE_APPLICATIONS: {
    NAME: GAUGE.LOADS_CREDIT_PURCHASE_APPLICATIONS.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  EPOS_LINK_GENERATION: {
    NAME: GAUGE.EPOS_LINK_GENERATION.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
  EPOS_LINK_GENERATION_SENT_TO_CUSTOMER: {
    NAME: GAUGE.EPOS_LINK_GENERATION_SENT_TO_CUSTOMER.name,
    RESULTS: {
      SUCCESS: EVENT_RESULTS.SUCCESS,
      SERVER_ERROR: EVENT_RESULTS.SERVER_ERROR,
      CLIENT_ERROR: EVENT_RESULTS.CLIENT_ERROR,
      APP_ERROR: EVENT_RESULTS.APP_ERROR,
    },
  },
}
