import Vue from 'vue'

import { Notifier } from '@airbrake/browser'

import { HTTP_STATUS_CODE } from '~/constants'

const reportedErrorStatuses = [
  HTTP_STATUS_CODE.GATEWAY_TIMEOUT,
]

function transformError(error) {
  if (error instanceof Error) {
    return error
  }

  if (error.status) {
    return {
      error: `Client network error - ${error.status}`,
      params: {
        config: error.config,
      },
      context: {
        responseData: error.data,
        responseHeaders: error.headers,
      },
    }
  }

  return JSON.stringify(error)
}

export default function ({ app }) {
  Vue.config.errorHandler = (err) => {
    if (err.status && !reportedErrorStatuses.includes(err.status)) {
      console.error(err)
      return
    }
    const { NODE_AIRBRAKE_PROJECT_ID, NODE_AIRBRAKE_API_KEY, NODE_ENV } = app.$env
    if (NODE_AIRBRAKE_PROJECT_ID && NODE_AIRBRAKE_API_KEY) {
      try {
        const airBreakClient = new Notifier({
          projectId: NODE_AIRBRAKE_PROJECT_ID,
          projectKey: NODE_AIRBRAKE_API_KEY,
          environment: NODE_ENV,
        })
        airBreakClient.notify(transformError(err))
      } catch (error) {
        console.error(error)
      }
    } else {
      console.error(err)
    }
  }
}
