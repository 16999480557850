/* eslint-disable max-classes-per-file */
class InbApiError {
  constructor(args = {}) {
    this.message = args.message || []
    this.errorCode = args.errorCode || null
    this.context = args.context || null
  }
}

export default class ApiError {
  constructor(args = {}) {
    this.statusCode = args.statusCode || null
    this.message = args.message || null
    this.error = args.error || null
    this.requestId = args.requestId || null
    this.details = new InbApiError(args.details)
  }

  isOfType(errorType) {
    const { message, errorCode } = this.details
    return message === errorType || message.includes(errorType) || errorCode === errorType
  }
}
