export const API_ERROR = {
  IDENTITY_CHECK_FAILED: 'identity_check_failed',
  IDENTIFICATION_IS_INVALID: 'identification_is_invalid',
  DOCUMENT_NUMBER_ALREADY_EXISTS: 'document_number_already_exists',
  MOBILE_OR_EMAIL_NOT_UNIQUE: 'mobile_or_email_not_unique',
  ADDRESS_EXISTS: 'address_exists',
  INVALID_SIGNER_STATUS: 'invalid_signer_status',
  USER_LOCKED: 'user_locked',
  ACCOUNT_NUMBER_IS_FROM_UNSUPPORTED_BANK: 'account_number_is_from_unsupported_bank',
  PAYOUT_ACCOUNT_NUMBER_MUST_BE_A_VALID_IBAN: 'payout_account_number_must_be_a_valid_iban',
  INVALID_USERNAME_OR_PASSWORD: 'invalid_username_or_password',
  CUSTOMER_EXCLUDED_FROM_PROCESSING: 'customer_excluded_from_processing',
  DOCUMENT_LOST_OR_STOLEN: 'document_lost_or_stolen',
  REGISTRY_CONNECTION_ISSUE: 'registry_connection_issue',
  AMOUNT_IS_INVALID: 'amount_is_invalid',
  MUNICIPALITY_ADDRESS: 'municipality_address',
  REGISTRY_ERROR: 'registry_error',
  VERIFICATION_FAILED: 'verification_failed',
  VERIFICATION_FAILED_USER_CANCELLED_THE_OPERATION: 'verification_failed_user_cancelled_the_operation',
  VERIFICATION_FAILED_EXPIRED_TRANSACTION: 'verification_failed_expired_transaction',
  GROSS_MONTHLY_PAYMENT_LESS_THAT_MIN_GROSS_MONTHLY_PAYMENT: 'gross_monthly_payment_less_that_min_gross_monthly_payment',
  GROSS_MONTHLY_PAYMENT_LESS_THAN_MIN_GROSS_MONTHLY_PAYMENT: 'gross_monthly_payment_less_than_min_gross_monthly_payment',
  ASSET_PRICE_NOT_IN_ALLOWED_LIMITS: 'asset_price_not_in_allowed_limits',
  SIGNING_ERROR: 'can_not_sign',
  RZPESEL_ERROR: 'customer_listed_in_rzpesel',
  LOAN_SERVICE_RESTRICTED: 'loan_service_restricted',
}

export const APP_ERROR = {
  UNKNOWN_ERROR: 'unknownError',
  NAME_CAN_NOT_BE_CHANGED: 'nameCanNotBeChanged',
  INVALID_SMS_CONFIRMATION_CODE: 'invalidSmsConfirmationCode',
}

export const ERROR_TRANSLATION_PREFIX = 'error'

export const SIGNING_MESSAGE = [
  API_ERROR.RZPESEL_ERROR,
  API_ERROR.SIGNING_ERROR,
  API_ERROR.LOAN_SERVICE_RESTRICTED,
]

export const RZPESEL_ERROR_MESSAGE_LENGTH = 2
