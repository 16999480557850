/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import Auth from '~/models/Auth'

import {
  setItem,
  getItem,
  removeItem,
  STORAGE_TYPE,
} from '~/utils/StorageUtils'

export const STORAGE_KEY = {
  AUTH: 'auth_auth',
}

export const MUTATIONS = {
  SET_AUTH: 'SET_AUTH',
  CLEAR_AUTH: 'CLEAR_AUTH',
  SET_AUTH_USER: 'SET_AUTH_USER',
}

export const state = () => ({
  auth: new Auth({ loggedIn: false }),
  user: null,
})

export const mutations = {
  [MUTATIONS.SET_AUTH](state, auth) {
    state.auth = auth
  },
  [MUTATIONS.CLEAR_AUTH](newState) {
    const initialState = state()
    newState.auth = initialState.auth
    newState.user = initialState.user
  },
  [MUTATIONS.SET_AUTH_USER](state, user) {
    state.user = user
  },
}

export const actions = {
  restoreState({ dispatch }) {
    const authData = getItem(STORAGE_KEY.AUTH, STORAGE_TYPE.LOCAL)

    if (authData) {
      dispatch('setAuth', new Auth(authData), false)
    }
  },
  setAuth({ commit }, auth, persist = true) {
    commit(MUTATIONS.SET_AUTH, auth)
    if (persist) {
      setItem(STORAGE_KEY.AUTH, auth, STORAGE_TYPE.LOCAL)
    }
  },
  setUser({ commit }, user) {
    commit(MUTATIONS.SET_AUTH_USER, user)
  },
  logout({ dispatch }) {
    dispatch('setAuth', new Auth({ loggedIn: false }), false)
  },
  clearAuth({ commit }) {
    commit(MUTATIONS.CLEAR_AUTH)
    removeItem(STORAGE_KEY.AUTH, STORAGE_TYPE.LOCAL)
  },
}
