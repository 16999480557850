import {
  onMounted,
  onBeforeMount,
  ref,
  computed,
  reactive,
  toRefs,
} from '@nuxtjs/composition-api'

import ClientUtils from '~/utils/ClientUtils'

import AuthenticationService, { AuthStatusResponse } from '~/services/api/AuthenticationService'
import UserService from '~/services/api/UserService'

import Auth from '~/models/Auth'

import { useAuthPromEvents } from '~/use/authentication/AuthPrometheusEvents'
import { DURATION_MILLISECONDS, COUNTRY_CODE } from '~/constants'
import { AUTH_STRATEGY, AUTH_STATUS } from '~/constants/auth'
import { Context } from '~/types/common'
import { Poller } from '~/utils/PollingUtils'

import {
  AUTHENTICATION_URLS,
  RESIDENCY_MAP,
  CERTIFICATE_PURPOSE,
} from '~/constants/dokobit'

const POLLER_INTERVAL = 2000
interface PasswordLoginParams {
  username: string
  password: string
}

interface SmartIdLoginParams {
  personalCode: string
  country: string
}

interface MobileIdLoginParams {
  personalCode: string
  phoneNumber: string
}

type OnAuthComplete = (data?: any) => void
type OnAuthError = (error?: any, status?: string) => void
type OnAuthCancel = () => void

interface AuthState {
  authErrors: string[]
  currentAuthStrategy: string | null
  authInitInProgress: boolean
  authStarted: boolean
  pollingInPorgress: boolean,
  authControlCode: string | null
}

export function useLogin(context: Context) {
  const loginInitializing = ref(false)

  const {
    promBroadcastSuccess,
    promBroadcastCancel,
    promBroadcastUnknownError,
    promBroadcastError,
  } = useAuthPromEvents(context)

  let fingerprint: string

  const generateFingerprint = async () => {
    // @ts-ignore
    if (window.requestIdleCallback) {
      // @ts-ignore
      requestIdleCallback(async () => {
        fingerprint = await ClientUtils.getFingerprint()
      })
    } else {
      setTimeout(async () => {
        fingerprint = await ClientUtils.getFingerprint()
      // eslint-disable-next-line no-magic-numbers
      }, 500)
    }
  }

  onMounted(async () => {
    loginInitializing.value = true

    await generateFingerprint()

    loginInitializing.value = false
  })

  let poller: Poller<AuthStatusResponse>
  let iSignErrorMessage: string | null = null

  const state = reactive<AuthState>({
    authErrors: [],
    currentAuthStrategy: null,
    authStarted: false,
    authInitInProgress: false,
    pollingInPorgress: false,
    authControlCode: null,
  })

  function resetState() {
    (window as any).iSignApplet?.clear?.()
    state.authStarted = false
    state.authInitInProgress = false
    state.pollingInPorgress = false
    state.authControlCode = null
  }

  function setAuthInitiatedState(authStrategy: string) {
    state.authErrors = []
    state.currentAuthStrategy = authStrategy
    state.authInitInProgress = true
    state.authStarted = true
  }

  function setStatusPoller(
    serviceMethod: () => Promise<AuthStatusResponse>,
    onComplete: OnAuthComplete,
    onError: OnAuthError,
    onCancel: OnAuthCancel,
  ) {
    state.pollingInPorgress = true
    poller = new Poller({
      fetchMethod: () => serviceMethod(),
      validator: (data) => {
        if (data.status === AUTH_STATUS.WAITING) {
          return { hasFinished: false, data: null }
        }
        return { hasFinished: true, data }
      },
      onDone: ({ status }) => {
        if (status === AUTH_STATUS.OK) {
          onComplete()
        } else {
          onError(null, status)
        }
      },
      onError: (error) => {
        onError(error)
      },
      onCancel: () => {
        onCancel()
      },
      onEnd: () => {
        state.pollingInPorgress = false
      },
      timeout: () => POLLER_INTERVAL,
    })
    poller.start()
  }

  async function fetchUser(strategy: string) {
    const user = await UserService.getCurrentUser(context.root.$store)
    context.root.$store.dispatch('auth/setAuth', new Auth({ loggedIn: true, strategy }))
    context.root.$store.dispatch('auth/setUser', user)
  }

  function onAuthSuccessFactory(strategy: string, onComplete: OnAuthComplete) {
    return async () => {
      await fetchUser(strategy)
      promBroadcastSuccess(strategy)
      state.currentAuthStrategy = null
      onComplete(strategy)
    }
  }

  function onAuthErrorFactory(strategy: string, onError: OnAuthError) {
    return (error?: any, status?: string) => {
      console.error(error, status)
      state.authErrors = [context.root.t(`errors.auth.${strategy}`)]
      promBroadcastError(error, strategy, status || '')
      resetState()
      onError(error)
    }
  }

  async function authenticateViaSmartId(
    { personalCode, country = COUNTRY_CODE.EE }: SmartIdLoginParams,
    onComplete: OnAuthComplete,
    onError: OnAuthError,
  ) {
    try {
      setAuthInitiatedState(AUTH_STRATEGY.SMART_ID)
      const { controlCode } = await AuthenticationService.authenticateViaSmartId({
        personalCode,
        country,
      })

      state.authControlCode = controlCode

      setStatusPoller(
        AuthenticationService.checkSmartIdStatus,
        onAuthSuccessFactory(AUTH_STRATEGY.SMART_ID, onComplete),
        onAuthErrorFactory(AUTH_STRATEGY.SMART_ID, onError),
        () => {
          onError()
          promBroadcastCancel(AUTH_STRATEGY.SMART_ID)
        },
      )
    } catch (error) {
      state.authErrors = [context.root.t(`errors.auth.${AUTH_STRATEGY.SMART_ID}`)]
      promBroadcastUnknownError(error, AUTH_STRATEGY.SMART_ID)
      resetState()
      onError(error)
    }
    state.authInitInProgress = false
  }

  async function authenticateViaMobileId(
    { personalCode, phoneNumber }: MobileIdLoginParams,
    onComplete: OnAuthComplete,
    onError: OnAuthError,
  ) {
    try {
      setAuthInitiatedState(AUTH_STRATEGY.MOBILE_ID)
      const { controlCode } = await AuthenticationService.authenticateViaMobileId({
        personalCode,
        phoneNumber,
      })

      state.authControlCode = controlCode
      setStatusPoller(
        AuthenticationService.checkMobileIdStatus,
        onAuthSuccessFactory(AUTH_STRATEGY.MOBILE_ID, onComplete),
        onAuthErrorFactory(AUTH_STRATEGY.MOBILE_ID, onError),
        () => {
          onError()
          promBroadcastCancel(AUTH_STRATEGY.MOBILE_ID)
        },
      )
    } catch (error) {
      state.authErrors = [context.root.t(`errors.auth.${AUTH_STRATEGY.MOBILE_ID}`)]
      promBroadcastUnknownError(error, AUTH_STRATEGY.MOBILE_ID)
      resetState()
      onError(error)
    }
    state.authInitInProgress = false
  }

  async function authenticateViaPassword({ username, password }: PasswordLoginParams) {
    const { NODE_SESSION_EXPIRE, session } = context.root.$env
    const sessionExpire = NODE_SESSION_EXPIRE
      ? parseInt(NODE_SESSION_EXPIRE, 10)
      : session.defaultExpire

    const authResponse = await AuthenticationService.authenticateViaPassword({
      username,
      password,
      deviceCode: fingerprint,
      context: {
        sessionLength: sessionExpire * DURATION_MILLISECONDS.SECOND,
      },
    })
    await fetchUser(AUTH_STRATEGY.PASSWORD)

    return authResponse
  }

  async function handleToken(
    token: string,
    iSignApplet: any,
    onComplete: OnAuthComplete,
    onError: OnAuthError,
  ) {
    try {
      await AuthenticationService.authenticateViaDokobitSmartCard({ token })
      state.authInitInProgress = false
      onAuthSuccessFactory(AUTH_STRATEGY.SMART_CARD, onComplete)()
    } catch (error) {
      // eslint-disable-next-line no-param-reassign
      iSignApplet.isInitialized = false
      iSignApplet.clear()
      state.authErrors = [context.root.t('errors.auth.smartCard')]
      promBroadcastError(error, AUTH_STRATEGY.SMART_CARD, '')
      resetState()
      onError(error)
    }
    return null
  }

  async function authenticateViaSmartCard(
    onComplete: OnAuthComplete,
    onError: OnAuthError,
  ) {
    const { iSignApplet } = window as any
    try {
      setAuthInitiatedState(AUTH_STRATEGY.SMART_CARD)

      // reset the stored error message
      iSignErrorMessage = null
      // Create a method to clear the message on error
      iSignApplet.clear = () => {
        (window as any).$('#isign-applet').html('')
      }

      // This method is called by the Dokobit applet
      (window as any).authenticationTokenReceived = async (token: string) => {
        await handleToken(token, iSignApplet, onComplete, onError)
      }

      (window as any).certificateSelected = async (certificate: string) => {
        if (context.root.$localisation.isoCode() === COUNTRY_CODE.LV) {
          try {
            const { dtbsHash, dtbs } = await AuthenticationService.authenticateViaSmartCard({
              certificate: btoa(unescape(encodeURIComponent(certificate))),
            })

            iSignApplet.sign(dtbs, dtbsHash, (signedHash: string) => {
              setStatusPoller(
                () => AuthenticationService.checkSmartCardStatus({ signature: signedHash }),
                onAuthSuccessFactory(AUTH_STRATEGY.SMART_CARD, onComplete),
                onAuthErrorFactory(AUTH_STRATEGY.SMART_CARD, onError),
                () => {
                  promBroadcastCancel(AUTH_STRATEGY.SMART_CARD)
                  onError() // on cancel
                },
              )
            })
          } catch (err) {
            promBroadcastUnknownError(err, AUTH_STRATEGY.SMART_CARD)
            onError(err)
          }
        }
      }

      (window as any).oniSignAppletError = async (message: string) => {
        if (message !== iSignErrorMessage) {
          iSignErrorMessage = message
          promBroadcastUnknownError({}, AUTH_STRATEGY.SMART_CARD)
          onError(message)
        }
      }

      // clear everything if the user attempts to auth again
      iSignApplet.clear()

      iSignApplet.isInitialized = false
      iSignApplet.init({
        language: context.root.$i18n.locale,
        certificatePurpose: CERTIFICATE_PURPOSE.LOGIN,
        supportedResidencies: RESIDENCY_MAP[context.root.$localisation.isoCode()],
        authenticationUrls: AUTHENTICATION_URLS,
      })
    } catch (error) {
      promBroadcastUnknownError(error, AUTH_STRATEGY.SMART_CARD)
      iSignApplet.isInitialized = false
      iSignApplet.clear()
      state.authErrors = [context.root.t('errors.auth.smartCard')]
      onError(error)
    }
    state.authInitInProgress = false
  }

  return {
    ...toRefs(state),
    loginInitializing,
    authenticateViaPassword,
    authenticateViaSmartId,
    authenticateViaMobileId,
    authenticateViaSmartCard,
    resetState,
  }
}

export function useLogout(context: Context) {
  const logout = async () => {
    AuthenticationService.logout(context.root.$store)
    // @ts-ignore
    window.location = context.root.localePath({ name: 'login', query: { from: 'logout' } })
  }

  return { logout }
}

export function useClearAuthState(context: Context) {
  onMounted(() => {
    if (context.root.$route.query.from === 'logout') {
      context.root.$store.dispatch('auth/clearAuth')
    }
  })

  return {}
}

export function useAuthRestore(context: Context) {
  onBeforeMount(() => {
    context.root.$store.dispatch('auth/restoreState')
  })

  return {}
}

export function useLoggedInCheck(context: Context) {
  const { logout } = useLogout(context)

  // needs to be "computed" in order to get the latest value in "onBeforeMount"
  const loggedIn = computed(() => context.root.$store.state.auth.auth.loggedIn)

  useAuthRestore(context)

  onBeforeMount(() => {
    if (
      !loggedIn.value
        && !context.root.$route.path.includes(context.root.localePath('cookie-policy'))
        && !context.root.$route.path.includes(context.root.localePath('login'))
        && !context.root.$route.path.includes(context.root.localePath('password/create'))
    ) {
      logout()
    }
  })

  return {}
}

export function useAuthLayout(context: Context) {
  return {
    ...useLoggedInCheck(context),
    ...useLogout(context),
    user: computed(() => context.root.$store.state.auth.user),
  }
}

export default {}
