import * as contentful from 'contentful'

import {
  CONTENTFUL_PREVIEW_HOST_ENVS,
} from '~/constants/contentful'

// export `createClient` to use it in page components
// eslint-disable-next-line import/prefer-default-export
export function createClient(NODE_ENV) {
  const host = (CONTENTFUL_PREVIEW_HOST_ENVS.includes(NODE_ENV))
    ? 'preview.contentful.com'
    : 'cdn.contentful.com'

  return contentful.createClient({
    host,
    space: ((process.env.contentful || {}).CTF_SPACE_ID) || '-',
    accessToken: ((process.env.contentful || {}).CTF_CDA_ACCESS_TOKEN) || '-',
  })
}
