import _ from 'lodash'
import axios from 'axios'

import ApiError from '~/models/ApiError'

import { HTTP_STATUS_CODE } from '~/constants'

const api = axios.create({
  baseURL: '/api',
  withCredentials: true,
})

api.interceptors.response.use(null, async (error) => {
  if (error.response) {
    if (error.response.config.responseType === 'blob') {
      // eslint-disable-next-line no-param-reassign
      error.response.data = new ApiError(JSON.parse(await error.response.data.text()))
    } else {
      // eslint-disable-next-line no-param-reassign
      error.response.data = new ApiError(error.response.data)
    }
  }
  throw error
})

api.interceptors.response.use(null, (error) => {
  const retries = _.get(error, 'config.retries')
  const status = _.get(error, 'response.status')

  const retriesLeft = retries ? retries - 1 : 0

  if (retriesLeft && status >= HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR) {
    return api.request({
      ...error.config,
      retries: retriesLeft,
    })
  }

  return Promise.reject(error)
})

export default api
