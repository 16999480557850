import EE_LOCALE from './EE'
import PL_LOCALE from './PL'
import LV_LOCALE from './LV'
import CZ_LOCALE from './CZ'
import LT_LOCALE from './LT'

export default {
  EE: EE_LOCALE,
  PL: PL_LOCALE,
  LV: LV_LOCALE,
  CZ: CZ_LOCALE,
  LT: LT_LOCALE,
}
