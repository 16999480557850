export const CONTENTFUL_CONTENT_TYPE = {
  NOTIFICATION: 'partnerPortal',
}

export const CONTENTFUL_PREVIEW_HOST_ENVS = [
  'staging',
  'local',
  'development',
]

export default {}
