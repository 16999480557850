







































import { defineComponent } from '@nuxtjs/composition-api'

import PartialReturn from '~/models/PartialReturn'

export default defineComponent({
  props: {
    partialReturn: {
      type: PartialReturn,
      default: () => new PartialReturn(),
    },
  },
})
