import Vue from 'vue'
import moment from 'moment'

import { formatCurrencyAmount } from '~/utils/DataUtils'
import { getCountryCodeForRegionCode } from '~/utils/PhoneUtils'

import countryConfig from '~/localisation/country-config'

import { COUNTRY_CODE } from '~/constants'

export default ({ app }, inject) => {
  const isoCode = app.$env.NODE_COUNTRY_ISO_CODE || 'EE'
  const conf = countryConfig[isoCode]
  const { NODE_ENV, NODE_ENV_SUBTYPE } = app.$env

  const localisation = {
    isoCode() {
      return isoCode
    },
    languageCode() {
      return conf.languageCode || null
    },
    communicationLanguages() {
      return conf.communicationLanguages || []
    },
    residencies() {
      return conf.residencies || []
    },
    idDocumentTypes() {
      return conf.idDocumentTypes || []
    },
    currency() {
      return conf.formats.CURRENCY || '€'
    },
    currencyString() {
      return conf.formats.CURRENCY_STRING || 'EUR'
    },
    dateFormat() {
      return conf.formats.DATE_FORMAT || 'yyyy-mm-dd'
    },
    dateTimeFormat() {
      return conf.formats.DATE_TIME_FORMAT || 'DD.MM.YYYY HH:mm'
    },
    documentNrMask() {
      return conf.formats.DOCUMENT_NR_MASK || 'AAA-######'
    },
    residencePermitMask() {
      return conf.formats.RESIDENCE_PERMIT_MASK || 'NN#######'
    },
    documentNrPlaceholder() {
      return conf.formats.DOCUMENT_NR_PLACEHOLDER || ''
    },
    idCodeMask() {
      return conf.formats.IDENTITY_CODE_FORMAT || ''
    },
    zipCodeMask() {
      return conf.formats.ZIP_CODE_MASK || '##-###'
    },
    phoneNumberMaxLength() {
      return conf.formats.PHONE_NUMBER_MAX_LENGTH || 9
    },
    zipCodePlaceholder() {
      return conf.formats.ZIP_CODE_PLACEHOLDER || ''
    },
    landlinePrefixes() {
      return conf.validations.LANDLINE_PREFIXES || []
    },
    mobilePrefixes() {
      return conf.validations.MOBILE_PREFIXES || []
    },
    validate(name, value, options) {
      return conf.validators[name](value, options)
    },
    postalPrefix() {
      return conf.address?.postalPrefix || false
    },
    features() {
      const envType = NODE_ENV_SUBTYPE || NODE_ENV
      return conf.features?.[envType] || {}
    },
  }

  inject('localisation', localisation)

  // Because our filters will be localisation based,
  // we need to define them here, where localisation functions are available
  Vue.filter('date', (value, format) => {
    if (!format) {
      // eslint-disable-next-line no-param-reassign
      format = localisation.dateFormat()
    }
    if (value) {
      return moment(value).format(format)
    }
    return ''
  })

  Vue.filter('datetime', (value, format) => {
    if (!format) {
      // eslint-disable-next-line no-param-reassign
      format = localisation.dateTimeFormat()
    }
    if (value) {
      return moment(value).format(format)
    }
    return ''
  })

  Vue.filter('currency', (value) => `${formatCurrencyAmount(value)} ${localisation.currency()}`)

  Vue.filter('withCurrency', (value) => `${value} ${localisation.currency()}`)

  Vue.filter('zip', (value) => {
    if (localisation.isoCode() === COUNTRY_CODE.PL) {
      const zipSplitPosition = 2
      return `${value.slice(0, zipSplitPosition)}-${value.slice(zipSplitPosition)}`
    }
    return value
  })

  Vue.filter('phoneWithCountryCode', (value) => {
    const countryCode = getCountryCodeForRegionCode(isoCode)
    return `+${countryCode} ${value}`
  })
}
