import { computed } from '@nuxtjs/composition-api'
import _ from 'lodash'
import { Context } from '~/types/common'
import Permissions from '~/models/Permissions'

export function usePermissions(context: Context) {
  const PERMISSIONS = computed(() => {
    const permissions = _.get(context.root.$store.state.auth, 'user.user.PERMISSIONS')
    return new Permissions(permissions || {})
  })
  return { PERMISSIONS }
}

export default {}
