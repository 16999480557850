<template>
  <b-modal
    ref="confirmRentalContractModal"
    size="lg"
    :centered="true"
    tabindex="-1"
    role="dialog"
    footer-class="d-flex justify-content-between"
  >
    <app-loader :is-loading="isLoading" />
    <h5 class="text-center">
      {{ t('confirmRentalContract.title') }}
    </h5>
    <p class="mt-3 mb-0">
      {{ t('confirmRentalContract.description.identifiers', {
        identifierType: descriptionIdentifiers })
      }}
    </p>
    <div class="row mt-3 align-items-end">
      <div class="col-md-12 mb-3">
        <strong>{{ t('confirmRentalContract.deviceName') }}</strong>:
        {{ asset.modelName }}
      </div>
      <div v-if="hasSerialNumber" class="col-md-12">
        <div class="form-group">
          <label for="deviceSerialNumber">
            {{ t('confirmRentalContract.deviceSerialNumber') }}
          </label>
          <input
            id="deviceSerialNumber"
            ref="deviceSerialNumber"
            v-model="serialNumber"
            type="text"
            class="form-control"
          >
        </div>
      </div>
      <div v-if="hasIMEI" class="col-md-12">
        <div class="form-group">
          <label for="deviceIMEI">
            {{ t('confirmRentalContract.deviceImei') }}
          </label>
          <input
            id="deviceImei"
            ref="deviceImei"
            v-model="imei"
            type="number"
            class="form-control"
          >
        </div>
      </div>
    </div>
    <alert v-if="error" variant="danger" icon="icon-ban">
      {{ error }}
    </alert>
    <div slot="modal-footer" class="col-12">
      <inb-button id="confirm" variant="primary" :disabled="confirmButtonStatus" @click="confirm">
        {{ t('confirmRentalContract.buttons.confirm') }}
      </inb-button>
      <inb-button id="dismiss" variant="outline-primary" class="float-right" @click="dismiss()">
        {{ t('confirmRentalContract.buttons.cancel') }}
      </inb-button>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { camelCase } from 'change-case'

import { InbButton } from 'inbank-frontend-commons/components/base-components'
import {
  computed,
  onMounted,
  ref,
  watch,
  useContext,
} from '@nuxtjs/composition-api'

import RentalService from '~/services/api/RentalService'
import { useCurrentUser } from '~/use/CurrentUser'
import Alert from '~/components/Alert.vue'
import AppLoader from '~/components/AppLoader.vue'
import AssetType from '~/models/rental/AssetType'
import useMixpanel from '~/use/Mixpanel'
import Product from '~/models/Product'

import { CONTRACT } from '~/constants/mixpanel'
import validateIMEI from '~/utils/IMEIUtils'
import { DEVICE_IDENTIFIER } from '~/constants'

const IMEI_LENGTH = 15

export default {
  name: 'ConfirmRentalContractModal',
  components: { AppLoader, Alert, InbButton },
  props: {
    bus: {
      type: Vue,
      default: () => new Vue(),
    },
    rentalQuote: {
      type: Object,
      required: true,
    },
    product: {
      type: Product,
      default: () => new Product(),
    },
    applicationNumber: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /* eslint-disable no-use-before-define */
    const { currentShopUuid, currentShop } = useCurrentUser(context)
    const { trackMixpanelData } = useMixpanel(useContext())

    const isLoading = ref(false)
    const serialNumber = ref()
    const imei = ref()
    const error = ref()
    const asset = computed(
      () => (props.rentalQuote?.assets || []).find((item) => item.categoryType === AssetType.ASSET),
    )
    const hasIMEI = computed(() => asset.value.identifierTypes.includes(DEVICE_IDENTIFIER.IMEI))
    const hasSerialNumber = computed(
      () => asset.value.identifierTypes.includes(DEVICE_IDENTIFIER.SERIAL_NUMBER),
    )
    const isModelBased = computed(() => asset.value.type === AssetType.MODEL_BASED)

    const getIdentifierTranslation = computed(() => (identifierType) => {
      if (identifierType === DEVICE_IDENTIFIER.IMEI) {
        return context.root.t(`data.rentalApplication.${camelCase(identifierType)}`)
      }
      return context.root.t(`data.rentalApplication.${camelCase(identifierType)}`).toLowerCase()
    })

    const descriptionIdentifiers = computed(() => asset.value.identifierTypes.map(
      (type) => getIdentifierTranslation.value(type),
    ).join(', '))

    function show() {
      error.value = null
      serialNumber.value = null
      imei.value = null

      if (context.refs.confirmRentalContractModal) {
        context.refs.confirmRentalContractModal.show()
      }
    }

    function hide() {
      if (context.refs.confirmRentalContractModal) {
        context.refs.confirmRentalContractModal.hide()
      }
    }

    async function confirm() {
      if (hasIMEI.value && !validateIMEI(imei.value)) {
        error.value = context.root.t('confirmRentalContract.error.invalidImei')
        return
      }
      error.value = null
      isLoading.value = true
      try {
        const identifiersPayload = []
        if (hasSerialNumber.value) {
          identifiersPayload.push({
            identifierType: DEVICE_IDENTIFIER.SERIAL_NUMBER,
            identifier: serialNumber.value,
          })
        }
        if (hasIMEI.value) {
          identifiersPayload.push({
            identifierType: DEVICE_IDENTIFIER.IMEI,
            identifier: imei.value,
          })
        }
        await RentalService.updateIdentifiers(
          currentShopUuid.value, asset.value.uuid, identifiersPayload,
        )
        trackMixpanelData(CONTRACT.CONFIRMED, {
          shopUuid: currentShopUuid.value,
          shopName: currentShop?.value?.name,
          productType: props.product?.type,
          productSubtype: props.product?.subtype,
          rentalType: isModelBased.value ? AssetType.MODEL_BASED : AssetType.CATEGORY_BASED,
          identifierTypes: asset.value.identifierTypes.join(', '),
          finalAmount: props.rentalQuote?.totalGrossPrice - +props.rentalQuote?.netTradeInAmount,
          applicationId: props.applicationNumber,
        })

        context.emit('confirmed')
        hide()
      } catch (err) {
        const { type } = err.data.details?.context?.identifier || ''
        error.value = context.root.t(`confirmRentalContract.error.${camelCase(err.data.details.errorCode)}`, { identifierType: getIdentifierTranslation.value(type) })
        trackMixpanelData(RENTAL.CONTRACT_ERROR, {
          shopUuid: currentShopUuid.value,
          shopName: currentShop?.value?.name,
          productType: props.product?.type,
          productSubtype: props.product?.subtype,
          errorType: error.value,
          errorCode: err.data?.statusCode,
          rentalType: isModelBased.value ? AssetType.MODEL_BASED : AssetType.CATEGORY_BASED,
          finalAmount: props.rentalQuote?.totalGrossPrice - +props.rentalQuote?.netTradeInAmount,
          applicationId: props.applicationNumber,
        })
      }
      isLoading.value = false
    }

    function dismiss() {
      trackMixpanelData(CONTRACT.CONFIRMATION_CANCELED, {
        shopUuid: currentShopUuid.value,
        shopName: currentShop?.value?.name,
        productType: props.product?.type,
        productSubtype: props.product?.subtype,
        rentalType: isModelBased.value ? AssetType.MODEL_BASED : AssetType.CATEGORY_BASED,
        identifierTypes: asset.value.identifierTypes.join(', '),
        finalAmount: props.rentalQuote?.totalGrossPrice - +props.rentalQuote?.netTradeInAmount,
        applicationId: props.applicationNumber,
      })

      context.emit('dismissed')
      hide()
    }

    // Removes the last digit as IMEI is limited to 15 digits
    watch(imei, () => {
      if (hasIMEI.value && imei.value && imei.value.length > IMEI_LENGTH) {
        imei.value = imei.value.slice(0, IMEI_LENGTH)
      }
    })

    const confirmButtonStatus = computed(() => {
      if (hasSerialNumber.value && !serialNumber.value) {
        return true
      }
      if (hasIMEI.value && !imei.value) {
        return true
      }
      return false
    })

    onMounted(() => {
      props.bus.$on('show', () => {
        show()
      })

      props.bus.$on('hide', () => {
        hide()
      })
    })

    return {
      confirm,
      dismiss,
      show,
      hide,
      asset,
      serialNumber,
      imei,
      error,
      isLoading,
      hasIMEI,
      hasSerialNumber,
      getIdentifierTranslation,
      confirmButtonStatus,
      descriptionIdentifiers,
    }
  },
}
</script>
