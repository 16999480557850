export const SIGNING_METHOD = {
  SMS: 'sms',
  SMS_NEXMO: 'sms_nexmo',
  PAPER: 'paper',
  DIGITAL: 'digital',
  SEND_TO_SIGNING_EMAIL: 'send_to_signing_email',
  SEND_TO_SIGNING_SMS: 'send_to_signing_sms',
}

export const REPORT_FORMAT = {
  // FA-1529: temporarily hide pdf report option
  // PDF: 'pdf',
  HTML: 'html',
  CSV: 'csv',
  XLS: 'xls',
}

export const REPORT_STATUS = {
  OK: 'ok',
  RUNNING: 'running',
}

export const SIGNING_METHOD_LABELS = {
  [SIGNING_METHOD.PAPER]: 'signing.labels.signingMethodPaper',
  [SIGNING_METHOD.DIGITAL]: 'signing.labels.signingMethodDigital',
  [SIGNING_METHOD.SEND_TO_SIGNING_SMS]: 'signing.labels.signingMethodSendToSigningSms',
  [SIGNING_METHOD.SEND_TO_SIGNING_EMAIL]: 'signing.labels.signingMethodSendToSigningEmail',
  [SIGNING_METHOD.SMS_NEXMO]: 'signing.labels.signingMethodSmsNexmo',
  [SIGNING_METHOD.SMS]: 'signing.labels.signingMethodSms',
}

export const EPOS_LINK_TO_CUSTOMER_METHOD = {
  SMS: 'sms',
  EMAIL: 'email',
}

export const EPOS_LINK_TO_CUSTOMER_LABELS = {
  [EPOS_LINK_TO_CUSTOMER_METHOD.SMS]: 'eposSession.labels.sendMethodSms',
  [EPOS_LINK_TO_CUSTOMER_METHOD.EMAIL]: 'eposSession.labels.sendMethodEmail',
}
export const AIS_LINK_TO_CUSTOMER_METHOD = {
  SMS: 'sms',
  EMAIL: 'email',
}

export const AIS_LINK_TO_CUSTOMER_LABELS = {
  [AIS_LINK_TO_CUSTOMER_METHOD.SMS]: 'ais.labels.sendMethodSms',
  [AIS_LINK_TO_CUSTOMER_METHOD.EMAIL]: 'ais.labels.sendMethodEmail',
}

export const SHOP_TYPE = {
  OFFLINE_PARTNER: 'offline_partner',
}

export const DOCUMENT_TYPE = {
  CREDIT_APPLICATION: 'credit_application',
  CONTRACT: 'contract',
  OFFER: 'offer',
}

export const ATTACHMENT_TYPE = {
  INCOME_PROOF_DOCUMENT: 'income_proof_document',
}

export const DECISION_STATUS = {
  PENDING: 'pending',
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  MANUAL_NEGATIVE: 'manual_negative',
  INCOME_PROOF_REQUIRED: 'income_proof_required',
  WAITING_FOR_INCOME_PROOF: 'waiting_for_income_proof',
  FAILED: 'failed',
}

export const CREDIT_APPLICATION_PROCESS_STATUS_FILTERS = {
  EE: [
    'CANCELLED',
    'TERMINATED',
    'ACCEPTED',
    'INITIATED',
    'REJECTED',
    'RETURNED',
    'ACTIVATED',
  ],
}

export const COUNTRY_PROCESS_STATUS_FILTERS = {
  EE: [
    'offer',
    'pending_decision',
    'waiting_for_decision',
    'negative_decision',
    'waiting_for_activation',
    'failed_registry_errors',
    'waiting_for_income_proof',
    'waiting_for_customer_signature',
    'waiting_for_partners_confirmation',
    'waiting_for_application_confirmation',
    'waiting_for_down_payment',
    'waiting_for_credit_verification',
    'cancelled',
    'activated',
    'terminated',
  ],
  LV: [
    'offer',
    'pending_decision',
    'waiting_for_decision',
    'negative_decision',
    'waiting_for_activation',
    'failed_registry_errors',
    'waiting_for_income_proof',
    'waiting_for_customer_signature',
    'waiting_for_partners_confirmation',
    'waiting_for_application_confirmation',
    'waiting_for_down_payment',
    'waiting_for_credit_verification',
    'cancelled',
    'activated',
    'terminated',
  ],
  PL: [
    'offer',
    'pending_decision',
    'waiting_for_decision',
    'negative_decision',
    'waiting_for_activation',
    'failed_registry_errors',
    'waiting_for_income_proof',
    'waiting_for_customer_signature',
    'waiting_for_partners_confirmation',
    'waiting_for_application_confirmation',
    'waiting_for_blue_media_verification',
    'waiting_for_customer_digital_verification',
    'waiting_for_credit_verification',
    'cancelled',
    'activated',
    'terminated',
  ],
  CZ: [
    'offer',
    'pending_decision',
    'waiting_for_decision',
    'negative_decision',
    'waiting_for_activation',
    'failed_registry_errors',
    'waiting_for_income_proof',
    'waiting_for_customer_signature',
    'waiting_for_partners_confirmation',
    'waiting_for_application_confirmation',
    'waiting_for_down_payment',
    'waiting_for_credit_verification',
    'cancelled',
    'activated',
    'terminated',
  ],
  LT: [
    'offer',
    'pending_decision',
    'waiting_for_decision',
    'negative_decision',
    'waiting_for_activation',
    'failed_registry_errors',
    'waiting_for_income_proof',
    'waiting_for_customer_signature',
    'waiting_for_partners_confirmation',
    'waiting_for_application_confirmation',
    'waiting_for_down_payment',
    'waiting_for_credit_verification',
    'cancelled',
    'activated',
    'terminated',
  ],
}

export const ADDRESS_TYPE = {
  LEGAL: 'legal',
  CONTACT: 'contact',
}

export const ADDRESS_SOURCE = {
  BANK_ID: 'bank_id',
  GOOGLE: 'google',
  MANUAL: 'manual',
}

export const APPLICATION_REQUIRED_PARAMS = {
  PARTNER_REFERENCE: 'creditApplication.additionalDetails.partner.reference',
}

export const CALENDAR = {
  DAYS: [
    ['calendar.day.sunday', {}, 'Sun'],
    ['calendar.day.monday', {}, 'Mon'],
    ['calendar.day.tuesday', {}, 'Tue'],
    ['calendar.day.wednesday', {}, 'Wed'],
    ['calendar.day.thursday', {}, 'Thu'],
    ['calendar.day.friday', {}, 'Fri'],
    ['calendar.day.saturday', {}, 'Sat'],
  ],
  MONTHS: [
    ['calendar.month.january', {}, 'Jan'],
    ['calendar.month.february', {}, 'Feb'],
    ['calendar.month.march', {}, 'Mar'],
    ['calendar.month.april', {}, 'Apr'],
    ['calendar.month.may', {}, 'May'],
    ['calendar.month.june', {}, 'Jun'],
    ['calendar.month.july', {}, 'Jul'],
    ['calendar.month.august', {}, 'Aug'],
    ['calendar.month.september', {}, 'Sep'],
    ['calendar.month.october', {}, 'Oct'],
    ['calendar.month.november', {}, 'Nov'],
    ['calendar.month.december', {}, 'Dec'],
  ],
  PICKERS: [
    ['calendar.pickers.next7days', {}, 'next 7 days'],
    ['calendar.pickers.next30days', {}, 'next 30 days'],
    ['calendar.pickers.previous7days', {}, 'previous 7 days'],
    ['calendar.pickers.previous30days', {}, 'previous30days'],
  ],
}

export const FILTER_TYPE = {
  BETWEEN: 'between',
}

export const COUNTRY_CODE = {
  PL: 'PL',
  EE: 'EE',
  LV: 'LV',
  LT: 'LT',
  FI: 'FI',
  SE: 'SE',
  NO: 'NO',
  CZ: 'CZ',
}

export const LANGUAGE_CODE = {
  ET: 'et',
  PL: 'pl',
  RU: 'ru',
  EN: 'en',
  LV: 'lv',
  CS: 'cs',
  LT: 'lt',
}

export const ADDRESS_COUNTRY_OPTIONS = [COUNTRY_CODE.EE, COUNTRY_CODE.PL]

export const RESIDENCY_OPTIONS = [COUNTRY_CODE.EE, COUNTRY_CODE.PL]

export const ID_DOCUMENT_TYPE = {
  ID_CARD: 'id_card',
  DRIVERS_LICENCE: 'drivers_licence',
  PASSPORT: 'passport',
  FOREIGNER_PASSPORT: 'foreigner_passport',
  RESIDENCE_PERMIT: 'residence_permit',
}

export const PEP_STATUS = {
  NOT_PEP: 'not_pep',
  PEP_IN_LOCAL_COUNTRY: 'pep_in_local_country',
  PEP_IN_EU: 'pep_in_eu',
  PEP_IN_THIRD_COUNTRY: 'pep_in_third_country',
}

export const PEP_POSITION = {
  OPTION_UNO: 'option_uno',
  OPTION_DOS: 'option_dos',
}

export const CIVIL_STATUS = {
  SINGLE: 'single',
  MARRIED_W_COMMON_PROPERTY: 'married_w_common_property',
  MARRIED_W_DIVISION_PROPERTY: 'married_w_division_property',
  LIVING_W_PARTNER: 'living_w_partner',
}

export const HOUSING_STATUS = {
  OWNING_HOUSE_APARTMENT: 'owning_house_apartment',
  TENANT: 'tenant',
  LIVING_W_PARENTS: 'living_w_parents',
}

export const CREDIT_PURPOSE = {
  OPTION_UNO: 'option_uno',
}

export const INCOME_SOURCE = {
  EMPLOYMENT_INDEFINITE: 'employment_indefinite',
  EMPLOYMENT_DEFINITE: 'employment_definite',
  FREE_FOR_TASK: 'free_for_task',
  SELF_EMPLOYED: 'self_employed',
  PENSION: 'pension',
  DISABILITY_LIVING_ALLOWANCE: 'disability_living_allowance',
  FARMER: 'farmer',
  LEASE_INCOME: 'lease_income',
}

export const EMPLOYMENT_POSITION = {
  PRIVATE_SECTOR_EMPLOYEE: 'private_sector_employee',
  PUBLIC_SECTOR_EMPLOYEE: 'public_sector_employee',
  TOP_MANAGER: 'top_manager',
  SELF_EMPLOYED: 'self_employed',
  SAILOR: 'sailor',
  EMPLOYED_ABROAD: 'employed_abroad',
  NOT_WORKING: 'not_working',
  PARENTAL_LEAVE: 'parental_leave',
  RETIRED: 'retired',
  OTHER: 'other',
}

export const EMPLOYMENT_POSITION_PEP = {
}

export const PURCHASE_ITEM_TYPE = {
  VEHICLE: 'vehicle',
}

export const PHONE_TYPE = {
  MOBILE: 'mobile',
  LANDLINE: 'landline',
}

export const MAX_INCOME = 999999

export const ISSUER_PLACE_MAX_LENGHT = 100

export const DURATION_MILLISECONDS = {
  SECOND: 1000,
}

export const DURATION_SECONDS = {
  MINUTE: 60,
}

export const TIMEOUT_DURATION = {
  SECOND: DURATION_MILLISECONDS.SECOND,
}

export const PERCENTAGE = {
  MULTIPLIER: 100,
  DECIMALS: 2,
}

export const HTTP_STATUS_CODE = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  PAYLOAD_TOO_LARGE: 413,
  UNPROCESSABLE_ENTITY: 422,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  GATEWAY_TIMEOUT: 504,
}

export const FLOW_TYPE = {
  EE: 'EE',
  PL: 'PL',
  LV: 'LV',
  CZ: 'CZ',
  INVOICE: 'INVOICE',
  RENT: 'RENT',
}

export const FLOW_CODE = {
  EE_PP_HP_1: 'ee_pp_hp_1',
}

export const DOWN_PAYMENT_COLLECTOR = {
  MERCHANT: 0,
  INBANK: 1,
}

export const CURRENCY = {
  EUR: 'EUR',
  CZK: 'CZK',
}

export const IDENTIFICATION_STATUS = {
  ACCEPTED: 'accepted',
}

export const CONTRACT_TYPE = {
  INVOICE: 'invoice',
}

export const CONTRACT_STATUS = {
  ACTIVATED: 'activated',
}

export const PROCESS_STATUS = {
  ACTIVATED: 'activated',
  CANCELLED: 'cancelled',
  FAILED_REGISTRY_ERRORS: 'failed_registry_errors',
  NEGATIVE_DECISION: 'negative_decision',
  OFFER: 'offer',
  PENDING_DECISION: 'pending_decision',
  WAITING_FOR_ACTIVATION: 'waiting_for_activation',
  WAITING_FOR_APPLICATION_CONFIRMATION: 'waiting_for_application_confirmation',
  WAITING_FOR_CUSTOMER_SIGNATURE: 'waiting_for_customer_signature',
  WAITING_FOR_DOWN_PAYMENT: 'waiting_for_down_payment',
  WAITING_FOR_PARTNERS_CONFIRMATION: 'waiting_for_partners_confirmation',
}

export const ASSET_STATUS = {
  ASSET_RETURNED_BY_CUSTOMER: 'ASSET_RETURNED_BY_CUSTOMER',
  RETURNED_BY_CUSTOMER: 'RETURNED_BY_CUSTOMER',
}

export const CREDIT_PURCHASE_STATUS = {
  ACTIVATED: 'ACTIVATED',
  ACCEPTED: 'ACCEPTED',
  CANCELLED: 'CANCELLED',
  INITIATED: 'INITIATED',
  REJECTED: 'REJECTED',
  RETURNED: 'RETURNED',
}

export const IDENTIFICATION_CHANNEL = {
  PARTNER: 'partner',
}

export const DEPENDANTS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

export const GENDERS = ['f', 'm']

export const VALIDATION_FUNCTIONS = {
  IBAN: 'iban',
  IS_FILLED: 'isFilled',
  INTEGER: 'integer',
  IN_RANGE: 'inRange',
  EMAIL: 'email',
  PHONE: 'phone',
  PERSON_NAME: 'personName',
  DOCUMENT_NR: 'documentNumber',
  DATE: 'date',
}

export const EVENTS = {
  VALIDATE: 'events/validate',
}

export const CZ_SMALL_LOAN_1 = 'cz_small_loan_1'

export const SHOW_PAYOUT_ACCOUNT = ['lv_pp_sl_1', 'lv_pp_cl_1', 'cz_small_loan_1']

export const PRODUCT_SUBTYPE = {
  CAR_LOAN: 'car_loan',
}

export const PREFERRED_LANG_COOKIE_KEY = 'pref_lang'

export const CHANNEL = {
  E_POS: 'e_pos',
  PARTNER_PORTAL: 'partner_portal',
}

export const DEVICE_IDENTIFIER = {
  IMEI: 'IMEI',
  SERIAL_NUMBER: 'SERIAL_NUMBER',
}

export const BANK_LIST = {
  CZ: {
    '0100': 'Komerční banka, a.s.',
    '0300': 'Československá obchodní banka, a. s.',
    '0600': 'MONETA Money Bank, a.s.',
    '0710': 'ČESKÁ NÁRODNÍ BANKA',
    '0800': 'Česká spořitelna, a.s.',
    2010: 'Fio banka, a.s.',
    2060: 'Citfin, spořitelní družstvo',
    2070: 'TRINITY BANK a.s.',
    2100: 'Hypoteční banka, a.s.',
    2200: 'Peněžní dům, spořitelní družstvo',
    2220: 'Artesa, spořitelní družstvo',
    2250: 'Banka CREDITAS a.s.',
    2260: 'NEY spořitelní družstvo',
    2275: 'Podnikatelská družstevní záložna',
    2600: 'Citibank Europe plc, organizační složka',
    2700: 'UniCredit Bank Czech Republic and Slovakia, a.s.',
    3030: 'Air Bank a.s.',
    3050: 'BNP Paribas Personal Finance SA, odštěpný závod',
    3060: 'PKO BP S.A., Czech Branch',
    3500: 'ING Bank N.V.',
    4000: 'Max banka a.s.',
    4300: 'Národní rozvojová banka, a.s.',
    5500: 'Raiffeisenbank a.s.',
    5800: 'J&T BANKA, a.s.',
    6000: 'PPF banka a.s.',
    6100: 'Raiffeisenbank a.s. (do 31. 12. 2021 Equa bank a.s.)',
    6200: 'COMMERZBANK Aktiengesellschaft, pobočka Praha',
    6210: 'mBank S.A., organizační složka',
    6300: 'BNP Paribas S.A., pobočka Česká republika',
    6700: 'Všeobecná úverová banka a.s., pobočka Praha',
    6800: 'Sberbank CZ, a.s. v likvidaci',
    7910: 'Deutsche Bank Aktiengesellschaft Filiale Prag, organizační složka',
    7950: 'Raiffeisen stavební spořitelna a.s.',
    7960: 'ČSOB Stavební spořitelna, a.s.',
    7970: 'MONETA Stavební Spořitelna, a.s.',
    7990: 'Modrá pyramida stavební spořitelna, a.s.',
    8030: 'Volksbank Raiffeisenbank Nordoberpfalz eG pobočka Cheb',
    8040: 'Oberbank AG pobočka Česká republika',
    8060: 'Stavební spořitelna České spořitelny, a.s.',
    8090: 'Česká exportní banka, a.s.',
    8150: 'HSBC Continental Europe, Czech Republic',
    8190: 'Sparkasse Oberlausitz-Niederschlesien',
    8198: 'FAS finance company s.r.o.',
    8199: 'MoneyPolo Europe s.r.o.',
    8200: 'PRIVAT BANK der Raiffeisenlandesbank Oberösterreich Aktiengesellschaft, pobočka Česká republika',
    8220: 'Payment execution s.r.o.',
    8230: 'ABAPAY s.r.o.',
    8240: 'Družstevní záložna Kredit, v likvidaci',
    8250: 'Bank of China (CEE) Ltd. Prague Branch',
    8255: 'Bank of Communications Co., Ltd., Prague Branch odštěpný závod',
    8265: 'Industrial and Commercial Bank of China Limited, Prague Branch, odštěpný závod',
    8270: 'Fairplay Pay s.r.o.',
    8280: 'B-Efekt a.s.',
    8293: 'Mercurius partners s.r.o.',
    8299: 'BESTPAY s.r.o.',
    8500: 'Multitude Bank p.l.c.',
  },
}
