<template>
  <b-modal
    ref="confirmModal"
    :centered="true"
    tabindex="-1"
    role="dialog"
    footer-class="d-flex justify-content-between"
  >
    <h5 class="text-center">
      {{ text || 'Confirm action?' }}
    </h5>
    <p v-if="description" class="mt-3 mb-0 px-3" v-html="description" />
    <div v-if="showPartialReturn">
      <hire-purchase-returns-modal
        :partial-return="partialReturn"
      />
    </div>
    <div slot="modal-footer" class="col-12">
      <inb-button id="confirm" variant="primary" @click.once="confirm()">
        {{ confirmButton || 'OK' }}
      </inb-button>
      <inb-button id="dismiss" variant="outline-primary" class="float-right" @click="dismiss()">
        {{ cancelButton || 'Cancel' }}
      </inb-button>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'

import { InbButton } from 'inbank-frontend-commons/components/base-components'
import { onMounted, computed } from '@nuxtjs/composition-api'

import HirePurchaseReturnsModal from '~/components/hire-purchase/details/HirePurchaseReturnsModal.vue'
import PartialReturn from '~/models/PartialReturn'

export default {
  name: 'ConfirmModal',
  components: { InbButton, HirePurchaseReturnsModal },
  props: {
    // bus is deprecated, use ref instead
    bus: {
      type: Vue,
      default: () => new Vue(),
    },
    confirmButton: {
      type: String,
      default: null,
    },
    cancelButton: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    partialReturn: {
      type: PartialReturn,
      default: () => new PartialReturn(),
    },
  },
  setup(props, context) {
    const showPartialReturn = computed(() => !_.isEmpty(props.partialReturn?.contractVersions))

    function show() {
      context.refs.confirmModal.show()
    }

    function hide() {
      context.refs.confirmModal.hide()
    }

    function confirm() {
      context.emit('confirmed')
      hide()
    }

    function dismiss() {
      context.emit('dismissed')
      hide()
    }

    onMounted(() => {
      props.bus.$on('show', () => {
        show()
      })

      props.bus.$on('hide', () => {
        hide()
      })
    })

    return {
      confirm,
      dismiss,
      show,
      hide,
      showPartialReturn,
    }
  },
}
</script>
