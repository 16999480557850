/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Calculation from '~/models/Calculation'

export const state = () => ({
  calculation: new Calculation(),
})

export const mutations = {
  setCalculation(state, calculation) {
    state.calculation = new Calculation(calculation)
  },
}

export const actions = {
  setCalculation({ commit }, calculation) {
    commit('setCalculation', calculation)
  },
}
