<template>
  <div class="col-12 ib-file-uploader">
    <div class="form-group">
      <form ref="uploadForm" enctype="multipart/form-data" @submit.prevent="submitForm">
        <div class="file-dropzone-wrapper" data-file-input-id="attachments">
          <div v-if="isUploading" class="loading">
            <div class="loader-circle loader-circle--large" />
          </div>
          <input
            id="attachments"
            ref="fileInput"
            type="file"
            name="attachments"
            class="file-input"
            multiple="multiple"
            @change="inputChanged"
          >
          <input ref="formSubmit" type="submit" class="d-none">
          <div ref="dropzone" :class="{ 'is-dragover' : dragEntered }" class="file-dropzone">
            <p :class="{ opaque: isUploading }" class="draggable">
              {{ description }}
            </p>
            <p
              :class="{ opaque: isUploading }"
              class="non-draggable btn btn-outline-primary"
              @click="triggerFileSelect"
            >
              {{ buttonText }}
            </p>
          </div>
        </div>
        <table v-if="files.length" class="table table-bordered">
          <thead>
            <tr>
              <th>{{ t('fileUploader.labels.fileList') }}</th>
            </tr>
            <tr v-for="(file, index) of files" :key="index">
              <td class="file-item">
                <inb-button variant="link" @click="removeFile(index)">
                  <span class="icons icon-times mr-2" />
                </inb-button>
                {{ file.name }}
              </td>
            </tr>
          </thead>
          <tbody />
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import { InbButton } from 'inbank-frontend-commons/components/base-components'

import UploadService from '~/services/api/UploadService'

import { EVENT } from '~/constants/prometheus'
import { usePrometheus } from '~/use/Prometheus'

export default {
  name: 'FileUploader',
  components: { InbButton },
  props: {
    description: {
      type: String,
      default: 'Drag and drop files here, or click to upload',
    },
    buttonText: {
      type: String,
      default: 'Click to upload',
    },
    path: {
      type: String,
      default: '',
    },
    formData: {
      type: Object,
      default: () => {},
    },

  },
  setup(props, context) {
    return { ...usePrometheus(context) }
  },
  data() {
    return {
      fileInput: this.$refs.fileInput,
      dropzone: this.$refs.dropzone,
      dragEntered: false,
      files: [],
      isUploading: false,
      errors: [],
    }
  },
  computed: {
    isCompatible() {
      return ('draggable' in document.createElement('div'))
    },
  },
  watch: {
    files(files) {
      this.$emit('files-changed', files)
    },
  },
  async mounted() {
    if (this.isCompatible) {
      // Prevent default events
      await ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((eventName) => {
        this.preventDefault(eventName)
      })

      await ['dragover', 'dragenter'].forEach((eventName) => {
        this.onDragEnter(eventName)
      })

      await ['dragleave', 'dragend', 'drop'].forEach((eventName) => {
        this.onDragLeave(eventName)
      })

      await ['drop'].forEach((eventName) => {
        this.onDrop(eventName)
      })
    }
  },
  methods: {
    upload() {
      if (this.files.length > 0) {
        this.$refs.formSubmit.click()
      }
    },
    async submitForm() {
      const formData = new FormData()

      Object.keys(this.formData).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
          formData.append(key, this.formData[key])
        }
      })

      for (let i = 0; i < this.files.length; i += 1) {
        formData.append(`attachments[${i}]`, this.files[i])
      }

      this.isUploading = true
      try {
        await UploadService.upload(this.path, formData)
        this.promBroadcastSuccess(EVENT.MANUAL_INCOME_VERIFICATION)
        this.$emit('done')
      } catch (error) {
        this.promBroadcastUnknownError(EVENT.MANUAL_INCOME_VERIFICATION, error)
        this.$emit('error', error)
      }

      this.isUploading = false

      this.files = []
      this.$refs.fileInput.value = null
    },
    addFiles(files) {
      for (let i = 0; i < files.length; i += 1) {
        this.files.push(files[i])
      }
    },
    removeFile(index) {
      this.files.splice(index, 1)
    },
    preventDefault(eventName) {
      this.$refs.dropzone.addEventListener(eventName, (e) => {
        e.preventDefault()
        e.stopPropagation()
      }, false)
    },
    inputChanged() {
      this.addFiles(this.$refs.fileInput.files)
    },
    triggerFileSelect() {
      this.$refs.fileInput.click()
    },
    onFileDropped(e) {
      this.addFiles(e.dataTransfer.files)
    },
    onDrop(eventName) {
      this.$refs.dropzone?.addEventListener(eventName, (e) => {
        this.onFileDropped(e)
      })
    },
    onDragEnter(eventName) {
      this.$refs.dropzone?.addEventListener(eventName, () => {
        this.dragEntered = true
      }, false)
    },
    onDragLeave(eventName) {
      this.$refs.dropzone?.addEventListener(eventName, () => {
        this.dragEntered = false
      }, false)
    },
  },
}
</script>

<style lang="scss">
  .ib-file-uploader{
    .file-dropzone-wrapper{
      position: relative;
    }
    .opaque{
      opacity: 0;
    }
    .file-item{
      padding: 0rem 1.25rem;
    }
    .loading{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      .loader-circle{
        left: 50%;
        top: 50%;
        margin-left: -41px;
        margin-top: -41px;
      }
    }
  }
</style>
