import { computed } from '@nuxtjs/composition-api'

import UserService from '~/services/api/UserService'
import Shop from '~/models/Shop'
import Organisation from '~/models/Organisation'
import { Context } from '~/types/common'
import User from '~/models/User'

export function useCurrentUser(context: Context) {
  const currentUser = computed<User>(() => context.root.$store.state.auth.user)

  const currentShop = computed(() => {
    if (!currentUser.value) {
      return null
    }
    const { viewingAllShops, tempSelectedShop, selectedShop } = currentUser.value
    if (viewingAllShops && tempSelectedShop) {
      return tempSelectedShop
    }
    return selectedShop
  })

  const currentShopUuid = computed(() => currentShop.value && currentShop.value.uuid)

  const availableOrganizations = computed(() => {
    if (!currentUser.value) {
      return []
    }
    return currentUser.value.organizations
  })

  const availableShops = computed(() => {
    let shops = [new Shop({
      name: context.root.t('store.labels.allShops'),
    })]

    if (!currentUser.value) {
      return []
    }

    availableOrganizations.value.forEach((organization: Organisation) => {
      shops = shops.concat(organization.shops)
    })
    return shops
  })

  const currentOrganization = computed(() => {
    const tmpOrgUuid = currentUser.value?.tmpOrgUuid

    if (tmpOrgUuid) {
      return availableOrganizations.value.find(
        (org: Organisation) => org.uuid === tmpOrgUuid,
      )
    }

    const shopUuid = currentShopUuid.value
    return availableOrganizations.value.find(
      (org: Organisation) => org.shops.map((s: Shop) => s.uuid).includes(shopUuid),
    )
  })

  async function fetchUser({ force = false }: { force: boolean } = { force: false }) {
    if (!currentUser.value || force) {
      return UserService.getCurrentUser(context.root.$store)
    }
    return currentUser.value
  }

  async function setTemporaryShop({ shop, uuid }: { shop?: Shop, uuid?: string }) {
    const tmpShop = shop || availableShops.value.find((s) => s.uuid === uuid)
    await UserService.selectShop(tmpShop!.uuid, true)
    await fetchUser({ force: true })
  }

  async function deselectShop() {
    await UserService.deselectShop()
    await fetchUser({ force: true })
  }

  function setTemporaryOrg(orgUuid: string) {
    currentUser.value.tmpOrgUuid = orgUuid
    context.root.$store.dispatch('auth/setUser', new User(currentUser.value))
  }

  async function deselectTemporaryOrg() {
    currentUser.value.tmpOrgUuid = undefined
    context.root.$store.dispatch('auth/setUser', new User(currentUser.value))
  }

  function getShopByUuid(uuid: string) {
    let shops: Shop[] = []
    const { organizations } = currentUser.value
    organizations.forEach((organization: Organisation) => {
      shops = [...shops, ...organization.shops]
    })
    return shops.find((shop) => shop.uuid === uuid)
  }

  function getOrgByShopUuid(shopUuid: string) {
    return availableOrganizations.value
      .find((o: Organisation) => o.shops.find((s: Shop) => s.uuid === shopUuid))
  }

  return {
    currentUser,
    currentShop,
    currentShopUuid,
    currentOrganization,
    availableOrganizations,
    availableShops,
    fetchUser,
    setTemporaryShop,
    deselectShop,
    getShopByUuid,
    setTemporaryOrg,
    deselectTemporaryOrg,
    getOrgByShopUuid,
  }
}

export default {}
