<template>
  <!-- eslint-disable max-len -->
  <div v-if="hasNotifications">
    <div v-for="(notification, index) in notifications" :key="`notification-${index}`">
      <alert v-if="notification.fields.messageType === 'danger'"
             variant="danger"
             icon="icon-ban"
      >
        <span v-html="notification.fields.notificationText" />
      </alert>
      <alert v-else-if="notification.fields.messageType === 'warning'"
             variant="warning"
             icon="icon-exclamation"
      >
        <span v-html="notification.fields.notificationText" />
      </alert>
      <alert v-else
             variant="info"
             icon="icon-lightbulb-on"
      >
        <span v-html="notification.fields.notificationText" />
      </alert>
    </div>
  </div>
</template>

<script>
import {
  computed, ref, watch, onMounted,
} from '@nuxtjs/composition-api'
import {
  CONTENTFUL_CONTENT_TYPE,
} from '~/constants/contentful'

import { createClient } from '~/plugins/contentful'

export default {
  name: 'Notifications',
  setup(props, context) {
    const notifications = ref([])
    const hasNotifications = computed(() => !!notifications.value.length)

    async function fetchNotifications() {
      try {
        const cfResponse = await createClient(context.root.$env.NODE_ENV).getEntries({
          content_type: CONTENTFUL_CONTENT_TYPE.NOTIFICATION,
          locale: context.root.$i18n.locale,
          order: '-sys.createdAt',
        })
        notifications.value = [
          ...cfResponse.items,
        ]
      } catch {
        // nothing to do here
      }
    }

    onMounted(() => {
      fetchNotifications()
    })
    watch(() => context.root.$i18n.locale, () => {
      fetchNotifications()
    })
    return {
      notifications,
      hasNotifications,
    }
  },
}
</script>
