<template>
  <div
    :class="{ 'overlayed-loader' : overlayed }"
    class="modal-dialog modal-dialog-centered justify-content-center"
  >
    <div class="loader">
      <i-loader class="transparent-loader-spinner" :transition-ms="300" />
    </div>
  </div>
</template>

<script>
import { ILoader } from 'inbank-frontend-commons/components/common'

export default {
  name: 'Loader',
  components: { ILoader },
  props: {
    overlayed: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style type="text/css">
  .overlayed-loader{
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 100%;
    left: 0px;
  }
</style>
