import { computed } from '@nuxtjs/composition-api'
import { Context } from '~/types/common'
import { useReportingPermissions } from '~/use/reporting/ReportingPermissions'

export function useDashboard(context: Context) {
  const { hasViewReportsPermission } = useReportingPermissions(context)

  const dashboardEnabled = computed(
    () => hasViewReportsPermission.value
      && context.root.$localisation.features().dashboard,
  )

  return {
    dashboardEnabled,
  }
}

export default {}
