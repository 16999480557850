<template>
  <footer class="d-flex">
    <div class="app-footer">
      <a :href="t('partnerPortal.footer.privacyPolicy.link')" target="_blank">
        {{ t("partnerPortal.footer.privacyPolicy.text") }}
      </a>
      <span>•</span>
      <nuxt-link :to="localePath('cookie-policy')">
        {{ t('partnerPortal.footer.cookiePolicy.text') }}
      </nuxt-link>
      <span>•</span>
      <a :href="t('partnerPortal.footer.termsAndConditions.link')" target="_blank">
        {{ t("partnerPortal.footer.termsAndConditions.text") }}
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style lang="scss" scoped>
footer {
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
  .app-footer {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #757575;
    font-size: 13px;
    opacity: 0.9;
    text-align: center;
    @media (max-width: 425px) {
      gap: 8px;
    }
  }
}
</style>
