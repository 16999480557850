import ApiError from '~/models/ApiError'
import { Context } from '~/types/common'

interface PrometheusEvent {
    NAME: string,
    RESULTS: {
        SUCCESS: string,
        SERVER_ERROR: string,
        CLIENT_ERROR: string,
        CLIENT_CANCEL: string,
        APP_ERROR: string,
    },
}

export function usePrometheus(context: Context) {
  function promBroadcastSuccess(eventName: PrometheusEvent, method?: string) {
    context.root.$prometheus.broadcast(eventName.NAME, {
      method,
      result: eventName.RESULTS.SUCCESS,
    })
  }

  function promBroadcastUnknownError(eventName: PrometheusEvent, error: ApiError, method?: string) {
    context.root.$prometheus.broadcast(eventName.NAME, {
      method,
      result: context.root.$prometheus.getResultType(error),
    })
  }

  function promBroadcastCancel(eventName: PrometheusEvent, method: string) {
    context.root.$prometheus.broadcast(eventName.NAME, {
      method,
      result: eventName.RESULTS.CLIENT_CANCEL,
    })
  }

  function promBroadcastError(eventName: PrometheusEvent, error: string, method?: string) {
    context.root.$prometheus.broadcast(eventName.NAME, {
      method,
      result: error,
    })
  }

  return {
    promBroadcastSuccess,
    promBroadcastUnknownError,
    promBroadcastError,
    promBroadcastCancel,
  }
}

export default {}
