interface contractChange {
    returnAmount: number,
    type: string,
    uuid: string,
}
interface SourceContractChange {
    contractChangeDetails: contractChange[],
    createdAt: string,
    fromContractVersionUuid: string,
    toContractVersionUuid: string,
    uuid: string,
}
export interface ContractVersion {
    activatedAt: string,
    consumerCreditInformationUuid: string,
    createdAt: string,
    paymentScheduleUuid: string,
    sourceContractChange: SourceContractChange,
    pricingUuid: string,
    status: string,
    uuid: string,
  }
export default class PartialReturn {
    contractVersions?: ContractVersion[]

    constructor(args: any = {}) {
      this.contractVersions = args.contractVersions || {}
    }
}
