<template>
  <b-alert :variant="variant" class="p-3 d-flex align-items-center" show>
    <div class="text-center">
      <span :class="iconClass" class="icons icon-alert mr-2" />
    </div>
    <div class="flex-lg-grow-1 text-wrap">
      <div v-if="text">
        {{ text }}
      </div>
      <slot v-else />
    </div>
  </b-alert>
</template>

<script>
import { computed } from '@nuxtjs/composition-api'

export default {
  name: 'Alert',
  props: {
    variant: {
      type: String,
      default: 'info',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const iconClass = computed(() => ({
      [props.icon]: true,
      [`text-${props.variant}`]: true,
    }))

    return {
      iconClass,
    }
  },
}
</script>

<style lang="scss">
  .fz-14{
    .text-wrap{
      font-size:14px;
    }
  }
</style>
