import _ from 'lodash'

import Address from '~/models/Address'
import Product from '~/models/Product'
import CreditApplication from '~/models/CreditApplication'
import PaymentSchedule from '~/models/PaymentSchedule'
import Purchase from '~/models/Purchase'
import Contract from '~/models/Contract'
import { DECISION_STATUS } from '~/constants'

export default class ApplicationDetail {
  static constructFormResponse(responseData) {
    const data = _.cloneDeep(responseData)
    data.creditApplication.underageDependantsCount = responseData.creditApplication.dependantsOver_18
    if (responseData.initialCreditApplication) {
      data.initialCreditApplication.underageDependantsCount = responseData.initialCreditApplication.dependantsOver_18
    }
    return new ApplicationDetail(data)
  }

  constructor(
    {
      customer = {},
      creditApplication = {},
      initialCreditApplication = {},
      product,
      purchase,
      paymentSchedule = {},
      customerAddresses = [],
      contract = {},
      shop = {},
    } = { customer: {} },
  ) {
    this.creditApplication = new CreditApplication(creditApplication)

    this.initialCreditApplication = new CreditApplication(initialCreditApplication)

    this.customer = {
      uuid: null,
      identityCode: null,
      firstName: null,
      lastName: null,
      residency: null,
      taxResidency: null,
      pepStatus: null,
      pepPosition: null,
      isUbo: null,
      communicationLanguage: null,
      contacts: {
        phoneMobile: null,
        phoneLandline: null,
        email: null,
        ...(customer ? customer.contacts : {}),
      },
      addresses: customerAddresses.map((address) => new Address(address)),
      identifications: [],
      status: null,
      statusDescription: null,
      ...customer,
    }

    this.product = new Product(product || {})

    this.paymentSchedule = paymentSchedule !== null ? new PaymentSchedule(paymentSchedule) : null

    this.purchase = purchase ? new Purchase(purchase) : null

    // Spreading arguments in case if contract is null
    this.contract = new Contract({ ...contract })

    this.shop = {
      ...shop,
    }
  }

  get changedConditionsRequiresIncomeProof() {
    return this.creditApplication.decisionStatus === DECISION_STATUS.INCOME_PROOF_REQUIRED
        && +this.creditApplication.amount > +this.creditApplication.maxLimit
  }

  get isRental() {
    return this.creditApplication && !!this.creditApplication.rentalQuoteUuid
  }
}
