<template>
  <div>
    <label v-if="label" for="search-select">
      {{ label }}
    </label>
    <div
      :class="{
        'active': menuVisible,
        'small': isSmall,
        'large-menu': largeMenu,
        'is-invalid': isInvalid

      }"
      class="custom-select-wrap custom-select"
    >
      <input
        id="search-select"
        v-model="filterValue"
        :placeholder="placeholder"
        :class="{'is-invalid': isInvalid}"
        type="text"
        class="custom-select-input"
        @blur="onInputBlur"
        @focus="onInputFocus"
        @keyup="onFilterChange()"
      >
      <div
        :class="{ visible : menuVisible, large : largeMenu }"
        class="select-menu"
        @mouseover="isHoveringMenu = true"
        @mouseleave="isHoveringMenu = false"
      >
        <div v-if="defaultItem" class="item" @click="onItemSelect(defaultItem)">
          {{ defaultItem.name }}
        </div>

        <div v-if="!grouped">
          <div
            v-for="(item, index) in visibleItems"
            :key="index"
            :title="item[`${itemLabel}`]"
            class="item"
            @click="onItemSelect(item)"
          >
            {{ item[`${itemLabel}`] }}
          </div>
        </div>

        <div v-else>
          <div v-for="(group, index) in visibleItems" :key="index" class="group">
            <p :title="group[`${groupLabel}`]">
              {{ group[`${groupLabel}`] }}
            </p>
            <div
              v-for="(item, _index) in group[groupItems]"
              :key="_index"
              :title="item[`${itemLabel}`]"
              class="item"
              @click="onItemSelect(item)"
            >
              {{ item[`${itemLabel}`] }}
            </div>
          </div>
        </div>

        <div v-if="!visibleItems.length" class="message text-center px-2">
          {{ noResultsText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SearchSelect',
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: '',
    },
    groupLabel: {
      type: String,
      default: '',
    },
    itemLabel: {
      type: String,
      default: '',
    },
    searchField: {
      type: String,
      default: '',
    },
    groupItems: {
      type: String,
      default: '',
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    defaultItem: {
      type: Object,
      default: null,
    },
    noResultsText: {
      type: String,
      default: 'Sorry, no results',
    },
    largeMenu: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuVisible: false,
      placeholder: '',
      filterValue: '',
      visibleItems: [],
      isHoveringMenu: false,
    }
  },
  watch: {
    items(before, after) {
      if (!_.isEqual(before, after)) {
        this.resetList()
      }
    },
    defaultItem(value) {
      if (!value) {
        this.resetList()
      }
    },
  },
  mounted() {
    this.resetList()
  },
  methods: {
    onFilterChange() {
      if (!this.filterValue.length) {
        this.visibleItems = _.cloneDeep(this.items)
        return
      }

      const filterValue = this.filterValue.toLowerCase().trim()
      const tempItems = _.cloneDeep(this.items)

      if (this.grouped) {
        this.visibleItems = tempItems.filter((group) => {
          const items = group[this.groupItems].filter(
            (item) => item[this.itemLabel].toLowerCase().includes(filterValue),
          )
          // eslint-disable-next-line no-param-reassign
          group[this.groupItems] = items
          return items.length
        })
      } else {
        this.visibleItems = tempItems.filter(
          (item) => item[this.searchField || this.itemLabel].toLowerCase().includes(filterValue),
        )
      }
    },
    onInputFocus() {
      this.menuVisible = true
    },
    onItemSelect(item) {
      this.resetList()
      this.placeholder = item[this.itemLabel]
      this.$emit('onSelect', item)
      this.$emit('input', item[this.itemValue])
    },
    onInputBlur() {
      if (!this.isHoveringMenu) {
        this.resetList()
      }
    },
    setActiveElement() {
      if (!this.value && this.defaultItem) {
        this.placeholder = this.defaultItem.name
        return
      }

      if (this.grouped) {
        this.items.forEach((group) => {
          group[this.groupItems].forEach((item) => {
            if (item[this.itemValue] === this.value) {
              this.placeholder = item[this.itemLabel]
            }
          })
        })
      }
    },
    resetList() {
      this.menuVisible = false
      this.filterValue = ''
      this.placeholder = ''
      this.visibleItems = _.cloneDeep(this.items)
      this.setActiveElement()
    },
  },
}
</script>

<style scoped>
::v-deep {
  .form-control-select:after {
    z-index: 300 !important;
  }

  .custom-select {
    z-index: auto;
  }

  .custom-select-wrap {
    position: relative;
    transition: all 0s;
    padding: 0.25rem 0 !important;
    min-width: 200px;
  }

  .custom-select-wrap.small {
    height: calc(calc(2.25rem + 4px) - .375rem);
  }

  .custom-select-wrap.active {
    border: solid 2px #7a1d8e;
  }

  .custom-select-wrap.active.large-menu {
    border: 2px solid #ddd;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .custom-select-wrap.active .custom-select-input {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .custom-select-input {
    z-index: 99;
    border: none;
    position: relative;
    width: 100%;
    height: 100% !important;
    padding: .625rem 2rem .625rem 1rem;
    border-radius: 0.25rem;
  }

  .custom-select-input:active {
    border-bottom: none;
  }

  .custom-select-input:focus {
    outline: none;
  }

  .select-menu {
    padding-top: 20px;
    display: none;
    position: absolute;
    top: 50%;
    left: -2px;
    z-index: 98;
    background-color: #fff;
    border: solid 2px #7a1d8e;
    width: calc(100% + 4px);
    border-top: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    overflow-y: auto;
    max-height: 240px;
  }

  .select-menu.large {
    top: 100%;
    border-top: solid 2px #7a1d8e;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 170%;
    left: -36%;
  }

  .item {
    padding: .625rem 1rem .625rem 1.5rem;
    white-space: nowrap;
    cursor: pointer;
  }

  .item:hover {
    background-color: #f1f1f1;
  }

  .group {
    cursor: default;
  }

  .group p {
    padding: .625rem 1rem .625rem 1rem;
    white-space: nowrap;
    margin: 0;
    opacity: 0.6;
  }

  .group:hover {
    background-color: transparent;
  }

  .visible {
    top: 40px;
    display: block;
    padding-top: 0;
    z-index: 200;
  }
}
</style>
