export default class Permissions {
  constructor(args = {}) {
    this.PP_APPLICATION = {
      VIEW_CUSTOMER_CONTACTS: false,
      DOWNLOAD_APPLICATION_CONFIRMATION: false,
      VIEW_DETAILS: false,
      REUSE_APPLICATION_DATA: false,
      VIEW_PURCHASE: false,
      DOWNLOAD_CONTRACT: false,
      VIEW_LIST: false,
      WITHDRAW_CONTRACT: false,
      ...args.PP_APPLICATION,
    }

    this.PP_APPLICATION_SALES_FLOW = {
      ACCEPT_OFFER: false,
      DOWNLOAD_APPLICATION_CONFIRMATION: false,
      DOWNLOAD_CONTRACT: false,
      PARTNERS_CONFIRMATION: false,
      CREATE_APPLICATION: false,
      CANCEL_APPLICATION: false,
      VIEW_DECISION: false,
      SIGN_APPLICATION: false,
      CANCEL_CONTRACT: false,
      UPLOAD_ATTACHMENTS: false,
      AMEND_APPLICATION: false,
      SIGN_CONTRACT: false,
      ...args.PP_APPLICATION_SALES_FLOW,
    }

    this.PP_BUSINESS_APPLICATION_SALES_FLOW = {
      REDIRECT_FOR_NEW_BUSINESS_APPLICATION: false,
      ...args.PP_BUSINESS_APPLICATION_SALES_FLOW,
    }

    this.PP_CUSTOMERS = {
      VIEW: false,
      VIEW_CONTACTS: false,
      VIEW_APPLICATIONS_LIST: false,
      ...args.PP_CUSTOMERS,
    }

    this.PP_REPORTS = {
      VIEW_LIST: false,
      VIEW_PAYOUT_REPORT: false,
      VIEW_SALES_REPORT: false,
      VIEW_SALES_REPORT_OTHER_USERS_DATA: false,
      ...args.PP_REPORTS,
    }

    this.PP_USER = {
      SHOW_ALL_SHOPS_VIEW: false,
      EDIT_PROFILE: false,
      VIEW_PROFILE: false,
      CHANGE_PASSWORD: false,
      ...args.PP_REPORTS,
    }

    this.PP_SEARCH = {
      SEARCH: false,
      ...args.PP_SEARCH,
    }

    this.PP_GENERAL_SUPPORT = {
      VIEW: false,
      ...args.PP_GENERAL_SUPPORT,
    }

    this.PP_LOGIN = {
      LOGIN: false,
      ...args.LOGIN,
    }

    this.PP_NOTIFICATIONS = {
      VIEW_NOTIFICATIONS: false,
      ...args.PP_NOTIFICATIONS,
    }
  }
}
