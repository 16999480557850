/* eslint-disable max-len */
import { booleanOrNull, parseFloatOrNull, toLowerCaseArray } from '~/utils/DataUtils'

export default class ProductParameters {
  constructor(args = {}) {
    this.partnerportalOpenable = args.partnerportalOpenable != null ? args.partnerportalOpenable : false
    this.eposOpenable = args.eposOpenable != null ? args.eposOpenable : false
    this.contractSigningOnFrontend = args.contractSigningOnFrontend != null ? args.contractSigningOnFrontend : false
    this.automaticContractSigning = args.automaticContractSigning != null ? args.automaticContractSigning : false
    this.applicationRequiredParams = args.applicationRequiredParams || []
    this.autoCreateWithGoodConditions = args.autoCreateWithGoodConditions || null
    this.salesFlowCode = args.salesFlowCode || null
    this.periodOptions = args.periodOptions || []
    this.defaultPeriod = args.defaultPeriod || null
    this.downPaymentEnabled = args.downPaymentEnabled != null ? args.downPaymentEnabled : false
    this.downPaymentCollectedBy = args.downPaymentCollectedBy != null ? args.downPaymentCollectedBy : null
    this.defaultDownPaymentPrc = parseFloatOrNull(args.defaultDownPaymentPrc)
    this.downPaymentFixedPrcLimit = parseFloatOrNull(args.downPaymentFixedPrcLimit)
    this.downPaymentFixedPrc = parseFloatOrNull(args.downPaymentFixedPrc)
    this.downPaymentFixedPrc2Limit = parseFloatOrNull(args.downPaymentFixedPrc2Limit)
    this.downPaymentFixedPrc2 = parseFloatOrNull(args.downPaymentFixedPrc2)
    this.residualAmountPrc = parseFloatOrNull(args.residualAmountPrc)
    this.defaultPaymentDay = args.defaultPaymentDay || null
    this.paymentDayOptions = args.paymentDayOptions || []
    this.defaultAmount = args.defaultAmount || null
    this.maxAmount = args.maxAmount || null
    this.minFinanceableAmount = args.minFinanceableAmount || null
    this.maxFinanceableAmount = args.maxFinanceableAmount || null
    this.minNetMonthlyIncome = args.minNetMonthlyIncome || null
    this.contractActivationRequiresPartnerApproval = args.contractActivationRequiresPartnerApproval || null
    this.allowedContractSigningMethods = toLowerCaseArray(args.allowedContractSigningMethods)
    this.allowedApplicationSigningMethods = toLowerCaseArray(args.allowedApplicationSigningMethods)
    this.showEmtaConsent = args.showEmtaConsent != null ? args.showEmtaConsent : true
    this.displayMaxLimitPp = args.displayMaxLimitPp || false
    this.automaticallySignApplication = args.automaticallySignApplication != null ? args.automaticallySignApplication : true
    this.useRiaConsentForEmta = Boolean(args.useRiaConsentForEmta)
    this.payoutToMerchant = booleanOrNull(args.payoutToMerchant)
    this.isRentalRenewal = Boolean(args.isRentalRenewal)
  }

  // FA-2351 tmp remove products with minimum required down payment and collected by merchant
  get isMerchantDownPayment() {
    return this.downPaymentEnabled
      // Not collected by Inbank
      && this.downPaymentCollectedBy !== 1
  }

  get isRequiredMerchantDownPayment() {
    return this.isMerchantDownPayment
      // Minimum down payment required
      && (!!this.downPaymentFixedPrc || !!this.downPaymentFixedPrc2)
  }
  // END OF FA-2351
}
