import PhoneNumber from 'awesome-phonenumber'

export function getRegionCode(phoneNumber) {
  if (phoneNumber) {
    const number = new PhoneNumber(phoneNumber)
    return number.getRegionCode()
  }
  return null
}

export function removeCountryCode(phoneNumber) {
  if (phoneNumber) {
    const number = new PhoneNumber(phoneNumber)
    return number.getRegionCode()
      ? number.getNumber('significant')
      : phoneNumber
  }
  return null
}

export function getWithCountryCode(phoneNumber, regionCode) {
  if (phoneNumber && regionCode) {
    const number = new PhoneNumber(phoneNumber, regionCode)
    return number.getNumber()
  }
  return null
}

export function getCountryCodeForRegionCode(regionCode) {
  return PhoneNumber.getCountryCodeForRegionCode(regionCode)
}

export function classifyPhoneNumber(number, $localisation) {
  const rawNumber = removeCountryCode(number)
  if (rawNumber) {
    if ($localisation.mobilePrefixes().some(prefix => rawNumber.startsWith(prefix))) {
      return 'mobile'
    }
    if ($localisation.landlinePrefixes().some(prefix => rawNumber.startsWith(prefix))) {
      return 'landline'
    }
  }
  return null
}
