import _ from 'lodash'
import { AUTH_STATUS } from '~/constants/auth'
import { API_ERROR } from '~/constants/errors'

import { EVENT } from '~/constants/prometheus'
import { Context } from '~/types/common'

export function useAuthPromEvents(context: Context) {
  function promBroadcastSuccess(authMethod: string) {
    context.root.$prometheus.broadcast(EVENT.LOGIN.NAME, {
      method: authMethod,
      result: EVENT.LOGIN.RESULTS.SUCCESS,
    })
  }

  function promBroadcastCancel(authMethod: string) {
    context.root.$prometheus.broadcast(EVENT.LOGIN.NAME, {
      method: authMethod,
      result: EVENT.LOGIN.RESULTS.CLIENT_CANCEL,
    })
  }

  function promBroadcastUnknownError(error: any, authMethod: string) {
    context.root.$prometheus.broadcast(EVENT.LOGIN.NAME, {
      method: authMethod,
      result: context.root.$prometheus.getResultType(error),
    })
  }

  function promBroadcastError(
    error: any,
    authMethod: string,
    status: string,
  ) {
    let result = EVENT.LOGIN.RESULTS.SERVER_ERROR
    if (error) {
      const message = _.get(error, 'data.details.message[0]')
      if ([
        API_ERROR.VERIFICATION_FAILED_USER_CANCELLED_THE_OPERATION,
        API_ERROR.VERIFICATION_FAILED_EXPIRED_TRANSACTION,
        API_ERROR.VERIFICATION_FAILED,
      ].includes(message)) {
        result = EVENT.LOGIN.RESULTS.CLIENT_CANCEL
      }
    }
    if (status === AUTH_STATUS.CANCELED) {
      result = EVENT.LOGIN.RESULTS.CLIENT_CANCEL
    }

    context.root.$prometheus.broadcast(EVENT.LOGIN.NAME, {
      method: authMethod,
      result,
    })
  }

  return {
    promBroadcastSuccess,
    promBroadcastCancel,
    promBroadcastUnknownError,
    promBroadcastError,
  }
}

export default {}
