/* eslint-disable class-methods-use-this */
import Shop from '~/models/Shop'
import Organisation from '~/models/Organisation'
import Permissions from '~/models/Permissions'
import * as StorageUtils from '~/utils/StorageUtils'

export const TMP_ORG_KEY = 'tmp_org'

export default class User {
  constructor({
    organizations,
    viewingAllShops = false,
    selectedShop,
    tempSelectedShop,
    user = {},
    tokenExpire,
  } = {}, { rawData = true } = {}) {
    this.organizations = organizations || []

    this.selectedShop = new Shop(selectedShop)
    this.tempSelectedShop = tempSelectedShop ? new Shop(tempSelectedShop) : null

    this.viewingAllShops = viewingAllShops

    this.user = {
      email: user.email || null,
      firstName: user.firstName || null,
      lastName: user.lastName || null,
      fullName: user.fullName || null,
      username: user.username || null,
      uuid: user.uuid || null,
      PERMISSIONS: new Permissions(user.PERMISSIONS || {}),
    }

    this.tokenExpire = tokenExpire || null

    if (rawData) {
      this.organizations = this.organizations.map((org) => new Organisation(org))
    }
  }

  get tmpOrgUuid() {
    return StorageUtils.getItem(TMP_ORG_KEY, StorageUtils.STORAGE_TYPE.SESSION)
  }

  set tmpOrgUuid(orgUuid) {
    if (!orgUuid) {
      StorageUtils.removeItem(TMP_ORG_KEY, StorageUtils.STORAGE_TYPE.SESSION)
    } else {
      StorageUtils.setItem(TMP_ORG_KEY, orgUuid, StorageUtils.STORAGE_TYPE.SESSION)
    }
  }

  getShopByUuid(uuid) {
    let shops = []
    this.organizations.forEach((organization) => {
      shops = [...shops, ...organization.shops]
    })
    return shops.find((shop) => shop.uuid === uuid)
  }
}
