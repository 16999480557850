<template>
  <loader v-if="initializing" class="loader-full-height" />
  <div v-else>
    <session-updater />
    <session-expired-modal />

    <b-navbar
      toggleable="sm"
      variant="light"
      class="global-nav navbar-main flex-nowrap justify-content-between px-2 px-md-3"
    >
      <b-navbar-brand :to="localePath(homePage)" class="global-nav__brand p-0">
        <img src="~/static/images/logo/inbank-partner-logo.svg" width="" height="36" alt="Inbank">
      </b-navbar-brand>

      <b-navbar-nav class="navbar-nav flex-row main-nav justify-content-end w-100 minw-0">
        <b-dropdown
          v-if="shops.length > 1 || organizations.length > 1"
          variant="link"
          class="main-nav"
          toggle-class="global-nav__dd px-2 regular text-gray-medium"
          right
        >
          <template slot="button-content">
            <span class="icons icon--nav icon-shopping-basket text-gray px-0 px-md-2" />
            <span class="d-none d-lg-inline">{{ selectedShop.name || t('store.labels.allShops') }}</span>
          </template>
          <template v-if="shops.length < 6 && organizations.length < 2">
            <b-dropdown-item
              v-for="(shop, index) in shops"
              :key="index"
              :class="{ 'global-nav__dd-item--active': shop.uuid === selectedShop.uuid }"
              class="global-nav__dd-item"
              @click="selectShop(shop)"
            >
              {{ shop.name }}
            </b-dropdown-item>
            <b-dropdown-item
              :class="{ 'global-nav__dd-item--active': tempSelectedShop }"
              @click="deselectShop()"
            >
              {{ t('store.labels.allShops') }}
            </b-dropdown-item>
          </template>

          <b-dropdown-item
            v-if="shops.length > 5 || organizations.length > 1"
            :to="localePath('store')"
          >
            {{ t('links.switchShop') }}
          </b-dropdown-item>
        </b-dropdown>

        <inb-button
          v-if="shops.length == 1 && organizations.length == 1"
          variant="link"
          class="main-nav position-static text-truncate"
        >
          <span class="icons icon--nav icon-shopping-basket text-gray px-0 px-sm-2" />
          <span>{{ selectedShop.name }}</span>
        </inb-button>

        <b-dropdown
          v-if="$i18n.locales.length > 1"
          variant="link"
          class="main-nav"
          toggle-class="global-nav__dd px-2 regular text-gray-medium"
          menu-class="language-select-dropdown"
          right
        >
          <template slot="button-content">
            <span class="icons icon--nav icon-user-circle text-gray px-0 px-md-2" />
            <span class="d-none d-lg-inline data-hj-suppress" data-hj-suppress>{{ safeUser.user.fullName }}</span>
          </template>

          <b-dropdown-item class="py-2 px-3" @click="onChangePassword">
            {{ t('links.changePassword') }}
          </b-dropdown-item>

          <b-dropdown-divider />

          <ul class="list-inline text-center py-2 px-3">
            <client-only v-for="(locale, index) in $i18n.locales" :key="index">
              <li
                :class="{ 'global-nav__dd-item--disabled': $i18n.locale == locale.code }"
                class="small text-uppercase"
              >
                <a href="javascript:void(0)" @click="switchLanguage(locale.code)">
                  {{ locale.code }}
                </a>
              </li>
            </client-only>
          </ul>
        </b-dropdown>

        <b-dropdown
          v-else-if="$i18n.locales.length <= 1"
          variant="link"
          class="main-nav"
          toggle-class="global-nav__dd px-2 regular text-gray-medium"
          right
        >
          <template slot="button-content">
            <span class="icons icon--nav icon-user-circle text-gray px-0 px-md-2" />
            <span class="d-none d-lg-inline data-hj-suppress" data-hj-suppress>{{ safeUser.user.fullName }}</span>
          </template>

          <b-dropdown-item class="py-2 px-3" @click="onChangePassword">
            {{ t('links.changePassword') }}
          </b-dropdown-item>
        </b-dropdown>

        <inb-button
          variant="link"
          class="main-nav pl-3 pr-1 regular text-gray-medium"
          @click="logout()"
        >
          <span class="d-none d-lg-inline">{{ t('links.logout') }}</span>
          <span class="icons icon--nav icon-sign-out text-gray px-0 pl-md-2" />
        </inb-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="container-fluid">
      <div class="row">
        <client-only>
          <app-sidebar />
        </client-only>
        <div class="col d-flex flex-column minw-0">
          <nuxt />
          <app-footer />
        </div>
      </div>
    </div>
    <cookie-consents-modal />
  </div>
</template>

<script>
import { InbButton } from 'inbank-frontend-commons/components/base-components'
import CookieConsentsModal from '~/components/modals/CookieConsentsModal.vue'
import AppFooter from '~/components/common/AppFooter.vue'

import User from '~/models/User'
import Shop from '~/models/Shop'
import UserService from '~/services/api/UserService'

import { useAuthLayout } from '~/use/authentication/Authentication'
import { useSessionUpdater } from '~/use/SessionUpdater'
import { useNotificationFetchLifeCycle } from '~/use/Notifications'
import { useLanguage } from '~/use/Language'

import { COUNTRY_CODE } from '~/constants'
import { useNavigation } from '~/use/navigation/Navigation'

export default {
  components: { InbButton, CookieConsentsModal, AppFooter },
  setup(props, context) {
    const { switchLanguage } = useLanguage(context)
    const { homePage } = useNavigation(context)
    useNotificationFetchLifeCycle(context)
    return {
      ...useAuthLayout(context),
      ...useSessionUpdater(context),
      switchLanguage,
      homePage,
    }
  },
  data() {
    return {
      shops: [],
      organizations: [],
      selectedShop: new Shop(),
      initializing: true,
    }
  },
  computed: {
    tempSelectedShop() {
      return this.safeUser.tempSelectedShop
    },
    safeUser() {
      return this.user || new User()
    },
  },
  async mounted() {
    this.selectedShop = this.$store.state.activeShop || new Shop()

    try {
      const user = await UserService.getCurrentUser(this.$store)
      this.initSessionUpdater(user.tokenExpire)

      await this.getShops(user)

      this.initializing = false
    } catch (error) {
      this.logout()
    }
  },
  methods: {
    async getShops(user) {
      this.selectedShop = user.selectedShop
      this.organizations = user.organizations
      if (!this.selectedShop || !Object.keys(this.selectedShop).length) {
        this.selectedShop = user.organizations[0].shops[0]
      }
      this.shops = user.organizations[0].shops
    },
    async selectShop(shop) {
      await UserService.selectShop(shop.uuid)
      this.$store.dispatch('setActiveShop', this.selectedShop)
      this.windowReload()
    },
    async deselectShop() {
      await UserService.deselectShop()
      this.$store.dispatch('setActiveShop', null)
      this.windowReload()
    },
    windowReload() {
      // Required for mocking in tests
      window.location.reload()
    },
    onChangePassword() {
      this.$store.dispatch('setPwChangeReturnPath', this.$route.fullPath)
      const path = this.localePath('password-change')
      this.$router.push(path)
    },
  },
}
</script>
