function getVisibilityProperties() {
  let hidden
  let visibilityChange
  if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }
  return {
    hidden,
    visibilityChange,
  }
}

export function isDocumentHidden() {
  const { hidden } = getVisibilityProperties()
  return document[hidden]
}

export default {}
