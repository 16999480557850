import { INTREST_TYPE } from '~/constants/contract'
import ApplicationDetail from '~/models/ApplicationDetail'
import { Context } from '~/types/common'

const BASE_RATE_PERCISION = 4

export function useFloatingInterestRate(context: Context) {
  function composeFloatingInterestRateCalculationText(
    baseRateType: string,
    margin: string,
    baseRateValue: string,
  ) {
    return context.root.t(`data.application.floatingInterestRateCalculation.${baseRateType}`, {
      margin: context.root.$options.filters!.percentage(margin),
      baseRateValue: context.root.$options.filters!.percentage(baseRateValue, BASE_RATE_PERCISION),
    })
  }

  function getFloatingInterestRateTotalValue(
    margin: string,
    baseRateValue: string,
  ) {
    return parseFloat(baseRateValue) + parseFloat(margin)
  }

  function getFloatingInterestRateValue(application: ApplicationDetail) {
    const { interestType, floatingInterestRate } = application.paymentSchedule!
    return interestType === INTREST_TYPE.FLOATING && floatingInterestRate
      ? getFloatingInterestRateTotalValue(
        floatingInterestRate.baseRateValue,
        floatingInterestRate.margin,
      )
      : 0
  }

  function getFloatingCampaignInterestRateValue(application: ApplicationDetail) {
    const { campaignInterestType, floatingInterestRate } = application.paymentSchedule!
    return campaignInterestType === INTREST_TYPE.FLOATING
      ? getFloatingInterestRateTotalValue(
        floatingInterestRate!.baseRateValue,
        floatingInterestRate!.campaignMargin,
      )
      : 0
  }

  function getFloatingInterestRateCalculationText(application: ApplicationDetail) {
    const { floatingInterestRate } = application.paymentSchedule!
    return floatingInterestRate
      ? composeFloatingInterestRateCalculationText(
        floatingInterestRate.baseRateType,
        floatingInterestRate.margin,
        floatingInterestRate.baseRateValue,
      )
      : null
  }

  function getFloatingCampaignInterestRateCalculationText(application: ApplicationDetail) {
    const { floatingInterestRate } = application.paymentSchedule!
    return floatingInterestRate
      ? composeFloatingInterestRateCalculationText(
        floatingInterestRate.baseRateType,
        floatingInterestRate.campaignMargin,
        floatingInterestRate.baseRateValue,
      )
      : null
  }

  function getCampaignDurationText(application: ApplicationDetail) {
    const {
      campaignInterestType,
      campaignInterestPeriodStartDate,
      campaignInterestPeriodEndDate,
    } = application.paymentSchedule!
    const { date } = context.root.$options.filters!
    return campaignInterestType
      ? `${date(campaignInterestPeriodStartDate)} - ${date(campaignInterestPeriodEndDate)}`
      : null
  }

  function getNextBaseRateChangeDate(application: ApplicationDetail) {
    const { floatingInterestRate } = application.paymentSchedule!
    const { date } = context.root.$options.filters!

    return floatingInterestRate?.nextBaseRateChangeDate
      ? `${date(floatingInterestRate.nextBaseRateChangeDate)}`
      : null
  }

  return {
    getFloatingInterestRateValue,
    getFloatingCampaignInterestRateValue,
    getFloatingInterestRateCalculationText,
    getFloatingCampaignInterestRateCalculationText,
    getCampaignDurationText,
    getNextBaseRateChangeDate,
  }
}

export default {}
