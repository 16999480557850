import Shop from '~/models/Shop'

export default class Organisation {
  constructor(args = {}, { rawData = true } = {}) {
    this.uuid = args.uuid || null
    this.name = args.name || null
    this.publicName = args.publicName || null
    this.shops = args.shops || []

    if (rawData) {
      this.shops = this.shops.map((shop) => new Shop(shop))
    }
  }
}
