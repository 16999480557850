import api from '~/services/api'

import { HTTP_STATUS_CODE } from '~/constants'

function successInterceptor(app) {
  return (response) => {
    return response
  }
}

function failInterceptor(app) {
  return (error) => {
    const { response } = error
    const { auth: authStore } = app.store.state

    if (response && response.config && response.config.baseURL === app.$env.apiBaseURL) {
      const code = parseInt(response.status, 10)
      if (code === HTTP_STATUS_CODE.UNAUTHORIZED && authStore.auth.loggedIn) {
        app.store.dispatch('auth/clearAuth')
        window.location.replace('/')
      }
    } else {
      throw error
    }
    return Promise.reject(error.response)
  }
}

export default function ({ app }) {
  if (process.client) {
    api.interceptors.response.use(
      successInterceptor(app),
      failInterceptor(app),
    )
  }
}
