import _ from 'lodash'
import moment from 'moment'

// TODO: This function will be deprecated. Move this logic to the ApiError model.
export function getErrors(context, errors, errorResponse, translate = true) {
  const message = _.get(errorResponse, 'data.details.message')
  let newErrors = [context.t('error.unknownError')]
  if (message) {
    if (message instanceof Array) {
      newErrors = message.map((error) => (translate ? context.t(error) : error))
    } else if (typeof message === 'string') {
      newErrors = [translate ? context.t(message) : message]
    }
  }
  return [
    ...errors,
    ...newErrors,
  ]
}

export function parseCeilInt(value) {
  const float = parseFloat(value)
  if (Number.isNaN(float)) {
    return null
  }
  return Math.ceil(float)
}

export function parseFloatOrNull(value) {
  const float = parseFloat(value)
  return Number.isNaN(float) ? null : float
}

export function booleanOrNull(value) {
  return [true, false].includes(value) ? value : null
}

export function toLowerCaseArray(value) {
  return value ? value.map((item) => item.toLowerCase()) : []
}

// Transform to SuperAPI accepted format
export function formatDate(date) {
  return moment(date).format('YYYY-MM-DD')
}

export function getNextMonthDay(day) {
  return `${day}.${moment().add(1, 'months').format('MM')}`
}

export function formatCurrencyAmount(rawValue) {
  let value = rawValue

  const decimal = '.'
  const thousands = ' '
  const decimalCount = 2
  const numberSectionLenght = 3

  if (typeof value === 'string') {
    value = value.replace(',', '.')
  }

  const negativeSign = value < 0 ? '-' : ''
  const i = parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalCount), 10).toString()
  const j = (i.length > numberSectionLenght) ? i.length % numberSectionLenght : 0

  return `${negativeSign}${(j ? i.substr(0, j) + thousands : '')}${i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}${(decimalCount ? decimal + Math.abs(value - i).toFixed(decimalCount).slice(decimalCount) : '')}`
}

export function isConsentActive(activeConsents, consentType) {
  if (activeConsents.length === 0 || (activeConsents.consents !== undefined && activeConsents.consents.length === 0)) {
    return false
  }
  if (activeConsents.length > 0) {
    return activeConsents.find((consent) => consent.type === consentType && consent.value) != null
  }
  if(activeConsents?.consents.length > 0) {
    return activeConsents.consents.find((consent) => consent.type === consentType && consent.value) != null
  }
  return false
}
