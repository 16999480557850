import * as PL_FORMATS from 'inbank-frontend-commons/localisation/validators/PL/formats'
import * as PL_VALIDATIONS from 'inbank-frontend-commons/localisation/validators/PL/constants'
import PL_VALIDATORS from 'inbank-frontend-commons/localisation/validators/PL'

import * as EE_FORMATS from 'inbank-frontend-commons/localisation/validators/EE/formats'
import * as EE_VALIDATIONS from 'inbank-frontend-commons/localisation/validators/EE/constants'
import EE_VALIDATORS from 'inbank-frontend-commons/localisation/validators/EE'

import * as LV_FORMATS from 'inbank-frontend-commons/localisation/validators/LV/formats'
import * as LV_VALIDATIONS from 'inbank-frontend-commons/localisation/validators/LV/constants'
import LV_VALIDATORS from 'inbank-frontend-commons/localisation/validators/LV'

import * as CZ_FORMATS from 'inbank-frontend-commons/localisation/validators/CZ/formats'
import * as CZ_VALIDATIONS from 'inbank-frontend-commons/localisation/validators/CZ/constants'
import CZ_VALIDATORS from 'inbank-frontend-commons/localisation/validators/CZ'

import * as LT_FORMATS from 'inbank-frontend-commons/localisation/validators/LT/formats'
import * as LT_VALIDATIONS from 'inbank-frontend-commons/localisation/validators/LT/constants'
import LT_VALIDATORS from 'inbank-frontend-commons/localisation/validators/LT'

import { LANGUAGE_CODE, COUNTRY_CODE, ID_DOCUMENT_TYPE } from '~/constants'

export default {
  PL: {
    formats: PL_FORMATS,
    validations: PL_VALIDATIONS,
    validators: PL_VALIDATORS,
    languageCode: LANGUAGE_CODE.PL,
    communicationLanguages: [
      LANGUAGE_CODE.PL,
      LANGUAGE_CODE.EN,
    ],
    residencies: [
      COUNTRY_CODE.PL,
    ],
    idDocumentTypes: [],
    features: {
      production: {
        automaticASUpload: true,
      },
      demo: {
        automaticASUpload: true,
      },
      staging: {
        automaticASUpload: true,
        dashboard: true,
      },
      local: {
        automaticASUpload: true,
        dashboard: true,
      },
    },
  },
  EE: {
    formats: EE_FORMATS,
    validations: EE_VALIDATIONS,
    validators: EE_VALIDATORS,
    languageCode: LANGUAGE_CODE.ET,
    communicationLanguages: [
      LANGUAGE_CODE.ET,
      LANGUAGE_CODE.EN,
      LANGUAGE_CODE.RU,
    ],
    residencies: [
      COUNTRY_CODE.EE,
      COUNTRY_CODE.LV,
      COUNTRY_CODE.LT,
      COUNTRY_CODE.FI,
      COUNTRY_CODE.SE,
      COUNTRY_CODE.NO,
    ],
    idDocumentTypes: [
      ID_DOCUMENT_TYPE.ID_CARD,
      ID_DOCUMENT_TYPE.PASSPORT,
      ID_DOCUMENT_TYPE.FOREIGNER_PASSPORT,
      ID_DOCUMENT_TYPE.RESIDENCE_PERMIT,
    ],
    features: {
      production: {
        automaticASUpload: true,
      },
      demo: {
        automaticASUpload: true,
      },
      staging: {
        automaticASUpload: true,
        dashboard: true,
      },
      local: {
        automaticASUpload: true,
        dashboard: true,
      },
    },
  },
  LV: {
    formats: LV_FORMATS,
    validations: LV_VALIDATIONS,
    validators: LV_VALIDATORS,
    languageCode: LANGUAGE_CODE.LV,
    communicationLanguages: [
      LANGUAGE_CODE.LV,
      LANGUAGE_CODE.EN,
      LANGUAGE_CODE.RU,
    ],
    residencies: [
      COUNTRY_CODE.EE,
      COUNTRY_CODE.LV,
      COUNTRY_CODE.LT,
      COUNTRY_CODE.FI,
      COUNTRY_CODE.SE,
      COUNTRY_CODE.NO,
    ],
    idDocumentTypes: [
      ID_DOCUMENT_TYPE.ID_CARD,
      ID_DOCUMENT_TYPE.PASSPORT,
      ID_DOCUMENT_TYPE.FOREIGNER_PASSPORT,
      ID_DOCUMENT_TYPE.RESIDENCE_PERMIT,
    ],
    address: {
      postalPrefix: true,
    },
    features: {
      production: {
        automaticASUpload: true,
      },
      demo: {
        automaticASUpload: true,
      },
      staging: {
        automaticASUpload: true,
        dashboard: true,
      },
      local: {
        automaticASUpload: true,
        dashboard: true,
      },
    },
  },
  CZ: {
    formats: CZ_FORMATS,
    validations: CZ_VALIDATIONS,
    validators: CZ_VALIDATORS,
    languageCode: LANGUAGE_CODE.CS,
    communicationLanguages: [
      LANGUAGE_CODE.CS,
    ],
    idDocumentTypes: [
      ID_DOCUMENT_TYPE.ID_CARD,
      ID_DOCUMENT_TYPE.RESIDENCE_PERMIT,
    ],
    features: {
      production: {
        automaticASUpload: true,
      },
      demo: {
        automaticASUpload: true,
      },
      staging: {
        automaticASUpload: true,
        dashboard: true,
      },
      local: {
        automaticASUpload: true,
        dashboard: true,
      },
    },
  },
  LT: {
    formats: LT_FORMATS,
    validations: LT_VALIDATIONS,
    validators: LT_VALIDATORS,
    languageCode: LANGUAGE_CODE.LT,
    communicationLanguages: [
      LANGUAGE_CODE.LT,
      LANGUAGE_CODE.EN,
    ],
    residencies: [
      COUNTRY_CODE.EE,
      COUNTRY_CODE.LV,
      COUNTRY_CODE.LT,
      COUNTRY_CODE.FI,
      COUNTRY_CODE.SE,
      COUNTRY_CODE.NO,
    ],
    idDocumentTypes: [
      ID_DOCUMENT_TYPE.ID_CARD,
      ID_DOCUMENT_TYPE.PASSPORT,
      ID_DOCUMENT_TYPE.RESIDENCE_PERMIT,
    ],
    features: {
      production: {
        automaticASUpload: true,
      },
      demo: {
        automaticASUpload: true,
      },
      staging: {
        automaticASUpload: true,
        dashboard: true,
      },
      local: {
        automaticASUpload: true,
        dashboard: true,
      },
    },
  },
}
