import { computed } from '@nuxtjs/composition-api'

import { Context } from '~/types/common'
import { usePermissions } from '~/use/Permissions'

export function useReportingPermissions(context: Context) {
  const { PERMISSIONS } = usePermissions(context)

  const hasSalesReportsPermission = computed(() => (
    PERMISSIONS.value.PP_REPORTS.VIEW_SALES_REPORT
    || PERMISSIONS.value.PP_REPORTS.VIEW_SALES_REPORT_OTHER_USERS_DATA
  ))

  const hasPayoutReportsPermission = computed(() => (
    PERMISSIONS.value.PP_REPORTS.VIEW_PAYOUT_REPORT
  ))

  const hasViewReportsPermission = computed(() => (
    PERMISSIONS.value.PP_REPORTS.VIEW_SALES_REPORT
    || PERMISSIONS.value.PP_REPORTS.VIEW_SALES_REPORT_OTHER_USERS_DATA
    || PERMISSIONS.value.PP_REPORTS.VIEW_PAYOUT_REPORT
  ))

  return {
    hasViewReportsPermission,
    hasSalesReportsPermission,
    hasPayoutReportsPermission,
  }
}

export default {}
