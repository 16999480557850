/* eslint-disable object-curly-newline */
/* eslint-disable class-methods-use-this */
import IBAN from 'iban'
import { isNumber } from 'lodash'

import { LANGUAGE_CODE, COUNTRY_CODE } from '~/constants'

import { classifyPhoneNumber } from '~/utils/PhoneUtils'

export default class Validator {
  constructor(args = {}) {
    this.$localisation = args.$localisation
  }

  identitycode(value) {
    return this.$localisation.validate('identityCode', value)
  }

  phone(value) {
    return this.$localisation.validate('phone', value)
  }

  phoneNumberType(value, expected) {
    return classifyPhoneNumber(value, this.$localisation) === expected
  }

  zipcode(value) {
    return this.$localisation.validate('zipCode', value)
  }

  personName(value) {
    return this.$localisation.validate('personName', value)
  }

  placeName(value) {
    return this.$localisation.validate('placeName', value)
  }

  streetName(value) {
    return this.$localisation.validate('streetName', value)
  }

  houseNumber(value) {
    return this.$localisation.validate('houseNumber', value)
  }

  email(value) {
    if (value == null) {
      return false
    }
    return /^(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)$/.test(value)
  }

  integer(value) {
    if (value == null) {
      return false
    }
    return /^\+?\d*$/.test(value)
  }

  documentNumber(value, { documentType } = {}) {
    return this.$localisation.validate('documentNumber', value, { documentType })
  }

  date(value) {
    if (value == null) {
      return false
    }
    return !!value
  }

  isFilled(value) {
    return !!value
  }

  iban(value) {
    return this.$localisation.validate('iban', value)
  }

  countryCode(value) {
    if (value == null) {
      return false
    }
    return Object.keys(COUNTRY_CODE).includes(value)
  }

  languageCode(value) {
    if (value == null) {
      return false
    }
    return Object.values(LANGUAGE_CODE).includes(value)
  }

  partnerCode(value) {
    if (!value) {
      value = ''
    }
    const codeLength = 8
    return value.length >= codeLength && /^[0-9]+$/.test(value)
  }

  inRange({ value, min, max, strict = false }) {
    const num = +value
    if (!num || !isNumber(num)) {
      return false
    }
    return strict
      ? num > min && num < max
      : num >= min && num <= max
  }

  isOfLength(value, length) {
    if (!value && length === 0) {
      return length === 0
    }
    return value.length === length
  }
}
