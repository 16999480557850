import PaymentScheduleItem from '~/models/PaymentScheduleItem'
import FloatingInterestRate from '~/models/FloatingInterestRate'

export default class PaymentSchedule {
  constructor(args = {}, { rawInput = true } = {}) {
    this.adminFeeAmountMonthly = args.adminFeeAmountMonthly || null
    this.adminFeeAmountTotal = args.adminFeeAmountTotal || null
    this.amount = args.amount || null
    this.contractFeeAmountTotal = args.contractFeeAmountTotal || null
    this.contractFeeType = args.contractFeeType || null
    this.contractFeePrc = args.contractFeePrc || null
    this.creditApplicationUuid = args.creditApplicationUuid || null
    this.creditCostAmountTotal = args.creditCostAmountTotal || null
    this.creditCostRateAnnual = args.creditCostRateAnnual || null
    this.downPaymentAmountPrc = args.downPaymentAmountPrc || null
    this.downPaymentAmountTotal = args.downPaymentAmountTotal || null
    this.downPaymentMainPart = args.downPaymentMainPart || null
    this.firstPaymentAmount = args.firstPaymentAmount || null
    this.firstPaymentDate = args.firstPaymentDate || null
    this.insuranceFeeAmountMonthly = args.insuranceFeeAmountMonthly || null
    this.insuranceFeeAmountTotal = args.insuranceFeeAmountTotal || null
    this.interestAmountTotal = args.interestAmountTotal || null
    this.interestBearingAmount = args.interestBearingAmount || null
    this.interestRateAnnual = args.interestRateAnnual || null
    this.lastPaymentAmount = args.lastPaymentAmount || null
    this.lastPaymentDate = args.lastPaymentDate || null
    this.netCreditAmount = args.netCreditAmount || null
    this.paymentAmountMonthly = args.paymentAmountMonthly || null
    this.paymentDay = args.paymentDay || null
    this.paymentScheduleItems = args.paymentScheduleItems || []
    this.period = args.period || null
    this.repaymentsAmountTotal = args.repaymentsAmountTotal || null
    this.residualAmountPrc = args.residualAmountPrc || null
    this.residualAmountTotal = args.residualAmountTotal || null
    this.responseLevel = args.responseLevel || null
    this.campaignInterestPeriodEndDate = args.campaignInterestPeriodEndDate || null
    this.campaignInterestPeriodStartDate = args.campaignInterestPeriodStartDate || null
    this.campaignInterestRate = args.campaignInterestRate || null
    this.interestType = args.interestType || null
    this.campaignInterestType = args.campaignInterestType || null
    this.floatingInterestRate = args.floatingInterestRate
      ? new FloatingInterestRate(args.floatingInterestRate)
      : null

    if (rawInput) {
      this.paymentScheduleItems = this.paymentScheduleItems
        .map((item) => new PaymentScheduleItem(item))
    }
  }
}
