/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

export const STORAGE_KEY = {
  FILTER_OPTIONS: 'sales_report_filter_options',
  FILTER_SELECTION: 'sales_report_filter_selection',
  RESULT: 'sales_report_result',
}

export const MUTATIONS = {
  SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
  SET_FILTER_SELECTION: 'SET_FILTER_SELECTION',
  SET_RESULT: 'SET_RESULT',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
}

export const state = () => ({
  filterOptions: null,
  filterSelection: null,
})

export const mutations = {
  [MUTATIONS.SET_FILTER_OPTIONS](state, filterOptions) {
    state.filterOptions = filterOptions
  },
  [MUTATIONS.SET_FILTER_SELECTION](state, filterSelection) {
    state.filterSelection = filterSelection
  },
}

export const actions = {
  setFilterOptions({ commit }, filterOptions) {
    commit(MUTATIONS.SET_FILTER_OPTIONS, filterOptions)
  },
  setFilterSelection({ commit }, filterSelection) {
    commit(MUTATIONS.SET_FILTER_SELECTION, filterSelection)
  },
}
