import ApiModel from '~/models/ApiModel'

export default class Address extends ApiModel {
  constructor(args = {}) {
    super(args)
    this.type = args.type || null
    this.country = args.country || null
    this.county = args.county || null
    this.parish = args.parish || null
    this.source = args.source || null
    this.township = args.township || null
    this.street = args.street || null
    this.city = args.city || null
    this.house = args.house || null
    this.houseNumberExtension = args.houseNumberExtension || null
    this.apartment = args.apartment || null
    this.zipCode = args.zipCode || null
    this.provider = args.provider || null
    this.providerId = args.providerId || null
    this.primary = args.primary || null
    this.addressId = args.addressId || null
  }

  setDataFromLocalisation(localisation) {
    this.country = localisation.isoCode()
  }

  getApiData(options) {
    const data = super.getApiData(options)
    if (data.zipCode) {
      data.zipCode = data.zipCode.replace(/-/gi, '')
    }
    delete data.provider
    return data
  }
}
