import PaymentScheduleItem from '~/models/PaymentScheduleItem'
import FloatingInterestRate from '~/models/FloatingInterestRate'

export default class Calculation {
  constructor(args = {}, { rawInput = true } = {}) {
    this.productCode = args.productCode || null
    this.amount = args.amount || null
    this.period = args.period || null
    this.downPaymentAmount = args.downPaymentAmount || null
    this.paymentDay = args.paymentDay || null
    this.responseLevel = args.responseLevel || null
    this.currency = args.currency || null
    this.paymentAmountMonthly = args.paymentAmountMonthly || null
    this.interestRateAnnual = args.interestRateAnnual || null
    this.creditCostRateAnnual = args.creditCostRateAnnual || null
    this.totalCost = args.totalCost || null
    this.totalCostOfCredit = args.totalCostOfCredit || null
    this.assetCategory = args.assetCategory || null
    this.residualAmountPrc = args.residualAmountPrc || null
    this.residualAmountTotal = args.residualAmountTotal || null
    this.advanced = {
      interestAmountTotal: null,
      contractFeeAmountTotal: null,
      adminFeeAmountMonthly: null,
      adminFeeAmountTotal: null,
      insuranceFeeAmountTotal: null,
      netCreditAmount: null,
      interestBearingAmount: null,
      repaymentsAmountTotal: null,
      creditCostAmountTotal: null,
      firstPaymentDate: null,
      firstPaymentAmount: null,
      lastPaymentDate: null,
      lastPaymentAmount: null,
      campaignInterestPeriodEndDate: args.campaignInterestPeriodEndDate || null,
      campaignInterestPeriodStartDate: args.campaignInterestPeriodStartDate || null,
      campaignInterestRate: args.campaignInterestRate || null,
      interestType: args.interestType || null,
      floatingInterestRate: new FloatingInterestRate(args),
      ...args.advanced,
    }
    this.paymentSchedule = args.paymentSchedule || []

    if (rawInput) {
      this.paymentSchedule = this.paymentSchedule
        .map((item) => new PaymentScheduleItem(item))
    }
  }
}
