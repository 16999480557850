import CookieConsents from '~/models/CookieConsents'

import {
  COOKIE_CONSENT_TYPE,
} from '~/constants/cookieConsent'

export function useMixpanel(context: any) {
  const {
    $env,
    $mixpanel,
  } = context

  const token = $env?.mixpanel?.MIXPANEL_TOKEN
  const country = $env?.countryIsoCode
  const customerConsents = new CookieConsents()

  function toSnakeCase<T extends Record<string, any>>(obj: T): T {
    if (typeof obj !== 'object' || obj === null) {
      return obj
    }
    if (Array.isArray(obj)) {
      return obj.map((item: any) => toSnakeCase(item)) as any as T
    }
    const convertedObj: Partial<Record<keyof T, T[keyof T]>> = {}
    Object.keys(obj).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const convertedKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
        convertedObj[convertedKey as keyof T] = toSnakeCase(obj[key])
      }
    })
    convertedObj['application_country' as keyof T] = country
    return convertedObj as T
  }

  function trackMixpanelData(metricName: string, metricParams: any = {}) {
    if (!customerConsents.consentGiven(COOKIE_CONSENT_TYPE.ANALYTICAL)) return
    if (!token) return
    const convertedParams = toSnakeCase(metricParams)
    $mixpanel?.track(metricName, convertedParams)
  }

  async function setMixpanelUser(trackingUuid: string) {
    if (!token) return
    if (!trackingUuid) return
    $mixpanel?.alias(trackingUuid)
  }

  async function identifyMixpanelUser(trackingUuid: string) {
    if (!token) return
    if (!trackingUuid) return
    $mixpanel?.identify(trackingUuid)
  }

  return {
    trackMixpanelData,
    setMixpanelUser,
    identifyMixpanelUser,
  }
}

export default useMixpanel
