import API from '~/services/api'
import Notification from '~/models/Notification'

export default {
  async getNotifications(shopUuid) {
    const response = await API.get(`/marketing/shops/${shopUuid}/notifications`)
    return response.data.notifications.map((notification) => new Notification(notification))
  },

  async markNotifications(shopUuid, payload) {
    const response = await API.post(`/marketing/shops/${shopUuid}/notifications/read`, payload)
    return response.data
  },
}
