/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
export const state = () => ({
  salesFlowCode: null,
})

export const mutations = {
  setSalesFlowCode(state, flowCode) {
    state.salesFlowCode = flowCode
  },
}

export const actions = {
  setSalesFlowCode({ commit }, flowCode) {
    commit('setSalesFlowCode', flowCode)
  },
}
