<template>
  <b-modal
    ref="nameChangeModal"
    :centered="true"
    tabindex="-1"
    role="dialog"
    footer-class="d-flex justify-content-between"
  >
    <div slot="modal-header" class="col-12 mt-2 mb-2">
      <h5 class="text-center">
        {{ text }}
      </h5>
    </div>
    <div class="col-12 form-group">
      <label for="firstName">{{ t('data.application.customerFirstName') }}</label>
      <input
        id="firstName"
        v-model="changedFirstName"
        :class="{ 'is-invalid': !validFirstName }"
        type="text"
        class="form-control data-hj-suppress"
        value
        data-hj-suppress
        @change="validateFirstName(changedFirstName)"
      >
      <div class="invalid-feedback">
        {{ t('data.error.firstName') }}
      </div>
    </div>
    <div class="col-12 form-group">
      <label for="lastName">{{ t('data.application.customerLastName') }}</label>
      <input
        id="lastName"
        v-model="changedLastName"
        :class="{ 'is-invalid': !validLastName }"
        type="text"
        class="form-control data-hj-suppress"
        value
        data-hj-suppress
        @change="validateLastName(changedLastName)"
      >
      <div class="invalid-feedback">
        {{ t('data.error.lastName') }}
      </div>
    </div>
    <div slot="modal-footer" class="col-12 mb-2">
      <inb-button
        id="confirm"
        variant="primary"
        :disabled="isDisabled"
        @click="confirm()"
      >
        {{ confirmButton }}
      </inb-button>
      <inb-button
        id="dismiss"
        variant="outline-primary"
        class="float-right"
        @click="dismiss()"
      >
        {{ cancelButton }}
      </inb-button>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import {
  onMounted, ref, computed,
} from '@nuxtjs/composition-api'
import { InbButton } from 'inbank-frontend-commons/components/base-components'
import Customer from '~/models/Customer'
import { useValidator } from '~/use/Validator'

export default {
  name: 'NameChangeModal',
  components: { InbButton },
  props: {
    bus: {
      type: Vue,
      default: () => new Vue(),
    },
    confirmButton: {
      type: String,
      default: 'OK',
    },
    text: {
      type: String,
      required: true,
    },
    cancelButton: {
      type: String,
      default: 'Cancel',
    },
    application: {
      type: Customer,
      default: () => new Customer({}),
    },
  },
  setup(props, context) {
    const { validator } = useValidator(context)
    const validFirstName = ref(true)
    const validLastName = ref(true)
    const changedFirstName = ref('')
    const changedLastName = ref('')

    function validateFirstName(value) {
      validFirstName.value = validator.personName(value)
    }

    function validateLastName(value) {
      validLastName.value = validator.personName(value)
    }

    const isDisabled = computed(() => (!validFirstName.value || !validLastName.value))

    function confirm() {
      context.emit('updateName', { updated: { firstName: changedFirstName.value, lastName: changedLastName.value } })
      context.refs.nameChangeModal.hide()
    }

    function dismiss() {
      context.refs.nameChangeModal.hide()
    }

    function show() {
      const { lastName, firstName } = props.application
      changedFirstName.value = firstName
      changedLastName.value = lastName
      validFirstName.value = true
      validLastName.value = true
      context.refs.nameChangeModal.show()
    }

    onMounted(() => {
      props.bus.$on('show', () => {
        show()
      })

      props.bus.$on('hide', () => {
        dismiss()
      })
    })
    return {
      confirm,
      changedFirstName,
      changedLastName,
      dismiss,
      show,
      validateFirstName,
      validateLastName,
      validFirstName,
      validLastName,
      isDisabled,
    }
  },
}
</script>
