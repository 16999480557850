/* eslint-disable no-magic-numbers */
import { computed } from '@nuxtjs/composition-api'
import { Context } from '~/types/common'
import { PREFERRED_LANG_COOKIE_KEY } from '~/constants'

const LANGUAGE_COOKIE_AGE = 30 * 24 * 60 * 60

export function useLanguage(context: Context) {
  function switchLanguage(languageCode: string) {
    const { $cookies, $router, switchLocalePath } = context.root
    $cookies.set(PREFERRED_LANG_COOKIE_KEY, languageCode, {
      maxAge: LANGUAGE_COOKIE_AGE,
      path: '/',
    })
    $router.push(switchLocalePath(languageCode))
  }

  const localeSelection = computed(() => {
    const selectedLocale = context.root.$i18n.locale || context.root.$store.state.locale
    const languages = context.root.$i18n.locales
      .map((locale: any) => ({
        value: locale.code,
        label: locale.code.toUpperCase(),
      }))
      .filter((locale: any) => locale.value !== selectedLocale)
    const langCallback = switchLanguage
    return {
      languages,
      langCallback,
      selectedLocale,
    }
  })

  return {
    switchLanguage,
    localeSelection,
  }
}

export default {}
