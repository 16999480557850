import * as changeCase from 'change-case'
import { PRODUCT_TYPE } from '~/constants/products'

export default class ProductMinimal {
  constructor(args = {}) {
    this.id = args.id || null
    this.code = args.code || null
    this.name = args.name || null
    this.subtype = args.subtype ? changeCase.snakeCase(args.subtype) : null
    this.type = args.type || null
  }

  get isRental() {
    return this.type === PRODUCT_TYPE.RENTAL
  }
}
