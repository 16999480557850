<template>
  <div
    :class="{ show : isLoading }"
    class="modal fade js-loading ib-loader-modal"
    tabindex="-1" role="dialog"
    aria-labelledby="loaderModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered justify-content-center" role="document">
      <div class="loader">
        <div class="loader-container">
          <i-loader class="transparent-loader-spinner" :transition-ms="300" />
        </div>
        <p class="text-night-violet">
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ILoader } from 'inbank-frontend-commons/components/common'

export default {
  name: 'AppLoader',
  components: { ILoader },
  props: {
    text: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
  .ib-loader-modal.show{
    display: block;
    opacity: 0.8;
    background-color: #F5F0FF;
  }
</style>
