export default class PaymentScheduleItem {
  constructor(args = {}) {
    this.adminFee = args.adminFee || null
    this.balanceBefore = args.balanceBefore || null
    this.contractFee = args.contractFee || null
    this.insuranceFee = args.insuranceFee || null
    this.interest = args.interest || null
    this.itemNumber = args.itemNumber != null ? args.itemNumber : null
    this.paymentDate = args.paymentDate || null
    this.principal = args.principal || null
    this.totalPayment = args.totalPayment || null
    this.residual = args.residual || null
  }
}
