export default class Contract {
  constructor(args = {}) {
    this.status = args.status || null
    this.terminationReason = args.terminationReason || null
    this.uuid = args.uuid || null
    this.number = args.number || null
    this.payoutBankAccount = args.payoutBankAccount || null
    this.activatedAt = args.activatedAt || null
    // eslint-disable-next-line max-len
    this.creditApplicationContractReferenceUuid = args.creditApplicationContractReferenceUuid || null
    this.activatorName = args.activatorName || null
    this.terminatedAt = args.terminatedAt || null
    this.productCode = args.productCode || null
    this.customerSigned = args.customerSigned ? args.customerSigned : null
    this.repSigned = args.repSigned != null ? args.repSigned : null
    this.signedAt = args.signedAt || null
    this.partnerApprovalAt = args.partnerApprovalAt || null
    this.withdrawable = args.withdrawable != null ? args.withdrawable : null
  }
}
