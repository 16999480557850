export const TRACKING_CONTEXT = {
  LINK_GENERATION: 'link_generation',
}

export const CALCULATOR_DETAILS = {
  EXPANDED: 'pp_calculator_details.expanded',
  LINK_GEN_EXPANDED: 'pp_link_gen_details.expanded',
}

export const CALCULATOR_PAYMENT_SCHEDULE = {
  OPENED: 'pp_calculator_payment_schedule.opened',
  LINK_GEN_OPENED: 'pp_link_gen_payment_schedule.opened',
}

export const EXAMPLE_OFFER = {
  DOWNLOADED: 'pp_example_offer.downloaded',
  LINK_GEN_DOWNLOADED: 'pp_link_gen_example_offer.downloaded',
}

export const DECISION = {
  RECEIVED: 'pp_decision.received',
}

export const OFFER = {
  CONTINUED: 'pp_offer.continued',
  CANCELED: 'pp_offer.cancelled',
  ACCEPTED: 'pp_offer.accepted',
}

export const CONTRACT = {
  CREATED: 'pp_contract.created',
  REJECTED: 'pp_contract.rejected',
  SIGNING_STARTED: 'pp_contract.signing_started',
  SIGNED: 'pp_contract.signed',
  DOWNLOADED: 'pp_contract_pdf.downloaded',
  WITHDRAWAL_STARTED: 'pp_contract.withdrawal_started',
  WITHDRAWAL_CANCELED: 'pp_contract.withdrawal_cancelled',
  WITHDRAW_CONFIRMED: 'pp_contract.withdrawn',
  PARTIAL_RETURN_STARTED: 'pp_contract.partial_return_started',
  PARTIAL_RETURN_CONFIRMED: 'pp_contract.partial_return_confirmed',
  PARTIAL_RETURN_CANCELED: 'pp_contract.partial_return_cancelled',
  PARTIAL_RETURN_SCHEDULE_OPENED: 'pp_contract_partial_return_schedule.shown',
  PARTIAL_RETURN_SCHEDULE_DETAILS_OPENED: 'pp_contract_partial_return_schedule.details_shown',
  REUSE_STARTED: 'pp_application_data.reuse_started',
  REUSE_CONFIRMED: 'pp_application_data.reused',
  REUSE_CANCELED: 'pp_application_data.reuse_cancelled',
  RECALCULATION_STARTED: 'pp_application.recalculation_started',
  RECALCULATION_CONFIRMED: 'pp_application.recalculated',
  RECALCULATION_CANCELED: 'pp_application.recalculation_cancelled',
  CONFIRMATION_STARTED: 'pp_contract.confirmation_started',
  CONFIRMATION_CANCELED: 'pp_contract.confirmation_cancelled',
  CONFIRMED: 'pp_contract.confirmed',
}

export const NEW_APPLICATION = {
  CANCELLED: 'pp_new_application.cancelled',
  SENT: 'pp_new_application.sent',
  STARTED: 'pp_new_application.started',
  CALCULATOR_OPENED: 'pp_calculator.opened',
}

export const OVERVIEW = {
  OPENED: 'pp_ovrvw.opened',
  QUICKVIEW_OPENED: 'pp_ovrvw_quickview.opened',
  QUICKVIEW_DETAILS_OPENED: 'pp_ovrvw_quickview_details.opened',
  DETAILS_OPENED: 'pp_ovrvw_details.opened',
  PURCHASES_PAY_NEXT_MONTH_TAB: 'pp_ovrvw_paynextmo.opened',
}

export const NOTIFICATIONS = {
  OPENED: 'pp_notifications.opened',
}

export const LINK_GENERATION = {
  OPENED: 'pp_link_generation.opened',
  CREATED: 'pp_link_gen.created',
  SHARED_VIA_SMS: 'pp_link_gen.shared_sms',
  SHARED_VIA_EMAIL: 'pp_link_gen.shared_email',
}

export const MANUAL_ACCOUNT_STATEMENT = {
  REQUESTED: 'pp_manual_account_statement.requested',
  UPLOADED: 'pp_manual_account_statement.uploaded',
}

export const AIS_ACCOUNT_STATEMENT = {
  REQUESTED: 'pp_ais.requested',
  EXPANDED: 'pp_ais_manual_upload.expanded',
  COMPLETED: 'pp_ais_manual_upload.completed',
  LINK_SENT: 'pp_ais_link.sent',
  POLLING_EXPIRED: 'pp_ais_polling.expired',
  NEG_DECISION_RECEIVED: 'pp_ais_manual_neg_decision.received',
}

export const SUPPORT = {
  OPENED: 'pp_support.opened',
  SUBJECT_SELECTED: 'pp_support_subject.selected',
  DESCRIPTION_ENTERED: 'pp_support_description.entered',
  FORM_CLEARED: 'pp_support_form.cleared',
  FORM_SENT: 'pp_support_form.sent',
  TECHNICAL_ISSUE: 'Technical issue',
  GENERAL_QUESTION: 'General question',
}

export const SALES_REPORTS = {
  OPENED: 'pp_sales_reports.opened',
  PERIOD_SELECTED: 'pp_sales_reports_period.selected',
  SUBMITTED: 'pp_sales_reports.submitted',
  DOWNLOADED: 'pp_sales_reports.downloaded',
}

export const PAYOUT_REPORTS = {
  OPENED: 'pp_payout_reports.opened',
  PERIOD_SELECTED: 'pp_payout_reports_period.selected',
  SUBMITTED: 'pp_payout_reports.submitted',
  DOWNLOADED: 'pp_payout_reports.downloaded',
}

export const LOGIN = {
  SMART_ID_SELECTED: 'pp_smartid_login.selected',
  SMART_ID_CONFIRMED: 'pp_smartid_login.confirmed',
  MOBILE_ID_SELECTED: 'pp_mobileid_login.selected',
  MOBILE_ID_CONFIRMED: 'pp_mobileid_login.confirmed',
  ID_CARD_SELECTED: 'pp_idcard_login.selected',
  ID_CARD_CONFIRMED: 'pp_idcard_login.confirmed',
  PASSWORD_SELECTED: 'pp_password_login.selected',
  PASSWORD_CONFIRMED: 'pp_password_login.confirmed',
  ERROR: 'pp_login.error',
}

export const RENTAL = {
  CONTRACT_ERROR: 'pp_rental_contract_confirm.error',
  PAYMENT_LINK_SENT: 'pp_rent_payment_link.sent',
  INSURANCE_CHECKED: 'pp_rent_calculator_insurance.checked',
  ADD_ON_ADDED: 'pp_rent_calculator_addon.opened',
  ADD_ON_REMOVED: 'pp_rent_calculator_addon.removed',
  MODEL_REMOVED: 'pp_rent_calculator_model.removed',
}
