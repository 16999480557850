/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

export const MUTATIONS = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_CHECKED_NOTIFICATION_IDS: 'SET_CHECKED_NOTIFICATION_IDS',
  ADD_CHECKED_NOTIFICATION_ID: 'ADD_CHECKED_NOTIFICATION_ID',
  REMOVE_CHECKED_NOTIFICATION_ID: 'REMOVE_CHECKED_NOTIFICATION_ID',
  MARK_NOTIFICATION_READ: 'MARK_NOTIFICATION_READ',
  MARK_SINGLE_NOTIFICATION_READ: 'MARK_SINGLE_NOTIFICATION_READ',
}

export const state = () => ({
  isLoading: false,
  notifications: [],
  checkedNotificationIds: [],
})

export const getters = {
  unreadNotificationsCount(currentState) {
    return currentState.notifications
      .filter((notification) => !notification.read)
      .length
  },

  shouldMarkRead(currentState) {
    return currentState.checkedNotificationIds.length === 0
      || currentState.notifications
        .some((n) => currentState.checkedNotificationIds.includes(n.id) && !n.read)
  },
}

export const mutations = {
  [MUTATIONS.SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading
  },
  [MUTATIONS.SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications
  },
  [MUTATIONS.SET_CHECKED_NOTIFICATION_IDS](state, clearAll) {
    if (clearAll) {
      state.checkedNotificationIds = []
    } else {
      const allNotificationIds = state.notifications.map((n) => n.id)
      state.checkedNotificationIds = allNotificationIds
    }
  },
  [MUTATIONS.ADD_CHECKED_NOTIFICATION_ID](state, notificationId) {
    state.checkedNotificationIds.push(notificationId)
  },
  [MUTATIONS.REMOVE_CHECKED_NOTIFICATION_ID](state, notificationId) {
    const newCheckedIds = state.checkedNotificationIds
    newCheckedIds.splice(newCheckedIds.indexOf(notificationId), 1)
    state.checkedNotificationIds = newCheckedIds
  },
  [MUTATIONS.MARK_NOTIFICATION_READ](state, shouldMarkRead) {
    state.notifications.forEach((n) => {
      if (state.checkedNotificationIds.includes(n.id)) {
        n.read = shouldMarkRead
      }
    })
  },
  [MUTATIONS.MARK_SINGLE_NOTIFICATION_READ](state, notificationId) {
    state.notifications.find((n) => n.id === notificationId).read = true
  },
}

export const actions = {
  setIsLoading({ commit }, isLoading) {
    commit(MUTATIONS.SET_IS_LOADING, isLoading)
  },
  setNotifications({ commit }, notifications) {
    commit(MUTATIONS.SET_NOTIFICATIONS, notifications)
  },
  clearNotifications({ commit }) {
    commit(MUTATIONS.SET_NOTIFICATIONS, [])
  },
  setCheckedNotificationIds({ commit }) {
    commit(MUTATIONS.SET_CHECKED_NOTIFICATION_IDS)
  },
  clearCheckedNotificationIds({ commit }) {
    commit(MUTATIONS.SET_CHECKED_NOTIFICATION_IDS, true)
  },
  addCheckedNotificationId({ commit }, notificationId) {
    commit(MUTATIONS.ADD_CHECKED_NOTIFICATION_ID, notificationId)
  },
  removeCheckedNotificationId({ commit }, notificationId) {
    commit(MUTATIONS.REMOVE_CHECKED_NOTIFICATION_ID, notificationId)
  },
  markNotificationRead({ commit, getters }) {
    commit(MUTATIONS.MARK_NOTIFICATION_READ, getters.shouldMarkRead)
  },
  markSingleNotificationRead({ commit }, notificationId) {
    commit(MUTATIONS.MARK_SINGLE_NOTIFICATION_READ, notificationId)
  },
}
