import Vue from 'vue'

Vue.use({
  install: function (Vue) {
    Vue.mixin({
      beforeCreate() {
        if (this !== this.$root) {
          return
        }

        // Remove some of the locales, so that user could not select them.
        if (this.$env && this.$env.NODE_I18N_LOCALES) {
          const enabledLocales = this.$env.NODE_I18N_LOCALES.split(',')
          this.$i18n.locales = this.$i18n.locales.filter(locale => enabledLocales.includes(locale.code))
        }
      },
    })
  },
})
