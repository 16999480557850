import _ from 'lodash'

import { removeEmptyKeys, includeData, excludeData } from '~/utils/ObjectUtils'

export default class ApiModel {
  constructor(args = {}) {
    this._state = args?._state || null
  }

  get dirtyFields() {
    const { _state, ...data } = this

    if (!_state) {
      return Object.keys(data)
    }

    function isEqualish(a, b) {
      const empties = [null, undefined, '']
      return _.isEqual(a, b) || (empties.includes(a) && empties.includes(b))
    }

    return _.reduce(data, (result, value, key) => (isEqualish(value, this._state[key])
      ? result
      : result.concat(key)), [])
  }

  getDirtyFields(options = {}) {
    const modelDirtyFields = _.cloneDeep(this.dirtyFields)

    if (options.exclude) {
      return modelDirtyFields.filter((field) => !options.exclude.includes(field))
    }
    return modelDirtyFields
  }

  saveState() {
    const { _state, ...data } = this
    this._state = _.cloneDeep(data)
  }

  clearState() {
    this._state = null
  }

  getApiData({
    include, exclude, onlyDirty, inject,
  } = {}) {
    const { _state, ...dataFields } = this

    const rawData = _.cloneDeep(dataFields)

    let data = rawData
    if (onlyDirty) {
      data = includeData(this.dirtyFields, data)
    }
    if (inject) {
      Object.keys(inject).forEach((key) => {
        data[key] = inject[key]
      })
    }
    if (include) {
      data = includeData(include, data)
    }
    if (exclude) {
      data = excludeData(exclude, data)
    }
    removeEmptyKeys(data)
    return data
  }
}
