<template>
  <!-- eslint-disable max-len -->
  <div
    ref="sidebar"
    :class="{ narrow: isCollapsed }"
    class="col-12 sidebar"
  >
    <nav class="navbar navbar-light">
      <form class="form-inline my-0 search-form d-lg-none">
        <!--  <input class="form-control w-100" type="text" :placeholder="t('labels.search')" :aria-label="t('labels.search')"> -->
      </form>

      <inb-button
        :class="{collapsed: !showMobileMenu}"
        class="x-small ml-2 d-lg-none mobile-menu-toggle"
        variant="gray-light"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleShowMobileMenu()"
      >
        <span class="lines">
          <span class="sr-only" />
        </span>
      </inb-button>

      <inb-button
        id="sidebarCollapse"
        class="d-none d-lg-block p-0"
        variant="sidebar-collapse"
        @click="toggleCollapse"
      >
        <span v-if="isCollapsed" class="icons icon-arrow-from-left-circle" />
        <span v-else-if="!isCollapsed" class="icons icon-arrow-from-right-circle" />
      </inb-button>

      <div id="navigation" :class="{show : showMobileMenu}" class="navbar-collapse collapse">
        <div class="sidebar-sticky">
          <ul class="nav flex-column side-main-nav">
            <li v-if="dashboardEnabled" class="nav-item">
              <span class="d-flex flex-row" tabindex="0">
                <inb-button
                  v-b-tooltip.right="{disabled: !isCollapsed || showMobileMenu}"
                  :title="t('links.dashboard')"
                  :disabled="!dashboardEnabled"
                  :to="localePath('dashboard')"
                  variant="link"
                  class="nav-link regular"
                >
                  <span
                    :class="{ 'active': activeMenuItem === 'dashboard' }"
                    class="icons icon--nav icon-dashboard side-menu-icons"
                  />
                  <span
                    :class="{ 'active': activeMenuItem === 'dashboard' }"
                    class="sidebar-text"
                  >
                    {{ t('links.dashboard') }}
                  </span>
                </inb-button>
                <b-tooltip
                  v-if="!dashboardEnabled"
                  :disabled="!isCollapsed"
                  placement="right"
                >
                  {{ t('links.tooltips.dashboardNoPermission') }}
                </b-tooltip>
              </span>
            </li>
            <li class="nav-item">
              <span class="d-flex flex-row">
                <inb-button
                  v-b-tooltip.right="{disabled: !isCollapsed || showMobileMenu}"
                  :title="t('links.overview')"
                  :to="localePath('overview')"
                  variant="link"
                  class="nav-link regular"
                >
                  <span
                    :class="{ 'active': activeMenuItem === 'overview' }"
                    class="icons icon--nav icon-clipboard-list side-menu-icons"
                  />
                  <span
                    :class="{ 'active': activeMenuItem === 'overview' }"
                    class="sidebar-text"
                  >
                    {{ t('links.overview') }}
                  </span>
                </inb-button>
              </span>
            </li>
            <li class="nav-item">
              <span class="d-flex flex-row">
                <inb-button
                  v-b-tooltip.right="{disabled: !isCollapsed || showMobileMenu}"
                  :disabled="!PERMISSIONS.PP_APPLICATION_SALES_FLOW.CREATE_APPLICATION"
                  :title="t('links.newApplication')"
                  :to="localePath('new-application')"
                  variant="link"
                  class="nav-link regular"
                >
                  <span
                    :class="{ 'active': activeMenuItem === 'new-application' }"
                    class="icons icon--nav icon-sliders-h side-menu-icons"
                  />
                  <span
                    :class="{ 'active': activeMenuItem === 'new-application' }"
                    class="sidebar-text"
                  >
                    {{ t('links.newApplication') }}
                  </span>
                </inb-button>
              </span>
              <b-tooltip
                v-if="!PERMISSIONS.PP_APPLICATION_SALES_FLOW.CREATE_APPLICATION"
                :disabled="!isCollapsed"
                placement="right"
              >
                {{ t('links.tooltips.createApplicationNoPermission') }}
              </b-tooltip>
            </li>
            <li
              v-if="newBusinessApplicationPageEnabled"
              v-show="PERMISSIONS.PP_BUSINESS_APPLICATION_SALES_FLOW.REDIRECT_FOR_NEW_BUSINESS_APPLICATION"
              class="nav-item"
            >
              <span id="create-business-application-wrapper" class="d-flex flex-row">
                <inb-button
                  v-b-tooltip.right="{disabled: !isCollapsed || showMobileMenu}"
                  :title="t('links.newBusinessApplication')"
                  :to="localePath('new-business-application')"
                  variant="link"
                  class="nav-link regular"
                >
                  <span
                    :class="{ 'active': activeMenuItem === 'new-business-application' }"
                    class="icons icon--nav icon-sliders-h  side-menu-icons"
                  />
                  <span
                    :class="{ 'active': activeMenuItem === 'new-business-application' }"
                    class="sidebar-text"
                  >
                    {{ t('links.newBusinessApplication') }}
                  </span>
                </inb-button>
              </span>
              <b-tooltip
                v-if="!PERMISSIONS.PP_BUSINESS_APPLICATION_SALES_FLOW.REDIRECT_FOR_NEW_BUSINESS_APPLICATION"
                :disabled="!isCollapsed"
                placement="right"
              >
                {{ t('links.tooltips.createBusinessApplicationNoPermission') }}
              </b-tooltip>
            </li>
            <li class="nav-item">
              <span id="epos-button-wrapper" class="d-flex flex-row" tabindex="0">
                <inb-button
                  v-b-tooltip.right="{disabled: !isCollapsed || showMobileMenu}"
                  :disabled="!PERMISSIONS.PP_APPLICATION.CREATE_EPOS_LINK"
                  :title="t('links.eposSession')"
                  :to="localePath('new-epos-session')"
                  variant="link"
                  class="nav-link regular"
                >
                  <span
                    :class="{ 'active': activeMenuItem === 'new-epos-session' }"
                    class="icons icon--nav icon-e-pos side-menu-icons"
                  />
                  <span
                    :class="{ 'active': activeMenuItem === 'new-epos-session' }"
                    class="sidebar-text"
                  >
                    {{ t('links.eposSession') }}
                  </span>
                </inb-button>
              </span>
              <b-tooltip
                v-if="!PERMISSIONS.PP_APPLICATION.CREATE_EPOS_LINK"
                :disabled="!isCollapsed"
                placement="right"
              >
                {{ t('links.tooltips.newPosSessionNoPermission') }}
              </b-tooltip>
            </li>
            <li class="nav-item">
              <span id="notifications-wrapper" class="d-flex flex-row">
                <inb-button
                  v-b-tooltip.right="{disabled: !isCollapsed || showMobileMenu}"
                  :disabled="!PERMISSIONS.PP_NOTIFICATIONS.VIEW_NOTIFICATIONS"
                  :title="`${t('links.notifications')} ${unreadNotificationsIndicator}`"
                  :to="localePath('notifications')"
                  variant="link"
                  :class="{ 'font-weight-medium': !!unreadNotificationsIndicator }"
                  class="nav-link regular"
                >
                  <span
                    :class="{ 'font-weight-medium': !!unreadNotificationsIndicator,
                              'active': activeMenuItem === 'notifications'}"
                    class="icons icon--nav icon-lightbulb-on side-menu-icons"
                  />
                  <span
                    :class="{ 'active': activeMenuItem === 'notifications' }"
                    class="sidebar-text"
                  >
                    {{ t('links.notifications') }} {{ unreadNotificationsIndicator }}
                  </span>
                </inb-button>
                <b-tooltip
                  v-if="!PERMISSIONS.PP_NOTIFICATIONS.VIEW_NOTIFICATIONS"
                  :disabled="!isCollapsed"
                  placement="right"
                >
                  {{ t('links.tooltips.notificationsNoPermission') }}
                </b-tooltip>
              </span>
            </li>
            <li v-if="hasViewReportsPermission" class="nav-item">
              <span class="d-flex flex-row" tabindex="0">
                <inb-button
                  v-b-tooltip.right="{disabled: !isCollapsed || showMobileMenu}"
                  :title="t('links.reporting')"
                  :to="localePath('reporting')"
                  :disabled="!true"
                  variant="link"
                  class="nav-link regular"
                >
                  <span
                    :class="{ 'active': activeMenuItem === 'reporting' }"
                    class="icons icon--nav icon-file-chart-line side-menu-icons"
                  />
                  <span
                    :class="{ 'active': activeMenuItem === 'reporting' }"
                    class="sidebar-text"
                  >
                    {{ t('links.reporting') }}
                  </span>
                </inb-button>
                <b-tooltip
                  v-if="!true"
                  :disabled="!isCollapsed"
                  placement="right"
                >
                  {{ t('links.tooltips.reportingNoPermission') }}
                </b-tooltip>
              </span>
            </li>
            <li class="nav-item">
              <span class="d-flex flex-row">
                <inb-button
                  v-b-tooltip.right="{disabled: !isCollapsed || showMobileMenu}"
                  :title="t('links.support')"
                  :to="localePath('support')"
                  variant="link"
                  class="nav-link regular"
                >
                  <span
                    :class="{ 'active': activeMenuItem === 'support' }"
                    class="icons icon--nav icon-question-circle side-menu-icons"
                  />
                  <span
                    :class="{ 'active': activeMenuItem === 'support' }"
                    class="sidebar-text"
                  >
                    {{ t('links.support') }}
                  </span>
                </inb-button>
              </span>
            </li>
          </ul>
          <app-sidebar-contacts
            title="contacts.title"
            phone="contacts.phone"
            email="contacts.email"
            opening-hours="contacts.open"
            :opening-hours-enabled="openingHoursEnabled"
          />
          <app-sidebar-contacts
            title="contacts.titleCustomer"
            phone="contacts.phoneCustomer"
            email="contacts.emailCustomer"
            opening-hours="contacts.openCustomer"
            :opening-hours-enabled="openingHoursEnabled"
          />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { debounce } from 'lodash'

import { InbButton } from 'inbank-frontend-commons/components/base-components'

import {
  ref, useStore, watch, computed, onMounted, useRoute, defineComponent, onUnmounted,
} from '@nuxtjs/composition-api'

import { useUIConfig } from '~/use/UIConfig'
import { useNotificationFetch } from '~/use/Notifications'
import { usePermissions } from '~/use/Permissions'
import { useReportingPermissions } from '~/use/reporting/ReportingPermissions'
import { useDashboard } from '~/use/dashboard/Dashboard'
import AppSidebarContacts from './AppSidebarContacts.vue'

const XL_COLLAPSE_BREAK = 1480
const LG_COLLAPSE_BREAK = 992

export default defineComponent({
  name: 'AppSidebar',
  components: {
    InbButton,
    AppSidebarContacts,
  },
  setup(props, context) {
    const {
      openingHoursEnabled,
      supportContactsEnabled,
      customerContactsEnabled,
      newBusinessApplicationPageEnabled,
    } = useUIConfig(context)

    const { PERMISSIONS } = usePermissions(context)
    const { hasViewReportsPermission } = useReportingPermissions(context)
    const { dashboardEnabled } = useDashboard(context)

    const store = useStore()
    const router = useRoute()
    const { deselectShopIfNeeded } = useNotificationFetch(context)

    const showMobileMenu = ref(false)
    const isCollapsed = ref(false)
    const isVerySmall = ref(false)

    let manuallyToggled = false

    // eslint-disable-next-line no-magic-numbers
    const activeMenuItem = computed(() => router.value.fullPath.split('/')[2])
    const unreadNotificationsCount = computed(() => store.getters['notifications/unreadNotificationsCount'])
    const unreadNotificationsIndicator = computed(() => (unreadNotificationsCount.value ? `(${unreadNotificationsCount.value})` : ''))

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value
      isVerySmall.value = !isVerySmall.value
      manuallyToggled = true
    }

    function toggleShowMobileMenu() {
      showMobileMenu.value = !showMobileMenu.value
    }

    const onResize = debounce(() => {
      if (!manuallyToggled) {
        isCollapsed.value = window.innerWidth < XL_COLLAPSE_BREAK
        isVerySmall.value = window.innerWidth < LG_COLLAPSE_BREAK
      }
      // eslint-disable-next-line no-magic-numbers
    }, 200)

    onMounted(() => {
      context.refs.sidebar.addEventListener('transitionend', (event) => {
        if (event.propertyName === 'flex' || event.propertyName === 'flex-basis') {
          store.dispatch('setMenuState', isCollapsed.value)
        }

        if (router.value.fullPath === 'notifications') {
          deselectShopIfNeeded()
        }
      })

      window.addEventListener('resize', onResize)
      onResize()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', onResize)
    })

    watch(isCollapsed, (value) => {
      store.dispatch('setMenuState', value)
    }, { immediate: true })

    watch(isVerySmall, (value) => {
      store.dispatch('setMenuState', value)
    }, { immediate: true })

    return {
      openingHoursEnabled,
      supportContactsEnabled,
      customerContactsEnabled,
      newBusinessApplicationPageEnabled,
      showMobileMenu,
      unreadNotificationsIndicator,
      isCollapsed,
      isVerySmall,
      toggleCollapse,
      deselectShopIfNeeded,
      activeMenuItem,
      toggleShowMobileMenu,
      hasViewReportsPermission,
      dashboardEnabled,
      PERMISSIONS,
    }
  },
})
</script>
