import { PURCHASE_ITEM_TYPE } from '~/constants'
import ApiModel from '~/models/ApiModel'

export default class Vehicle extends ApiModel {
  constructor(args = {}) {
    super(args)

    this.type = PURCHASE_ITEM_TYPE.VEHICLE
    this.vehicleMake = args.vehicleMake || null
    this.vehicleModel = args.vehicleModel || null
    this.vehicleRegistrationDate = args.vehicleRegistrationDate || null
    this.vehicleVin = args.vehicleVin || null
    this.vehicleLicencePlate = args.vehicleLicencePlate || null
  }
}
