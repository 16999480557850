<template>
  <b-modal
    :id="id"
    v-model="show"
    :size="size"
    :centered="centered"
    hide-header
    body-class="pb-0 pt-3"
    footer-class="d-block border-0 pt-0"
    @ok="onOkButtonClick(true)"
  >
    <p class="small" v-html="text" />
    <slot />
    <template #modal-footer="{ ok }">
      <div class="d-flex justify-content-center">
        <div class="col-12 col-md-6 center">
          <inb-button :variant="okVariant" block @click="ok()">
            {{ okTitle }}
          </inb-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { InbButton } from 'inbank-frontend-commons/components/base-components'

export default {
  components: { InbButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    okTitle: {
      type: String,
      default: 'OK',
    },
    okVariant: {
      type: String,
      default: 'primary',
    },
    id: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      show: this.value,
    }
  },
  watch: {
    show(show) {
      this.$emit('input', show)
    },
    async value(value) {
      this.show = value
    },
  },
  methods: {
    onOkButtonClick(value) {
      this.$emit('ok', value)
    },
  },
}
</script>
