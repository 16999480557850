import { COUNTRY_CODE } from '~/constants'
import { Context } from '~/types/common'

export function useUIConfig(context: Context) {
  const isoCode = context.root.$localisation.isoCode()

  const openingHoursEnabled = [
    COUNTRY_CODE.PL,
    COUNTRY_CODE.EE,
  ].includes(isoCode)

  const supportContactsEnabled = [
    COUNTRY_CODE.LV,
  ].includes(isoCode)

  const customerContactsEnabled = [
    COUNTRY_CODE.EE,
  ].includes(isoCode)

  const newBusinessApplicationPageEnabled = [
    COUNTRY_CODE.EE,
  ].includes(isoCode)

  return {
    openingHoursEnabled,
    supportContactsEnabled,
    customerContactsEnabled,
    newBusinessApplicationPageEnabled,
  }
}

export default {}
