import { PREFERRED_LANG_COOKIE_KEY } from '~/constants'

function getLangCookie(app) {
  return app.$cookies.get(PREFERRED_LANG_COOKIE_KEY)
}

export default function redirectLocale({ app, route, redirect }) {
  const locales = app.i18n.locales.map((locale) => locale.code)
  const firstPathPart = route.path.split('/')[1] // 0th is always empty string because path starts with /

  if (locales.includes(firstPathPart)) {
    // There is a locale prefix no need for redirect.
    return
  }

  const defaultLocale = getLangCookie(app) || app.$env?.NODE_I18N_DEFAULT_LOCALE || 'en'

  redirect(`/${defaultLocale}${route.fullPath}`)
}
