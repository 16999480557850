<template>
  <loader />
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    const { statusCode } = this.error

    // eslint-disable-next-line no-magic-numbers
    if (statusCode === 404) {
      this.$router.push(this.localePath('index'))
    }
  },
}
</script>
