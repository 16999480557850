import locale5c098a48 from '../../locales/default/lv.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"lv","silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"en","name":"English","file":"default/en.json"},{"code":"pl","name":"Polski","file":"default/pl.json"},{"code":"et","name":"Eesti","file":"default/et.json"},{"code":"ru","name":"Pусский","file":"default/ru.json"},{"code":"lt","name":"Lietuvių","file":"default/lt.json"},{"code":"lv","name":"Latviešu","file":"default/lv.json"},{"code":"cs","name":"Čeština","file":"default/cs.json"},{"code":"zh","name":"Chinese","file":"default/zh.json"}],
  defaultLocale: "lv",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/opt/app/locales",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  noPrefixDefaultLocale: false,
  loadLanguagesAsync: true,
  redirectCookieKey: "redirected",
  useRedirectCookie: true,
  normalizedLocales: [{"code":"en","name":"English","file":"default/en.json"},{"code":"pl","name":"Polski","file":"default/pl.json"},{"code":"et","name":"Eesti","file":"default/et.json"},{"code":"ru","name":"Pусский","file":"default/ru.json"},{"code":"lt","name":"Lietuvių","file":"default/lt.json"},{"code":"lv","name":"Latviešu","file":"default/lv.json"},{"code":"cs","name":"Čeština","file":"default/cs.json"},{"code":"zh","name":"Chinese","file":"default/zh.json"}],
  localeCodes: ["en","pl","et","ru","lt","lv","cs","zh"],
}

export const localeMessages = {
  'default/lv.json': () => Promise.resolve(locale5c098a48),
  'default/en.json': () => import('../../locales/default/en.json' /* webpackChunkName: "lang-default/en.json" */),
  'default/pl.json': () => import('../../locales/default/pl.json' /* webpackChunkName: "lang-default/pl.json" */),
  'default/et.json': () => import('../../locales/default/et.json' /* webpackChunkName: "lang-default/et.json" */),
  'default/ru.json': () => import('../../locales/default/ru.json' /* webpackChunkName: "lang-default/ru.json" */),
  'default/lt.json': () => import('../../locales/default/lt.json' /* webpackChunkName: "lang-default/lt.json" */),
  'default/cs.json': () => import('../../locales/default/cs.json' /* webpackChunkName: "lang-default/cs.json" */),
  'default/zh.json': () => import('../../locales/default/zh.json' /* webpackChunkName: "lang-default/zh.json" */),
}
