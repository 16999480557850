import moment from 'moment'
import _ from 'lodash'
import ReportFilter, { ConstructorArgs as SuperConstructorArgs, ReportFilterSelectionApiData } from '~/models/reporting/ReportFilter'
import SalesReportFilterOptions, { SalesReportFilterProduct, SalesReportFilterShop } from '~/models/sales-report/SalesReportFilterOptions'

interface GetApiDataOptions {
  initialFilterOptions: SalesReportFilterOptions,
  // Inherit these properties once the ApiModel has been converted to TS
  include: any
  exclude: any
  onlyDirty: any
  inject: any
}

export interface SalesReportFilterSelectionApiData extends ReportFilterSelectionApiData {
  shopUuids: string[]
  userUuids: string[] | null
  productSubtypes: string[]
  productCodes: string[]
}

export interface ConstructorArgs extends SuperConstructorArgs {
  shopUuids?: string[]
  userUuids?: string[]
  productSubtypes?: string[]
  productCodes?: string[]
}

export default class SalesReportFilterSelection extends ReportFilter {
  shopUuids: string[] = []
  userUuids: string[] = []
  productSubtypes: string[] = []
  productCodes: string[] = []

  constructor(args: ConstructorArgs = {}) {
    super(args)

    this.shopUuids = args.shopUuids || []
    this.userUuids = args.userUuids || []
    this.productSubtypes = args.productSubtypes || []
    this.productCodes = args.productCodes || []
  }

  get isFilled(): boolean {
    return !!(this.shopUuids.length
      && this.userUuids.length
      && this.productSubtypes.length
      && this.productCodes.length)
  }

  setDefaultValues(filterOptions: SalesReportFilterOptions, currentShop: string | null = null) {
    let validUsers = null
    let activeShop = null
    let activeShopProducts = null

    const allUsers = [
      ...new Set(filterOptions.shops.map((shop) => shop.users).flat()),
    ]

    if (currentShop !== null) {
      activeShop = filterOptions.shops.length !== 0
        ? filterOptions.shops.filter((shop) => shop?.uuid === currentShop)[0]
        : {} as SalesReportFilterShop

      validUsers = _.isEmpty(activeShop) ? [] : activeShop.users.filter((users) => allUsers.includes(users))
    }

    this.shopUuids = currentShop !== null
      ? [currentShop]
      : filterOptions.shops.map((shop) => shop.uuid)

    this.userUuids = currentShop !== null
      ? validUsers!.map((user) => user.uuid)
      : [...new Set(filterOptions.shops.map((shop) => shop.users).flat().map((user) => user.uuid))]

    if (currentShop !== null && !_.isEmpty(activeShop)) {
      activeShopProducts = this.getActiveProducts(activeShop.products)
    }

    const activeProducts = this.getActiveProducts(filterOptions.products)

    this.productSubtypes = currentShop !== null && activeShopProducts !== null
      ? [...new Set(activeShopProducts.map((product) => product.subtype))]
      : [...new Set(activeProducts.map((product) => product.subtype))]

    this.productCodes = currentShop !== null && activeShopProducts !== null
      ? activeShopProducts.map((product) => product.code)
      : activeProducts.map((product) => product.code)
  }

  getApiData(options: GetApiDataOptions) {
    const { initialFilterOptions, ...otherOptions } = options

    const data: any = super.getApiData(otherOptions)

    const filterOptions = new SalesReportFilterOptions({
      ...initialFilterOptions,
      shops: initialFilterOptions.getShopsForUuids(this.shopUuids),
    })

    if (data.userUuids.length === filterOptions.employees.length) {
      data.userUuids = null
    }
    return data
  }

  getActiveProducts(products: SalesReportFilterProduct[]): SalesReportFilterProduct[] {
    return products.filter(({ validFrom, validTo }) => {
      if (validFrom && validTo) {
        return (
          moment(validFrom).isSameOrBefore(this.periodFrom)
          && moment(validTo).isSameOrAfter(this.periodTo)
        )
        || moment(validFrom).isBetween(this.periodFrom, this.periodTo, 'day', '[]')
        || moment(validTo).isBetween(this.periodFrom, this.periodTo, 'day', '[]')
      }
      if (validFrom) {
        return moment(validFrom).isSameOrBefore(this.periodTo)
      }
      if (validTo) {
        return moment(validTo).isSameOrAfter(this.periodFrom)
      }
      return true
    })
  }
}
