/* eslint-disable no-shadow */
const MUTATIONS = {
  SET_PREV_SCROLL_OFFSET: 'SET_PREV_SCROLL_OFFSET',
  SET_APPLICATIONS_CACHE: 'SET_APPLICATIONS_CACHE',
  SET_APPLICATIONS_FILTERS_CACHE: 'SET_APPLICATIONS_FILTERS_CACHE',
  SET_CURRENT_TAB: 'SET_CURRENT_TAB',
  SET_CREDIT_PURCHASES_CACHE: 'SET_CREDIT_PURCHASES_CACHE',
  SET_CREDIT_PURCHASES_CUSTOMERS_CACHE: 'SET_CREDIT_PURCHASES_CUSTOMERS_CACHE',
  SET_CREDIT_PURCHASES_USERS_CACHE: 'SET_CREDIT_PURCHASES_USERS_CACHE',
  SET_CREDIT_PURCHASES_PAGE: 'SET_CREDIT_PURCHASES_PAGE',
  SET_CREDIT_PURCHASES_FILTERS_CACHE: 'SET_CREDIT_PURCHASES_FILTERS_CACHE',
}

export const TABS = {
  ALL_APPLICATIONS: 0,
  CREDIT_PURCHASES: 1,
}

export const DEFAULT_APPLICATION_FILTERS = {
  shopUuid: 0,
  createdAtFrom: null,
  createdAtTo: null,
  processStatus: '',
}

export const DEFAULT_CREDIT_PURCHASES_FILTERS = {
  creditPurchaseNumber: null,
  createdAtFrom: null,
  createdAtTo: null,
  processStatus: null,
}

export const state = () => ({
  prevScrollOffset: 0,
  applicationsCache: [],
  creditPurchasesCache: [],
  creditPurchasesCustomersCache: [],
  creditPurchasesUsersCache: [],
  currentCreditPurchasesPage: 0,
  applicationsFiltersCache: { ...DEFAULT_APPLICATION_FILTERS },
  creditPurchasesFilter: { ...DEFAULT_CREDIT_PURCHASES_FILTERS },
  currentTab: TABS.ALL_APPLICATIONS,
})

export const mutations = {
  [MUTATIONS.SET_PREV_SCROLL_OFFSET](state, offset) {
    state.prevScrollOffset = offset
  },
  [MUTATIONS.SET_APPLICATIONS_CACHE](state, applications) {
    state.applicationsCache = applications
  },
  [MUTATIONS.SET_APPLICATIONS_FILTERS_CACHE](state, filters) {
    state.applicationsFiltersCache = filters
  },
  [MUTATIONS.SET_CURRENT_TAB](state, tab) {
    state.currentTab = tab
  },
  [MUTATIONS.SET_CREDIT_PURCHASES_CACHE](state, creditPurchases) {
    state.creditPurchasesCache = creditPurchases
  },
  [MUTATIONS.SET_CREDIT_PURCHASES_CUSTOMERS_CACHE](state, creditPurchasesCustomers) {
    state.creditPurchasesCustomersCache = creditPurchasesCustomers
  },
  [MUTATIONS.SET_CREDIT_PURCHASES_USERS_CACHE](state, creditPurchasesUsers) {
    state.creditPurchasesUsersCache = creditPurchasesUsers
  },
  [MUTATIONS.SET_CREDIT_PURCHASES_PAGE](state, creditPurchasesPage) {
    state.currentCreditPurchasesPage = creditPurchasesPage
  },
  [MUTATIONS.SET_CREDIT_PURCHASES_FILTERS_CACHE](state, filters) {
    state.creditPurchasesFilter = filters
  },
}

export const actions = {
  setPrevScrollOffset({ commit }, offset) {
    commit(MUTATIONS.SET_PREV_SCROLL_OFFSET, offset)
  },
  setApplicationsCache({ commit }, applications) {
    commit(MUTATIONS.SET_APPLICATIONS_CACHE, applications)
  },
  setApplicationsFiltersCache({ commit }, filters) {
    commit(MUTATIONS.SET_APPLICATIONS_FILTERS_CACHE, filters)
  },
  setCurrentTab({ commit }, tab) {
    commit(MUTATIONS.SET_CURRENT_TAB, tab)
  },
  setCreditPurchasesCache({ commit }, creditPurchases) {
    commit(MUTATIONS.SET_CREDIT_PURCHASES_CACHE, creditPurchases)
  },
  setCreditPurchasesCustomersCache({ commit }, creditPurchasesCustomers) {
    commit(MUTATIONS.SET_CREDIT_PURCHASES_CUSTOMERS_CACHE, creditPurchasesCustomers)
  },
  setCreditPurchasesUsersCache({ commit }, creditPurchasesUsers) {
    commit(MUTATIONS.SET_CREDIT_PURCHASES_USERS_CACHE, creditPurchasesUsers)
  },
  setCreditPurchasesPage({ commit }, creditPurchasesPage) {
    commit(MUTATIONS.SET_CREDIT_PURCHASES_PAGE, creditPurchasesPage)
  },
  setCreditApplicationFiltersCache({ commit }, filters) {
    commit(MUTATIONS.SET_CREDIT_PURCHASES_FILTERS_CACHE, filters)
  },
  setCreditPurchasesTabCache({ dispatch }, {
    creditPurchases,
    creditPurchasesCustomers,
    creditPurchasesUsers,
    creditPurchasesPage,
  }) {
    dispatch('setCreditPurchasesCache', creditPurchases)
    dispatch('setCreditPurchasesCustomersCache', creditPurchasesCustomers)
    dispatch('setCreditPurchasesUsersCache', creditPurchasesUsers)
    dispatch('setCreditPurchasesPage', creditPurchasesPage)
  },
  clearApplicationCache({ dispatch }) {
    dispatch('setApplicationsCache', [])
    dispatch('setApplicationsFiltersCache', DEFAULT_APPLICATION_FILTERS)
  },
  clearCreditPurchasesCache({ dispatch }) {
    dispatch('setCreditPurchasesCache', [])
    dispatch('setCreditPurchasesCustomersCache', [])
    dispatch('setCreditPurchasesUsersCache', [])
    dispatch('setCreditPurchasesPage', 0)
    dispatch('setCreditApplicationFiltersCache', DEFAULT_CREDIT_PURCHASES_FILTERS)
  },
}
