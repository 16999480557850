/* eslint-disable no-magic-numbers */
import { useLogout } from '~/use/authentication/Authentication'

import UserService from '~/services/api/UserService'

import { DURATION_MILLISECONDS } from '~/constants'
import { SESSION_EVENT } from '~/constants/events'

// There are two sessions to handle:
// 1. Partner Portal UI session that is defined an env variable
// 2. JWT expire session. Should be updated in the background, as long as user is active.

const REFRESH_BUFFER_MINUTES = 2

let refreshTimeout = null
let currentSessionExpire = null

let sessionTimer = null
let sessionNotifyTimer = null

let backgroundRefreshEnabled = true

export function getRefreshTimeoutMs(sessionExpire) {
  const timeToExpireMs = (sessionExpire * 1000) - Date.now()
  return Math.max(timeToExpireMs - (REFRESH_BUFFER_MINUTES * 60000), 0)
}

export function useEndSession(context) {
  const { logout } = useLogout(context)

  async function endSession() {
    clearTimeout(refreshTimeout)
    clearTimeout(sessionTimer)
    clearTimeout(sessionNotifyTimer)
    refreshTimeout = null
    sessionTimer = null
    sessionNotifyTimer = null

    await logout()
  }

  return { endSession }
}

export function useJwtSessionUpdater() {
  function setRefreshTimeout(timeout) {
    clearTimeout(refreshTimeout)
    refreshTimeout = setTimeout(async () => {
      refreshTimeout = null
      const { tokenExpire } = await UserService.refreshToken()
      setJwtSessionUpdater(tokenExpire)
    }, timeout)
  }


  function setJwtSessionUpdater(sessionExpire) {
    if (process.client) {
      const refreshTimeoutMs = getRefreshTimeoutMs(sessionExpire)

      if (currentSessionExpire !== sessionExpire || !refreshTimeout) {
        currentSessionExpire = sessionExpire
        setRefreshTimeout(refreshTimeoutMs)
      }
    }
  }

  return { setJwtSessionUpdater }
}

function useUiSessionUpdater(context) {
  const { endSession } = useEndSession(context)

  const { NODE_SESSION_EXPIRE, NODE_SESSION_EXPIRE_NOTIFY, session } = context.root.$env

  const uiSessionLength = NODE_SESSION_EXPIRE
    ? parseInt(NODE_SESSION_EXPIRE, 10)
    : session.defaultExpire

  const uiSessionLengthNotify = NODE_SESSION_EXPIRE_NOTIFY
    ? parseInt(NODE_SESSION_EXPIRE_NOTIFY, 10)
    : session.defaultExpireNotify

  // (Re)sets/defines UI session and notification timers
  function sessionTimerReset() {
    backgroundRefreshEnabled = true

    clearTimeout(sessionTimer)
    clearTimeout(sessionNotifyTimer)

    sessionTimer = setTimeout(() => {
      clearTimeout(refreshTimeout)

      endSession()
      sessionTimer = null
    }, uiSessionLength * DURATION_MILLISECONDS.SECOND)

    sessionNotifyTimer = setTimeout(() => {
      backgroundRefreshEnabled = false

      context.root.$emit(SESSION_EVENT.EXPIRING)

      sessionNotifyTimer = null
    }, uiSessionLengthNotify * DURATION_MILLISECONDS.SECOND)
  }

  async function sessionRefresh() {
    try {
      await UserService.getCurrentUser(context.root.$store)
      sessionTimerReset()
    } catch (error) {
      endSession()
    }
  }

  // Lets the proxy know that user is still active
  async function backgroundSessionRefresh() {
    if (backgroundRefreshEnabled) {
      sessionRefresh()
    }
  }

  return {
    sessionTimerReset,
    sessionRefresh,
    backgroundSessionRefresh,
  }
}

export function useSessionUpdater(context) {
  const { setJwtSessionUpdater } = useJwtSessionUpdater()
  const { endSession } = useEndSession(context)
  const {
    sessionTimerReset,
    sessionRefresh,
    backgroundSessionRefresh,
  } = useUiSessionUpdater(context)

  function initSessionUpdater(sessionExpire) {
    setJwtSessionUpdater(sessionExpire)
    sessionTimerReset()
  }

  return {
    initSessionUpdater,
    sessionRefresh,
    backgroundSessionRefresh,
    endSession,
  }
}

export default {}
