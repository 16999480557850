


































































































import { defineComponent, computed } from '@nuxtjs/composition-api'
import { Context } from '~/types/common'

import ApplicationDetail from '~/models/ApplicationDetail'

import { INTREST_TYPE } from '~/constants/contract'

import DataLabel from '~/components/common/DataLabel.vue'

import { useFloatingInterestRate } from '~/use/FloatingInterestRate'

export default defineComponent({
  components: { DataLabel },
  props: {
    application: {
      type: ApplicationDetail,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, setupContext) {
    const context = setupContext as Context

    const {
      getFloatingInterestRateValue,
      getFloatingCampaignInterestRateValue,
      getFloatingInterestRateCalculationText,
      getFloatingCampaignInterestRateCalculationText,
      getCampaignDurationText,
      getNextBaseRateChangeDate,
    } = useFloatingInterestRate(context)

    return {
      /* eslint-disable max-len */
      INTREST_TYPE,

      floatingInterestRateValue: computed(() => getFloatingInterestRateValue(props.application)),
      floatingCampaignInterestRateValue: computed(() => getFloatingCampaignInterestRateValue(props.application)),
      floatingInterestRateCalculationText: computed(() => getFloatingInterestRateCalculationText(props.application)),
      floatingCampaignInterestRateCalculationText: computed(() => getFloatingCampaignInterestRateCalculationText(props.application)),

      campaignInterestRate: computed(() => props.application.paymentSchedule?.campaignInterestRate),
      campaignDurationText: computed(() => getCampaignDurationText(props.application)),
      campaignInterestType: computed(() => props.application.paymentSchedule?.campaignInterestType),

      interestType: computed(() => props.application.paymentSchedule?.interestType),
      nextBaseRateChangeDate: computed(() => getNextBaseRateChangeDate(props.application)),
    }
  },
})
