import Fingerprint2 from 'fingerprintjs2'

const FALLBACK_IDLE_TIMEOUT = 500

export default {
  getFingerprint() {
    if (!process.client) {
      throw new Error('Fingerprint can only be generated on the client side')
    }

    function getPrint(callback) {
      Fingerprint2.get((components) => {
        const values = components.map(component => component.value)
        const murmurSeed = 31
        const murmur = Fingerprint2.x64hash128(values.join(''), murmurSeed)
        callback(murmur)
      })
    }

    return new Promise((resolve) => {
      if (window.requestIdleCallback) {
        requestIdleCallback(() => {
          getPrint(resolve)
        })
      } else {
        setTimeout(() => {
          getPrint(resolve)
        }, FALLBACK_IDLE_TIMEOUT)
      }
    })
  },
}
