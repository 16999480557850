/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
export const strict = false

const MUTATIONS = {
  SET_PW_CHANGE_RETURN_PATH: 'SET_PW_CHANGE_RETURN_PATH',
  SET_USER: 'SET_USER',
}

export const state = () => ({
  layout: 'default',
  activeShop: null,
  pwChangeReturnPath: null,
  menuClosed: null,
})

export const mutations = {
  setLang(state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
    }
  },
  setLayout(state, payload) {
    state.layout = payload
  },
  setActiveShop(state, shop) {
    state.activeShop = shop
  },
  setMenuState(state, payload) {
    state.menuClosed = payload
  },
  [MUTATIONS.SET_PW_CHANGE_RETURN_PATH](state, path) {
    state.pwChangeReturnPath = path
  },
}

export const actions = {
  setActiveShop({ commit, dispatch }, shop) {
    commit('setActiveShop', shop)
    dispatch('overview/clearApplicationCache')
    dispatch('overview/clearCreditPurchasesCache')
  },
  setMenuState({ commit }, payload) {
    commit('setMenuState', payload)
  },
  setPwChangeReturnPath({ commit }, path) {
    commit(MUTATIONS.SET_PW_CHANGE_RETURN_PATH, path)
  },
}
