export const AUTH_STRATEGY = {
  PASSWORD: 'password',
  SMART_ID: 'SMART_ID',
  SMART_CARD: 'SMART_CARD',
  MOBILE_ID: 'MOBILE_ID',
}

export const AUTH_STATUS = {
  OK: 'ok',
  CANCELED: 'canceled',
  WAITING: 'waiting',
}


export default {}
