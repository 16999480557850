<template
  :cookieConsentName="cookieConsentName"
>
  <i-modal
    id="cookie-consent-modal"
    :modal-show="modalOpen"
    header-class="p-0"
    title-class="text-left"
    hide-footer
    opaque-backdrop
    :title="t('partnerPortal.cookieConsentModal.title')"
    :no-close-on-esc="!closable"
    :no-close-on-backdrop="!closable"
    :hide-header-close="!closable"
    @hidden="$emit('hide')"
  >
    <template v-if="!closable && languages.length > 1" #lang-switch>
      <i-language-dropdown
        :dropdown-text="currentLocale"
        :languages="languages"
        size="md"
        @click="switchLanguage($event)"
      />
    </template>

    <p class="modal-description" v-html="t('partnerPortal.cookieConsentModal.description')" />

    <div class="mb-5">
      <p class="manage-cookies">
        {{ t('partnerPortal.cookieConsentModal.manageCookies.title') }}
      </p>
      <div v-for="(consent, index) in consents" :key="index">
        <i-checkbox
          v-model="consent.state"
          :binding="consent.binding"
          :label-text="t(consent.name)"
          :field-disabled="consent.disabled"
          field-name="cookies"
          class="ml-1 mb-2"
        />
        <p>
          {{ t(consent.description) }}
        </p>
      </div>
    </div>

    <i-button
      variant="primary"
      block
      @click="accept"
    >
      {{ t('partnerPortal.cookieConsentModal.saveSettingsBtn') }}
    </i-button>
  </i-modal>
</template>

<script>
import moment from 'moment'

import {
  ILanguageDropdown,
  IModal,
  ICheckbox,
  IButton,
} from 'inbank-frontend-commons/components/common'
import { useTrackingUtils } from '~/utils/TrackingUtils'

import { useLanguage } from '~/use/Language'

export default {
  name: 'CookieConsentsModal',
  components: {
    ILanguageDropdown,
    IModal,
    ICheckbox,
    IButton,
  },
  props: {
    /**
     * Local storage key value
     */
    cookieConsentName: {
      type: String,
      default: 'INBANKGDPR:accepted',
    },
    /**
     * Flag for modal to be closable, f.e on cookie consent page
     */
    closable: {
      type: Boolean,
    },
    /**
     * Object containing functional links
     */
    consents: {
      type: Object,
      default: () => (
        {
          functional: {
            name: 'partnerPortal.cookieConsentModal.cookies.functional.label',
            binding: 'functionalCookies',
            category: 'functional',
            description: 'partnerPortal.cookieConsentModal.cookies.functional.description',
            state: true,
            disabled: true,
          },
          analytical: {
            name: 'partnerPortal.cookieConsentModal.cookies.analytical.label',
            binding: 'analyticalCookies',
            category: 'analytical',
            description: 'partnerPortal.cookieConsentModal.cookies.analytical.description',
            state: true,
          },
        }),
    },
    open: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const { switchLanguage, localeSelection } = useLanguage(context)
    const { initiateTracking } = useTrackingUtils(context)
    return {
      switchLanguage,
      localeSelection,
      initiateTracking,
    }
  },
  data() {
    return {
      modalOpen: false,
      consentName: this.cookieConsentName,
    }
  },
  computed: {
    currentLocale() {
      return `${this.localeSelection.selectedLocale}`.toUpperCase()
    },
    languages() {
      return this.localeSelection.languages
    },
  },
  watch: {
    open(newVal) {
      this.modalOpen = newVal
    },
  },
  mounted() {
    if (!this.getGDPR() && !this.$route.path.includes(this.localePath('cookie-policy'))) {
      this.modalOpen = true
    } else if (this.getGDPR()) {
      const availableConsents = localStorage.getItem(this.cookieConsentName)
      const consentExpiryDate = Object.values(JSON.parse(availableConsents))[0]?.expiry

      if (consentExpiryDate && moment(consentExpiryDate).isBefore(Date.now())) {
        localStorage.removeItem(this.cookieConsentName)
        this.modalOpen = true
      } else {
        this.initiateTracking()
      }
    }
  },
  methods: {
    getGDPR() {
      return localStorage.getItem(this.consentName, true)
    },
    accept() {
      const consentsData = this.consents
      this.modalOpen = false

      Object.keys(consentsData).forEach((key) => {
        consentsData[key].updatedAt = new Date().getTime()
        // eslint-disable-next-line no-magic-numbers, max-len
        consentsData[key].expiry = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000) // set expiry date to current date plus 90 days
      })

      localStorage.setItem(this.cookieConsentName, JSON.stringify(consentsData))
      this.initiateTracking()
      this.$emit('update', consentsData)
    },
  },
}
</script>
