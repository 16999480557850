/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import _ from 'lodash'

export function objectContainsValue(object, validator) {
  if (validator(object)) {
    return true
  } else if (object && typeof object === 'object') {
    for (const key in object) {
      if (objectContainsValue(object[key], validator)) {
        return true
      }
    }
  } else if (
    object instanceof Array &&
    object.some(item => objectContainsValue(item, validator))
  ) {
    return true
  }
  return false
}

export function replaceValue(object, check, replace) {
  for (const key in object) {
    const value = object[key]
    if (check(value)) {
      object[key] = replace(object[key])
    } else if (value && typeof value === 'object') {
      replaceValue(object[key], check, replace)
    } else if (value instanceof Array) {
      object[key].forEach(v => replaceValue(v, check, replace))
    }
  }
}

export function removeValue(object, check) {
  for (const key in object) {
    const value = object[key]
    if (check(value)) {
      delete object[key]
    } else if (value && typeof value === 'object') {
      removeValue(object[key], check)
    } else if (value instanceof Array) {
      object[key].forEach(v => removeValue(v, check))
    }
  }
}

export function removeEmptyKeys(object) {
  removeValue(object, value => [null, undefined, ''].includes(value))
}

export function deepMask(mask, ignore = {}, object) {
  Object.keys(object).forEach(key => {
    const value = object[key]
    if (value instanceof Array) {
      object[key].forEach((x, index) => {
        deepMask(mask[key], ignore[key], object[key][index])
      })
    } else if (value !== null && typeof value === 'object') {
      deepMask(mask[key], ignore[key], object[key])
    } else if (!mask[key] && !ignore[key]) {
      delete object[key]
    }
  })
}

export function removeEmptyObjects(object, iterations = 1) {
  for (let i = 0; i < iterations; i += 1) {
    removeValue(object, (value) => value instanceof Object && Object.keys(value).length === 0)
  }
}

export function removeEmptyArrays(object) {
  removeValue(object, (value) => value instanceof Array && value.length === 0)
}

export function includeData(include, data) {
  const trimmedData = {}
  include.forEach((field) => {
    _.set(trimmedData, field, _.get(data, field))
  })
  return trimmedData
}

export function excludeData(exclude, data) {
  const trimmedData = _.cloneDeep(data)
  exclude.forEach((field) => {
    _.unset(trimmedData, field)
  })
  return trimmedData
}
