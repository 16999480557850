import moment from 'moment'

import { REPORTING_QUICK_FILTER } from '~/constants/reporting'
import ApiModel from '../ApiModel'

const DATE_FORMAT = 'YYYY-MM-DD'

export interface ReportFilterSelectionApiData {
  periodFrom: string
  periodTo: string
}

export interface ConstructorArgs {
  periodFrom?: string
  periodTo?: string
  quickFilterPeriod?: string
}

export default class ReportFilter extends ApiModel {
  periodFrom: string | null = null
  periodTo: string | null = null

  constructor(args: ConstructorArgs = {}) {
    super()

    this.periodFrom = args.periodFrom || null
    this.periodTo = args.periodTo || null

    if (args.quickFilterPeriod) {
      this.period = args.quickFilterPeriod
    }
  }

  get periodRangeDates(): [periodFrom: Date, periodTo: Date] {
    return [
      new Date(this.periodFrom!.split('T')[0]),
      new Date(this.periodTo!.split('T')[0]),
    ]
  }

  set period(value: string | [periodFrom: Date, periodTo: Date]) {
    const isoNow = () => moment().utcOffset(0)

    if (value instanceof Array) {
      const [periodFrom, periodTo] = value
      this.periodFrom = moment(periodFrom).utcOffset(0, true).startOf('day').format(DATE_FORMAT)
      this.periodTo = moment(periodTo).utcOffset(0, true).endOf('day').format(DATE_FORMAT)
      return
    }

    switch (value) {
      case REPORTING_QUICK_FILTER.TODAY: {
        this.periodFrom = isoNow().startOf('day').format(DATE_FORMAT)
        this.periodTo = isoNow().endOf('day').format(DATE_FORMAT)
        break
      }
      case REPORTING_QUICK_FILTER.YESTERDAY: {
        const isoYesterday = () => isoNow().subtract(1, 'days')
        this.periodFrom = isoYesterday().startOf('day').format(DATE_FORMAT)
        this.periodTo = isoYesterday().endOf('day').format(DATE_FORMAT)
        break
      }
      case REPORTING_QUICK_FILTER.CURRENT_WEEK: {
        this.periodFrom = isoNow().startOf('isoWeek').format(DATE_FORMAT)
        this.periodTo = isoNow().endOf('day').format(DATE_FORMAT)
        break
      }
      case REPORTING_QUICK_FILTER.CURRENT_MONTH: {
        this.periodFrom = isoNow().startOf('month').format(DATE_FORMAT)
        this.periodTo = isoNow().endOf('day').format(DATE_FORMAT)
        break
      }
      case REPORTING_QUICK_FILTER.LAST_WEEK: {
        const isoLastWeek = () => isoNow().subtract(1, 'weeks')
        this.periodFrom = isoLastWeek().startOf('isoWeek').format(DATE_FORMAT)
        this.periodTo = isoLastWeek().endOf('isoWeek').format(DATE_FORMAT)
        break
      }
      case REPORTING_QUICK_FILTER.LAST_MONTH: {
        const isoLastMonth = () => isoNow().subtract(1, 'months')
        this.periodFrom = isoLastMonth().startOf('month').format(DATE_FORMAT)
        this.periodTo = isoLastMonth().endOf('month').format(DATE_FORMAT)
        break
      }
      case REPORTING_QUICK_FILTER.CURRENT_YEAR: {
        this.periodFrom = isoNow().startOf('year').format(DATE_FORMAT)
        this.periodTo = isoNow().endOf('day').format(DATE_FORMAT)
        break
      }
      default:
        break
    }
  }
}
