/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { TIMEOUT_DURATION } from '~/constants'
import { isDocumentHidden } from '~/utils/VisibilityUtils'

interface ValidatorResult<T> {
  hasFinished: boolean
  data: T | null
}

interface PollerOptions<T> {
  fetchMethod?: () => Promise<T>
  validator?: (data: T) => ValidatorResult<T>
  onDone?: (data: T) => void
  onError?: (error: any) => void
  onCancel?: () => void
  onEnd?: () => void
  timeout?: () => number
  cancelTimeout?: () => number
  autoPauseExecution?: boolean
}

export class Poller<T> implements PollerOptions<T> {
  fetchMethod = () => Promise.resolve({}) as Promise<T>
  validator = (data: any) => ({ hasFinished: true, data })
  onDone = (d: T) => { /* stub */ }
  onError = (e: any) => { /* stub */ }
  onCancel = () => { /* stub */ }
  onEnd = () => { /* stub */ }
  timeout = () => TIMEOUT_DURATION.SECOND
  cancelTimeout = () => 0

  autoPauseExecution: boolean
  cancelled: boolean

  constructor(options: PollerOptions<T>) {
    this.fetchMethod = options.fetchMethod || this.fetchMethod
    this.validator = options.validator || this.validator
    this.onDone = options.onDone || this.onDone
    this.onError = options.onError || this.onError
    this.onCancel = options.onCancel || this.onCancel
    this.onEnd = options.onEnd || this.onEnd
    this.timeout = options.timeout || this.timeout
    this.cancelTimeout = options.cancelTimeout || this.cancelTimeout
    this.autoPauseExecution = !!options.autoPauseExecution

    this.cancelled = false
  }

  start() {
    const cancelTimeoutValue = this.cancelTimeout()
    if (cancelTimeoutValue) {
      setTimeout(() => {
        this.cancelled = true
      }, cancelTimeoutValue)
    }
    this.cancelled = false
    this._poll()
  }

  cancel() {
    this.cancelled = true
  }

  _poll() {
    setTimeout(async () => {
      if (this.autoPauseExecution && isDocumentHidden()) {
        this._poll()
        return
      }

      if (this.cancelled) {
        this.onCancel()
      } else {
        try {
          const data = await this.fetchMethod()
          const { hasFinished, data: doneData } = this.validator(data)
          if (hasFinished) {
            this.onDone(doneData)
          } else {
            this._poll()
            return
          }
        } catch (error) {
          this.onError(error)
        }
      }
      this.onEnd()
    }, this.timeout())
  }
}

export function poll(options: PollerOptions<any>) {
  new Poller(options).start()
}
