export const AUTHENTICATION_URLS = {
  ee: 'https://auth.dokobit.com',
  lv: 'https://auth.dokobit.com',
  'e-residency': 'https://auth.dokobit.com',
}

export const RESIDENCY_MAP = {
  EE: ['ee', 'e-residency'],
  LV: ['lv'],
  PL: [],
  CZ: [],
  LT: [],
}

export const CERTIFICATE_PURPOSE = {
  LOGIN: 'login',
  SIGN: 'sign',
}

export const DEFAULT_LOCALE = {
  EE: 'et',
  LV: 'lv',
}
