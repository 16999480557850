/* eslint-disable no-magic-numbers */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

function sumDig(n: number) {
  let a = 0
  while (n > 0) {
    a += n % 10
    n = parseInt(`${n / 10}`, 10)
  }
  return a
}

export default function validateIMEI(n: number) {
  const s = n.toString()
  const len = s.length

  if (len !== 15) return false

  let sum = 0
  for (let i = len; i >= 1; i--) {
    let d = (n % 10)

    // Doubling every alternate digit
    if (i % 2 === 0) {
      d *= 2
    }

    // Finding sum of the digits
    sum += sumDig(d)
    n = parseInt(`${n / 10}`, 10)
  }

  return (sum % 10 === 0)
}
