import ApiModel from '~/models/ApiModel'

export default class Customer extends ApiModel {
  constructor(args = {}) {
    super(args)
    this.id = args.id || null
    this.uuid = args.uuid || null
    this.identityCode = args.identityCode || null
    this.firstName = args.firstName || null
    this.lastName = args.lastName || null
    this.gender = args.gender || null
    this.communicationLanguage = args.communicationLanguage || null
    this.residency = args.residency || null
    this.taxResidency = args.taxResidency || null
    this.pepStatus = args.pepStatus || null
    this.pepPosition = args.pepPosition || null
    this.isUbo = args.isUbo != null ? args.isUbo : false
    this.isNewCustomer = args.isNewCustomer != null ? args.isNewCustomer : false
  }

  setDataFromLocalisation(localisation, fieldsToSet = null) {
    function shouldSet(fieldName) {
      return !fieldsToSet || fieldsToSet.includes(fieldName)
    }
    if (shouldSet('residency')) {
      this.residency = localisation.isoCode()
    }
    if (shouldSet('taxResidency')) {
      this.taxResidency = localisation.isoCode().toLowerCase()
    }
    if (shouldSet('communicationLanguage')) {
      this.communicationLanguage = localisation.languageCode()
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }
}
