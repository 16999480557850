export const STORAGE_TYPE = {
  LOCAL: 'local',
  SESSION: 'session',
}

function getStorageForType(type, target = window) {
  const storage = `${type}Storage`
  if (!target[storage]) {
    throw new Error(`Storage does not exist: ${storage}`)
  }
  return target[storage]
}

export function setItem(key, value, storageType = STORAGE_TYPE.LOCAL, target = window) {
  const storage = getStorageForType(storageType, target)
  storage.setItem(key, JSON.stringify(value))
}

export function getItem(key, storageType = STORAGE_TYPE.LOCAL) {
  const storage = getStorageForType(storageType)
  const item = storage.getItem(key)
  try {
    return JSON.parse(item)
  } catch (error) {
    console.info('Could not parse item', item)
    return item
  }
}

export function removeItem(key, storageType = STORAGE_TYPE.LOCAL) {
  const storage = getStorageForType(storageType)
  try {
    storage.removeItem(key)
  } catch (error) {
    console.info('Could not remove item ', key)
  }
}
