import API from '~/services/nuxt-api'

export default {
  broadcast(event, payload = {}) {
    return API.post('/prom-event', {
      name: event,
      payload,
    })
  },
}
