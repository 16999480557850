export const REPORTING_QUICK_FILTER = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  CURRENT_WEEK: 'CURRENT_WEEK',
  CURRENT_MONTH: 'CURRENT_MONTH',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  CURRENT_YEAR: 'CURRENT_YEAR',
}

export default {}
