/* eslint-disable */
import { useRouter } from '@nuxtjs/composition-api'
import { COOKIE_CONSENT_ITEM_NAME } from '~/constants/cookieConsent'

export function useTrackingUtils(context) {
  const router = useRouter()
  const { ga, hotjar } = context.root.$config.tracking

  function initiateGTM(ga) {
    if (ga) {
      // Google Tag Manager
      (function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        })
        const f = d.getElementsByTagName(s)[0]
        const j = d.createElement(s)
        const dl = l !== 'dataLayer' ? `&l=${l}` : ''
        j.async = true
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
        f.parentNode.insertBefore(j, f)
      }(window, document, 'script', 'dataLayer', ga))

      router.afterEach((to) => {
        // check and push to gtag dataLayer
        const consents = JSON.parse(localStorage.getItem(COOKIE_CONSENT_ITEM_NAME))
        if (window.dataLayer && consents?.analytical?.state) {
          window.dataLayer.push({
            event: 'Pageview',
            pagePath: to.fullPath,
          })
        }
      })
    }
  }

  function initiateHJ(hotjar) {
    if (hotjar) {
      // Hotjar Tracking
      (function (h, o, t, j, a, r) {
        h.hj = h.hj
          || function () {
            (h.hj.q = h.hj.q || []).push(arguments)
          }
        h._hjSettings = { hjid: hotjar, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      }(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv='))
    }
  }

  function initiateTracking() {
    const consents = JSON.parse(localStorage.getItem(COOKIE_CONSENT_ITEM_NAME))
    if (consents.analytical?.state) {
      initiateGTM(ga)
      initiateHJ(hotjar)
    }
  }

  return {
    initiateTracking,
  }
}
