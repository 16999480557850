import { computed } from '@nuxtjs/composition-api'
import { COUNTRY_CODE } from '~/constants'
import { Context } from '~/types/common'
import { useDashboard } from '../dashboard/Dashboard'

export function useNavigation(context: Context) {
  const { dashboardEnabled } = useDashboard(context)

  const homePage = computed(() => {
    if (context.root.$env.NODE_COUNTRY_ISO_CODE === COUNTRY_CODE.LV) {
      return 'new-application'
    }
    if (dashboardEnabled.value) {
      return 'dashboard'
    }
    return 'overview'
  })

  return {
    homePage,
  }
}

export default {}
