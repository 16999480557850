



























































import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    openingHours: {
      type: String,
      default: '',
    },
    openingHoursEnabled: {
      type: Boolean,
      default: false,
    },
    supportContactsEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { state } = useStore<{menuClosed: boolean}>()
    const isCollapsed = computed(() => state.menuClosed)
    return { isCollapsed }
  },

})
