/* eslint-disable max-classes-per-file */
import Customer from '~/models/Customer'

class NotificationCreditApplicationCreator {
  constructor(args = {}) {
    this.userUuid = args.userUuid || null
    this.userFirstName = args.userFirstName || null
    this.userLastName = args.userLastName || null
  }

  get fullName() {
    return this.userFirstName || this.userLastName
      ? `${this.userFirstName || ''} ${this.userLastName || ''}`
      : null
  }
}
class NotificationCreditApplication {
  constructor(args = {}) {
    this.uuid = args.uuid || null
    this.creditApplicationContractReferenceUuid = args.creditApplicationContractReferenceUuid
      || null
    this.refNo = args.refNo || null
    this.creator = new NotificationCreditApplicationCreator(args.creator)
  }
}
export default class Notification {
  constructor(args = {}) {
    this.id = args.id || null
    this.shopUuid = args.shopUuid || null
    this.type = args.type || null
    this.created = args.created || null
    this.read = Boolean(args.read)
    this.customer = new Customer(args.customer)
    this.creditApplication = new NotificationCreditApplication(args.creditApplication)
  }
}
