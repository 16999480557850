/* eslint-disable import/prefer-default-export */
export const COOKIE_CONSENT_ITEM_NAME = 'INBANKGDPR:accepted'

export const COOKIE_CONSENT_TYPE = {
  FUNCTIONAL: 'functional',
  ANALYTICAL: 'analytical',
}

export const COOKIE_CONSENT_NAME = {
  [COOKIE_CONSENT_TYPE.FUNCTIONAL]: 'partnerPortal.cookieConsentModal.cookies.functional.label',
  [COOKIE_CONSENT_TYPE.ANALYTICAL]: 'partnerPortal.cookieConsentModal.cookies.analytical.label',
}

export const COOKIE_CONSENT_DESCRIPTION = {
  [COOKIE_CONSENT_TYPE.FUNCTIONAL]: 'partnerPortal.cookieConsentModal.cookies.functional.description',
  [COOKIE_CONSENT_TYPE.ANALYTICAL]: 'partnerPortal.cookieConsentModal.cookies.analytical.description',
}

export const COOKIE_CONSENT_BINDING = {
  [COOKIE_CONSENT_TYPE.FUNCTIONAL]: 'functionalCookies',
  [COOKIE_CONSENT_TYPE.ANALYTICAL]: 'analyticalCookies',
}

export const COOKIE_CONSENT_CATEGORY = {
  [COOKIE_CONSENT_TYPE.FUNCTIONAL]: 'functional',
  [COOKIE_CONSENT_TYPE.ANALYTICAL]: 'analytical',
}

export const COOKIE_CONSENT_DEFAULT_STATE = {
  [COOKIE_CONSENT_TYPE.FUNCTIONAL]: true,
  [COOKIE_CONSENT_TYPE.ANALYTICAL]: false,
}

export const COOKIE_CONSENT_DEFAULT_DISABLED = {
  [COOKIE_CONSENT_TYPE.FUNCTIONAL]: true,
  [COOKIE_CONSENT_TYPE.ANALYTICAL]: false,
}
