<template>
  <b-modal
    ref="expireModal"
    v-model="showModal"
    :centered="true"
    tabindex="-1"
    role="dialog"
    footer-class="d-flex justify-content-between p-2"
  >
    <h5 class="text-center">
      {{ t('sessionExpired.modal.title') }}
    </h5>

    <p>{{ t('sessionExpired.modal.text') }}</p>

    <template slot="modal-footer">
      <inb-button
        id="extendSession"
        variant="primary"
        @click="onExtend()"
      >
        {{ t('sessionExpired.modal.extendButton') }}
      </inb-button>
      <inb-button
        id="endSession"
        variant="outline-primary"
        @click="onEnd()"
      >
        {{ t('sessionExpired.modal.endButton') }}
      </inb-button>
    </template>
  </b-modal>
</template>

<script>
import { InbButton } from 'inbank-frontend-commons/components/base-components'

import { useSessionUpdater } from '~/use/SessionUpdater'

import { SESSION_EVENT } from '~/constants/events'

const ALERT_DURATION = 5000

export default {
  name: 'SessionExpiredModal',
  components: { InbButton },
  setup(props, context) {
    return {
      ...useSessionUpdater(context),
    }
  },
  data() {
    return {
      showModal: false,
      unAuthenticating: false,
    }
  },
  mounted() {
    this.$root.$on(SESSION_EVENT.EXPIRING, () => {
      this.showModal = true
    })
  },
  methods: {
    async onExtend() {
      this.showModal = false

      await this.sessionRefresh()

      this.$toast.show(this.t('sessionExpired.alert.sessionExtended'), { duration: ALERT_DURATION })
    },
    async onEnd() {
      if (!this.unAuthenticating) {
        this.unAuthenticating = true
        await this.endSession()
      }
    },
  },
}
</script>

<style>
  .no-margin {
    margin: 0;
  }
</style>
