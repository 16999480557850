import moment from 'moment'

import ApiModel from '~/models/ApiModel'
import Vehicle from '~/models/purchase/Vehicle'

export default class Purchase extends ApiModel {
  constructor(args = {}) {
    super(args)
    this.uuid = args?.uuid || null
    this.purchaseReference = args?.purchaseReference || null
    this.description = args?.description || null
    this.items = args?.items || []
  }

  getApiData(options) {
    const data = super.getApiData(options)
    if (data.items) {
      if (data.items.length === 0) {
        delete data.items
      } else if (data.items.length === 1) {
        // If is empty vehicle item
        const [item] = data.items
        if (item instanceof Vehicle && data.items[0].vehicleRegistrationDate) {
          try {
            data.items[0].vehicleRegistrationDate = moment(data.items[0].vehicleRegistrationDate).format('YYYY')
          } catch (err) {
            console.error('Could not parse vehicle registration date', err)
          }
        }
        if (item instanceof Vehicle && !Object.keys(item).some((key) => key !== 'type' && item[key])) {
          delete data.items
        }
      }
    }
    return data
  }
}
