import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _faf18508 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _03a119a8 = () => interopDefault(import('../pages/cookie-policy/index.vue' /* webpackChunkName: "pages/cookie-policy/index" */))
const _4f179541 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _4cf12654 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _78e6bf10 = () => interopDefault(import('../pages/new-application/index.vue' /* webpackChunkName: "pages/new-application/index" */))
const _61413919 = () => interopDefault(import('../pages/new-business-application/index.vue' /* webpackChunkName: "pages/new-business-application/index" */))
const _01eae9ae = () => interopDefault(import('../pages/new-epos-session/index.vue' /* webpackChunkName: "pages/new-epos-session/index" */))
const _b9d664d6 = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _21d76afc = () => interopDefault(import('../pages/overview/index.vue' /* webpackChunkName: "pages/overview/index" */))
const _16898f4a = () => interopDefault(import('../pages/reporting/index.vue' /* webpackChunkName: "pages/reporting/index" */))
const _3529004e = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _6b14193c = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _728f52ce = () => interopDefault(import('../pages/password/change.vue' /* webpackChunkName: "pages/password/change" */))
const _433e5a1a = () => interopDefault(import('../pages/password/create.vue' /* webpackChunkName: "pages/password/create" */))
const _3e4b0321 = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _07b08178 = () => interopDefault(import('../pages/reporting/payout-report/result.vue' /* webpackChunkName: "pages/reporting/payout-report/result" */))
const _f5805cc0 = () => interopDefault(import('../pages/reporting/sales-report/result.vue' /* webpackChunkName: "pages/reporting/sales-report/result" */))
const _60b51254 = () => interopDefault(import('../pages/application/confirm/_id.vue' /* webpackChunkName: "pages/application/confirm/_id" */))
const _dcf6b2a8 = () => interopDefault(import('../pages/application/decision/_id.vue' /* webpackChunkName: "pages/application/decision/_id" */))
const _49cd267b = () => interopDefault(import('../pages/application/errors/_id.vue' /* webpackChunkName: "pages/application/errors/_id" */))
const _4196406a = () => interopDefault(import('../pages/application/first-rental-payment/_id.vue' /* webpackChunkName: "pages/application/first-rental-payment/_id" */))
const _32fc52ad = () => interopDefault(import('../pages/application/pending/_id.vue' /* webpackChunkName: "pages/application/pending/_id" */))
const _209c6d64 = () => interopDefault(import('../pages/application/rejected/_id.vue' /* webpackChunkName: "pages/application/rejected/_id" */))
const _400ee52c = () => interopDefault(import('../pages/application/signed/_id.vue' /* webpackChunkName: "pages/application/signed/_id" */))
const _e13dffca = () => interopDefault(import('../pages/application/signing/_id.vue' /* webpackChunkName: "pages/application/signing/_id" */))
const _6befe6f1 = () => interopDefault(import('../pages/credit-purchase/confirm/_creditPurchaseUuid/customer/_customerUuid.vue' /* webpackChunkName: "pages/credit-purchase/confirm/_creditPurchaseUuid/customer/_customerUuid" */))
const _d6da8bba = () => interopDefault(import('../pages/credit-purchase/rejected/_creditPurchaseUuid/customer/_customerUuid.vue' /* webpackChunkName: "pages/credit-purchase/rejected/_creditPurchaseUuid/customer/_customerUuid" */))
const _70ab5dc5 = () => interopDefault(import('../pages/credit-purchase/signed/_creditPurchaseUuid/customer/_customerUuid.vue' /* webpackChunkName: "pages/credit-purchase/signed/_creditPurchaseUuid/customer/_customerUuid" */))
const _39e69625 = () => interopDefault(import('../pages/application/_id.vue' /* webpackChunkName: "pages/application/_id" */))
const _5d670b32 = () => interopDefault(import('../pages/printouts/_id.vue' /* webpackChunkName: "pages/printouts/_id" */))
const _4e85c7c2 = () => interopDefault(import('../pages/credit-purchase/_creditPurchaseUuid/customer/_customerUuid.vue' /* webpackChunkName: "pages/credit-purchase/_creditPurchaseUuid/customer/_customerUuid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cs",
    component: _faf18508,
    name: "index___cs"
  }, {
    path: "/en",
    component: _faf18508,
    name: "index___en"
  }, {
    path: "/et",
    component: _faf18508,
    name: "index___et"
  }, {
    path: "/lt",
    component: _faf18508,
    name: "index___lt"
  }, {
    path: "/lv",
    component: _faf18508,
    name: "index___lv"
  }, {
    path: "/pl",
    component: _faf18508,
    name: "index___pl"
  }, {
    path: "/ru",
    component: _faf18508,
    name: "index___ru"
  }, {
    path: "/zh",
    component: _faf18508,
    name: "index___zh"
  }, {
    path: "/cs/cookie-policy",
    component: _03a119a8,
    name: "cookie-policy___cs"
  }, {
    path: "/cs/dashboard",
    component: _4f179541,
    name: "dashboard___cs"
  }, {
    path: "/cs/login",
    component: _4cf12654,
    name: "login___cs"
  }, {
    path: "/cs/new-application",
    component: _78e6bf10,
    name: "new-application___cs"
  }, {
    path: "/cs/new-business-application",
    component: _61413919,
    name: "new-business-application___cs"
  }, {
    path: "/cs/new-epos-session",
    component: _01eae9ae,
    name: "new-epos-session___cs"
  }, {
    path: "/cs/notifications",
    component: _b9d664d6,
    name: "notifications___cs"
  }, {
    path: "/cs/overview",
    component: _21d76afc,
    name: "overview___cs"
  }, {
    path: "/cs/reporting",
    component: _16898f4a,
    name: "reporting___cs"
  }, {
    path: "/cs/store",
    component: _3529004e,
    name: "store___cs"
  }, {
    path: "/cs/support",
    component: _6b14193c,
    name: "support___cs"
  }, {
    path: "/en/cookie-policy",
    component: _03a119a8,
    name: "cookie-policy___en"
  }, {
    path: "/en/dashboard",
    component: _4f179541,
    name: "dashboard___en"
  }, {
    path: "/en/login",
    component: _4cf12654,
    name: "login___en"
  }, {
    path: "/en/new-application",
    component: _78e6bf10,
    name: "new-application___en"
  }, {
    path: "/en/new-business-application",
    component: _61413919,
    name: "new-business-application___en"
  }, {
    path: "/en/new-epos-session",
    component: _01eae9ae,
    name: "new-epos-session___en"
  }, {
    path: "/en/notifications",
    component: _b9d664d6,
    name: "notifications___en"
  }, {
    path: "/en/overview",
    component: _21d76afc,
    name: "overview___en"
  }, {
    path: "/en/reporting",
    component: _16898f4a,
    name: "reporting___en"
  }, {
    path: "/en/store",
    component: _3529004e,
    name: "store___en"
  }, {
    path: "/en/support",
    component: _6b14193c,
    name: "support___en"
  }, {
    path: "/et/cookie-policy",
    component: _03a119a8,
    name: "cookie-policy___et"
  }, {
    path: "/et/dashboard",
    component: _4f179541,
    name: "dashboard___et"
  }, {
    path: "/et/login",
    component: _4cf12654,
    name: "login___et"
  }, {
    path: "/et/new-application",
    component: _78e6bf10,
    name: "new-application___et"
  }, {
    path: "/et/new-business-application",
    component: _61413919,
    name: "new-business-application___et"
  }, {
    path: "/et/new-epos-session",
    component: _01eae9ae,
    name: "new-epos-session___et"
  }, {
    path: "/et/notifications",
    component: _b9d664d6,
    name: "notifications___et"
  }, {
    path: "/et/overview",
    component: _21d76afc,
    name: "overview___et"
  }, {
    path: "/et/reporting",
    component: _16898f4a,
    name: "reporting___et"
  }, {
    path: "/et/store",
    component: _3529004e,
    name: "store___et"
  }, {
    path: "/et/support",
    component: _6b14193c,
    name: "support___et"
  }, {
    path: "/lt/cookie-policy",
    component: _03a119a8,
    name: "cookie-policy___lt"
  }, {
    path: "/lt/dashboard",
    component: _4f179541,
    name: "dashboard___lt"
  }, {
    path: "/lt/login",
    component: _4cf12654,
    name: "login___lt"
  }, {
    path: "/lt/new-application",
    component: _78e6bf10,
    name: "new-application___lt"
  }, {
    path: "/lt/new-business-application",
    component: _61413919,
    name: "new-business-application___lt"
  }, {
    path: "/lt/new-epos-session",
    component: _01eae9ae,
    name: "new-epos-session___lt"
  }, {
    path: "/lt/notifications",
    component: _b9d664d6,
    name: "notifications___lt"
  }, {
    path: "/lt/overview",
    component: _21d76afc,
    name: "overview___lt"
  }, {
    path: "/lt/reporting",
    component: _16898f4a,
    name: "reporting___lt"
  }, {
    path: "/lt/store",
    component: _3529004e,
    name: "store___lt"
  }, {
    path: "/lt/support",
    component: _6b14193c,
    name: "support___lt"
  }, {
    path: "/lv/cookie-policy",
    component: _03a119a8,
    name: "cookie-policy___lv"
  }, {
    path: "/lv/dashboard",
    component: _4f179541,
    name: "dashboard___lv"
  }, {
    path: "/lv/login",
    component: _4cf12654,
    name: "login___lv"
  }, {
    path: "/lv/new-application",
    component: _78e6bf10,
    name: "new-application___lv"
  }, {
    path: "/lv/new-business-application",
    component: _61413919,
    name: "new-business-application___lv"
  }, {
    path: "/lv/new-epos-session",
    component: _01eae9ae,
    name: "new-epos-session___lv"
  }, {
    path: "/lv/notifications",
    component: _b9d664d6,
    name: "notifications___lv"
  }, {
    path: "/lv/overview",
    component: _21d76afc,
    name: "overview___lv"
  }, {
    path: "/lv/reporting",
    component: _16898f4a,
    name: "reporting___lv"
  }, {
    path: "/lv/store",
    component: _3529004e,
    name: "store___lv"
  }, {
    path: "/lv/support",
    component: _6b14193c,
    name: "support___lv"
  }, {
    path: "/pl/cookie-policy",
    component: _03a119a8,
    name: "cookie-policy___pl"
  }, {
    path: "/pl/dashboard",
    component: _4f179541,
    name: "dashboard___pl"
  }, {
    path: "/pl/login",
    component: _4cf12654,
    name: "login___pl"
  }, {
    path: "/pl/new-application",
    component: _78e6bf10,
    name: "new-application___pl"
  }, {
    path: "/pl/new-business-application",
    component: _61413919,
    name: "new-business-application___pl"
  }, {
    path: "/pl/new-epos-session",
    component: _01eae9ae,
    name: "new-epos-session___pl"
  }, {
    path: "/pl/notifications",
    component: _b9d664d6,
    name: "notifications___pl"
  }, {
    path: "/pl/overview",
    component: _21d76afc,
    name: "overview___pl"
  }, {
    path: "/pl/reporting",
    component: _16898f4a,
    name: "reporting___pl"
  }, {
    path: "/pl/store",
    component: _3529004e,
    name: "store___pl"
  }, {
    path: "/pl/support",
    component: _6b14193c,
    name: "support___pl"
  }, {
    path: "/ru/cookie-policy",
    component: _03a119a8,
    name: "cookie-policy___ru"
  }, {
    path: "/ru/dashboard",
    component: _4f179541,
    name: "dashboard___ru"
  }, {
    path: "/ru/login",
    component: _4cf12654,
    name: "login___ru"
  }, {
    path: "/ru/new-application",
    component: _78e6bf10,
    name: "new-application___ru"
  }, {
    path: "/ru/new-business-application",
    component: _61413919,
    name: "new-business-application___ru"
  }, {
    path: "/ru/new-epos-session",
    component: _01eae9ae,
    name: "new-epos-session___ru"
  }, {
    path: "/ru/notifications",
    component: _b9d664d6,
    name: "notifications___ru"
  }, {
    path: "/ru/overview",
    component: _21d76afc,
    name: "overview___ru"
  }, {
    path: "/ru/reporting",
    component: _16898f4a,
    name: "reporting___ru"
  }, {
    path: "/ru/store",
    component: _3529004e,
    name: "store___ru"
  }, {
    path: "/ru/support",
    component: _6b14193c,
    name: "support___ru"
  }, {
    path: "/zh/cookie-policy",
    component: _03a119a8,
    name: "cookie-policy___zh"
  }, {
    path: "/zh/dashboard",
    component: _4f179541,
    name: "dashboard___zh"
  }, {
    path: "/zh/login",
    component: _4cf12654,
    name: "login___zh"
  }, {
    path: "/zh/new-application",
    component: _78e6bf10,
    name: "new-application___zh"
  }, {
    path: "/zh/new-business-application",
    component: _61413919,
    name: "new-business-application___zh"
  }, {
    path: "/zh/new-epos-session",
    component: _01eae9ae,
    name: "new-epos-session___zh"
  }, {
    path: "/zh/notifications",
    component: _b9d664d6,
    name: "notifications___zh"
  }, {
    path: "/zh/overview",
    component: _21d76afc,
    name: "overview___zh"
  }, {
    path: "/zh/reporting",
    component: _16898f4a,
    name: "reporting___zh"
  }, {
    path: "/zh/store",
    component: _3529004e,
    name: "store___zh"
  }, {
    path: "/zh/support",
    component: _6b14193c,
    name: "support___zh"
  }, {
    path: "/cs/password/change",
    component: _728f52ce,
    name: "password-change___cs"
  }, {
    path: "/cs/password/create",
    component: _433e5a1a,
    name: "password-create___cs"
  }, {
    path: "/cs/password/reset",
    component: _3e4b0321,
    name: "password-reset___cs"
  }, {
    path: "/en/password/change",
    component: _728f52ce,
    name: "password-change___en"
  }, {
    path: "/en/password/create",
    component: _433e5a1a,
    name: "password-create___en"
  }, {
    path: "/en/password/reset",
    component: _3e4b0321,
    name: "password-reset___en"
  }, {
    path: "/et/password/change",
    component: _728f52ce,
    name: "password-change___et"
  }, {
    path: "/et/password/create",
    component: _433e5a1a,
    name: "password-create___et"
  }, {
    path: "/et/password/reset",
    component: _3e4b0321,
    name: "password-reset___et"
  }, {
    path: "/lt/password/change",
    component: _728f52ce,
    name: "password-change___lt"
  }, {
    path: "/lt/password/create",
    component: _433e5a1a,
    name: "password-create___lt"
  }, {
    path: "/lt/password/reset",
    component: _3e4b0321,
    name: "password-reset___lt"
  }, {
    path: "/lv/password/change",
    component: _728f52ce,
    name: "password-change___lv"
  }, {
    path: "/lv/password/create",
    component: _433e5a1a,
    name: "password-create___lv"
  }, {
    path: "/lv/password/reset",
    component: _3e4b0321,
    name: "password-reset___lv"
  }, {
    path: "/pl/password/change",
    component: _728f52ce,
    name: "password-change___pl"
  }, {
    path: "/pl/password/create",
    component: _433e5a1a,
    name: "password-create___pl"
  }, {
    path: "/pl/password/reset",
    component: _3e4b0321,
    name: "password-reset___pl"
  }, {
    path: "/ru/password/change",
    component: _728f52ce,
    name: "password-change___ru"
  }, {
    path: "/ru/password/create",
    component: _433e5a1a,
    name: "password-create___ru"
  }, {
    path: "/ru/password/reset",
    component: _3e4b0321,
    name: "password-reset___ru"
  }, {
    path: "/zh/password/change",
    component: _728f52ce,
    name: "password-change___zh"
  }, {
    path: "/zh/password/create",
    component: _433e5a1a,
    name: "password-create___zh"
  }, {
    path: "/zh/password/reset",
    component: _3e4b0321,
    name: "password-reset___zh"
  }, {
    path: "/cs/reporting/payout-report/result",
    component: _07b08178,
    name: "reporting-payout-report-result___cs"
  }, {
    path: "/cs/reporting/sales-report/result",
    component: _f5805cc0,
    name: "reporting-sales-report-result___cs"
  }, {
    path: "/en/reporting/payout-report/result",
    component: _07b08178,
    name: "reporting-payout-report-result___en"
  }, {
    path: "/en/reporting/sales-report/result",
    component: _f5805cc0,
    name: "reporting-sales-report-result___en"
  }, {
    path: "/et/reporting/payout-report/result",
    component: _07b08178,
    name: "reporting-payout-report-result___et"
  }, {
    path: "/et/reporting/sales-report/result",
    component: _f5805cc0,
    name: "reporting-sales-report-result___et"
  }, {
    path: "/lt/reporting/payout-report/result",
    component: _07b08178,
    name: "reporting-payout-report-result___lt"
  }, {
    path: "/lt/reporting/sales-report/result",
    component: _f5805cc0,
    name: "reporting-sales-report-result___lt"
  }, {
    path: "/lv/reporting/payout-report/result",
    component: _07b08178,
    name: "reporting-payout-report-result___lv"
  }, {
    path: "/lv/reporting/sales-report/result",
    component: _f5805cc0,
    name: "reporting-sales-report-result___lv"
  }, {
    path: "/pl/reporting/payout-report/result",
    component: _07b08178,
    name: "reporting-payout-report-result___pl"
  }, {
    path: "/pl/reporting/sales-report/result",
    component: _f5805cc0,
    name: "reporting-sales-report-result___pl"
  }, {
    path: "/ru/reporting/payout-report/result",
    component: _07b08178,
    name: "reporting-payout-report-result___ru"
  }, {
    path: "/ru/reporting/sales-report/result",
    component: _f5805cc0,
    name: "reporting-sales-report-result___ru"
  }, {
    path: "/zh/reporting/payout-report/result",
    component: _07b08178,
    name: "reporting-payout-report-result___zh"
  }, {
    path: "/zh/reporting/sales-report/result",
    component: _f5805cc0,
    name: "reporting-sales-report-result___zh"
  }, {
    path: "/cs/application/confirm/:id?",
    component: _60b51254,
    name: "application-confirm-id___cs"
  }, {
    path: "/cs/application/decision/:id?",
    component: _dcf6b2a8,
    name: "application-decision-id___cs"
  }, {
    path: "/cs/application/errors/:id?",
    component: _49cd267b,
    name: "application-errors-id___cs"
  }, {
    path: "/cs/application/first-rental-payment/:id?",
    component: _4196406a,
    name: "application-first-rental-payment-id___cs"
  }, {
    path: "/cs/application/pending/:id?",
    component: _32fc52ad,
    name: "application-pending-id___cs"
  }, {
    path: "/cs/application/rejected/:id?",
    component: _209c6d64,
    name: "application-rejected-id___cs"
  }, {
    path: "/cs/application/signed/:id?",
    component: _400ee52c,
    name: "application-signed-id___cs"
  }, {
    path: "/cs/application/signing/:id?",
    component: _e13dffca,
    name: "application-signing-id___cs"
  }, {
    path: "/en/application/confirm/:id?",
    component: _60b51254,
    name: "application-confirm-id___en"
  }, {
    path: "/en/application/decision/:id?",
    component: _dcf6b2a8,
    name: "application-decision-id___en"
  }, {
    path: "/en/application/errors/:id?",
    component: _49cd267b,
    name: "application-errors-id___en"
  }, {
    path: "/en/application/first-rental-payment/:id?",
    component: _4196406a,
    name: "application-first-rental-payment-id___en"
  }, {
    path: "/en/application/pending/:id?",
    component: _32fc52ad,
    name: "application-pending-id___en"
  }, {
    path: "/en/application/rejected/:id?",
    component: _209c6d64,
    name: "application-rejected-id___en"
  }, {
    path: "/en/application/signed/:id?",
    component: _400ee52c,
    name: "application-signed-id___en"
  }, {
    path: "/en/application/signing/:id?",
    component: _e13dffca,
    name: "application-signing-id___en"
  }, {
    path: "/et/application/confirm/:id?",
    component: _60b51254,
    name: "application-confirm-id___et"
  }, {
    path: "/et/application/decision/:id?",
    component: _dcf6b2a8,
    name: "application-decision-id___et"
  }, {
    path: "/et/application/errors/:id?",
    component: _49cd267b,
    name: "application-errors-id___et"
  }, {
    path: "/et/application/first-rental-payment/:id?",
    component: _4196406a,
    name: "application-first-rental-payment-id___et"
  }, {
    path: "/et/application/pending/:id?",
    component: _32fc52ad,
    name: "application-pending-id___et"
  }, {
    path: "/et/application/rejected/:id?",
    component: _209c6d64,
    name: "application-rejected-id___et"
  }, {
    path: "/et/application/signed/:id?",
    component: _400ee52c,
    name: "application-signed-id___et"
  }, {
    path: "/et/application/signing/:id?",
    component: _e13dffca,
    name: "application-signing-id___et"
  }, {
    path: "/lt/application/confirm/:id?",
    component: _60b51254,
    name: "application-confirm-id___lt"
  }, {
    path: "/lt/application/decision/:id?",
    component: _dcf6b2a8,
    name: "application-decision-id___lt"
  }, {
    path: "/lt/application/errors/:id?",
    component: _49cd267b,
    name: "application-errors-id___lt"
  }, {
    path: "/lt/application/first-rental-payment/:id?",
    component: _4196406a,
    name: "application-first-rental-payment-id___lt"
  }, {
    path: "/lt/application/pending/:id?",
    component: _32fc52ad,
    name: "application-pending-id___lt"
  }, {
    path: "/lt/application/rejected/:id?",
    component: _209c6d64,
    name: "application-rejected-id___lt"
  }, {
    path: "/lt/application/signed/:id?",
    component: _400ee52c,
    name: "application-signed-id___lt"
  }, {
    path: "/lt/application/signing/:id?",
    component: _e13dffca,
    name: "application-signing-id___lt"
  }, {
    path: "/lv/application/confirm/:id?",
    component: _60b51254,
    name: "application-confirm-id___lv"
  }, {
    path: "/lv/application/decision/:id?",
    component: _dcf6b2a8,
    name: "application-decision-id___lv"
  }, {
    path: "/lv/application/errors/:id?",
    component: _49cd267b,
    name: "application-errors-id___lv"
  }, {
    path: "/lv/application/first-rental-payment/:id?",
    component: _4196406a,
    name: "application-first-rental-payment-id___lv"
  }, {
    path: "/lv/application/pending/:id?",
    component: _32fc52ad,
    name: "application-pending-id___lv"
  }, {
    path: "/lv/application/rejected/:id?",
    component: _209c6d64,
    name: "application-rejected-id___lv"
  }, {
    path: "/lv/application/signed/:id?",
    component: _400ee52c,
    name: "application-signed-id___lv"
  }, {
    path: "/lv/application/signing/:id?",
    component: _e13dffca,
    name: "application-signing-id___lv"
  }, {
    path: "/pl/application/confirm/:id?",
    component: _60b51254,
    name: "application-confirm-id___pl"
  }, {
    path: "/pl/application/decision/:id?",
    component: _dcf6b2a8,
    name: "application-decision-id___pl"
  }, {
    path: "/pl/application/errors/:id?",
    component: _49cd267b,
    name: "application-errors-id___pl"
  }, {
    path: "/pl/application/first-rental-payment/:id?",
    component: _4196406a,
    name: "application-first-rental-payment-id___pl"
  }, {
    path: "/pl/application/pending/:id?",
    component: _32fc52ad,
    name: "application-pending-id___pl"
  }, {
    path: "/pl/application/rejected/:id?",
    component: _209c6d64,
    name: "application-rejected-id___pl"
  }, {
    path: "/pl/application/signed/:id?",
    component: _400ee52c,
    name: "application-signed-id___pl"
  }, {
    path: "/pl/application/signing/:id?",
    component: _e13dffca,
    name: "application-signing-id___pl"
  }, {
    path: "/ru/application/confirm/:id?",
    component: _60b51254,
    name: "application-confirm-id___ru"
  }, {
    path: "/ru/application/decision/:id?",
    component: _dcf6b2a8,
    name: "application-decision-id___ru"
  }, {
    path: "/ru/application/errors/:id?",
    component: _49cd267b,
    name: "application-errors-id___ru"
  }, {
    path: "/ru/application/first-rental-payment/:id?",
    component: _4196406a,
    name: "application-first-rental-payment-id___ru"
  }, {
    path: "/ru/application/pending/:id?",
    component: _32fc52ad,
    name: "application-pending-id___ru"
  }, {
    path: "/ru/application/rejected/:id?",
    component: _209c6d64,
    name: "application-rejected-id___ru"
  }, {
    path: "/ru/application/signed/:id?",
    component: _400ee52c,
    name: "application-signed-id___ru"
  }, {
    path: "/ru/application/signing/:id?",
    component: _e13dffca,
    name: "application-signing-id___ru"
  }, {
    path: "/zh/application/confirm/:id?",
    component: _60b51254,
    name: "application-confirm-id___zh"
  }, {
    path: "/zh/application/decision/:id?",
    component: _dcf6b2a8,
    name: "application-decision-id___zh"
  }, {
    path: "/zh/application/errors/:id?",
    component: _49cd267b,
    name: "application-errors-id___zh"
  }, {
    path: "/zh/application/first-rental-payment/:id?",
    component: _4196406a,
    name: "application-first-rental-payment-id___zh"
  }, {
    path: "/zh/application/pending/:id?",
    component: _32fc52ad,
    name: "application-pending-id___zh"
  }, {
    path: "/zh/application/rejected/:id?",
    component: _209c6d64,
    name: "application-rejected-id___zh"
  }, {
    path: "/zh/application/signed/:id?",
    component: _400ee52c,
    name: "application-signed-id___zh"
  }, {
    path: "/zh/application/signing/:id?",
    component: _e13dffca,
    name: "application-signing-id___zh"
  }, {
    path: "/cs/credit-purchase/confirm/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _6befe6f1,
    name: "credit-purchase-confirm-creditPurchaseUuid-customer-customerUuid___cs"
  }, {
    path: "/cs/credit-purchase/rejected/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _d6da8bba,
    name: "credit-purchase-rejected-creditPurchaseUuid-customer-customerUuid___cs"
  }, {
    path: "/cs/credit-purchase/signed/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _70ab5dc5,
    name: "credit-purchase-signed-creditPurchaseUuid-customer-customerUuid___cs"
  }, {
    path: "/en/credit-purchase/confirm/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _6befe6f1,
    name: "credit-purchase-confirm-creditPurchaseUuid-customer-customerUuid___en"
  }, {
    path: "/en/credit-purchase/rejected/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _d6da8bba,
    name: "credit-purchase-rejected-creditPurchaseUuid-customer-customerUuid___en"
  }, {
    path: "/en/credit-purchase/signed/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _70ab5dc5,
    name: "credit-purchase-signed-creditPurchaseUuid-customer-customerUuid___en"
  }, {
    path: "/et/credit-purchase/confirm/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _6befe6f1,
    name: "credit-purchase-confirm-creditPurchaseUuid-customer-customerUuid___et"
  }, {
    path: "/et/credit-purchase/rejected/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _d6da8bba,
    name: "credit-purchase-rejected-creditPurchaseUuid-customer-customerUuid___et"
  }, {
    path: "/et/credit-purchase/signed/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _70ab5dc5,
    name: "credit-purchase-signed-creditPurchaseUuid-customer-customerUuid___et"
  }, {
    path: "/lt/credit-purchase/confirm/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _6befe6f1,
    name: "credit-purchase-confirm-creditPurchaseUuid-customer-customerUuid___lt"
  }, {
    path: "/lt/credit-purchase/rejected/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _d6da8bba,
    name: "credit-purchase-rejected-creditPurchaseUuid-customer-customerUuid___lt"
  }, {
    path: "/lt/credit-purchase/signed/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _70ab5dc5,
    name: "credit-purchase-signed-creditPurchaseUuid-customer-customerUuid___lt"
  }, {
    path: "/lv/credit-purchase/confirm/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _6befe6f1,
    name: "credit-purchase-confirm-creditPurchaseUuid-customer-customerUuid___lv"
  }, {
    path: "/lv/credit-purchase/rejected/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _d6da8bba,
    name: "credit-purchase-rejected-creditPurchaseUuid-customer-customerUuid___lv"
  }, {
    path: "/lv/credit-purchase/signed/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _70ab5dc5,
    name: "credit-purchase-signed-creditPurchaseUuid-customer-customerUuid___lv"
  }, {
    path: "/pl/credit-purchase/confirm/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _6befe6f1,
    name: "credit-purchase-confirm-creditPurchaseUuid-customer-customerUuid___pl"
  }, {
    path: "/pl/credit-purchase/rejected/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _d6da8bba,
    name: "credit-purchase-rejected-creditPurchaseUuid-customer-customerUuid___pl"
  }, {
    path: "/pl/credit-purchase/signed/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _70ab5dc5,
    name: "credit-purchase-signed-creditPurchaseUuid-customer-customerUuid___pl"
  }, {
    path: "/ru/credit-purchase/confirm/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _6befe6f1,
    name: "credit-purchase-confirm-creditPurchaseUuid-customer-customerUuid___ru"
  }, {
    path: "/ru/credit-purchase/rejected/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _d6da8bba,
    name: "credit-purchase-rejected-creditPurchaseUuid-customer-customerUuid___ru"
  }, {
    path: "/ru/credit-purchase/signed/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _70ab5dc5,
    name: "credit-purchase-signed-creditPurchaseUuid-customer-customerUuid___ru"
  }, {
    path: "/zh/credit-purchase/confirm/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _6befe6f1,
    name: "credit-purchase-confirm-creditPurchaseUuid-customer-customerUuid___zh"
  }, {
    path: "/zh/credit-purchase/rejected/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _d6da8bba,
    name: "credit-purchase-rejected-creditPurchaseUuid-customer-customerUuid___zh"
  }, {
    path: "/zh/credit-purchase/signed/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _70ab5dc5,
    name: "credit-purchase-signed-creditPurchaseUuid-customer-customerUuid___zh"
  }, {
    path: "/cs/application/:id?",
    component: _39e69625,
    name: "application-id___cs"
  }, {
    path: "/cs/printouts/:id?",
    component: _5d670b32,
    name: "printouts-id___cs"
  }, {
    path: "/en/application/:id?",
    component: _39e69625,
    name: "application-id___en"
  }, {
    path: "/en/printouts/:id?",
    component: _5d670b32,
    name: "printouts-id___en"
  }, {
    path: "/et/application/:id?",
    component: _39e69625,
    name: "application-id___et"
  }, {
    path: "/et/printouts/:id?",
    component: _5d670b32,
    name: "printouts-id___et"
  }, {
    path: "/lt/application/:id?",
    component: _39e69625,
    name: "application-id___lt"
  }, {
    path: "/lt/printouts/:id?",
    component: _5d670b32,
    name: "printouts-id___lt"
  }, {
    path: "/lv/application/:id?",
    component: _39e69625,
    name: "application-id___lv"
  }, {
    path: "/lv/printouts/:id?",
    component: _5d670b32,
    name: "printouts-id___lv"
  }, {
    path: "/pl/application/:id?",
    component: _39e69625,
    name: "application-id___pl"
  }, {
    path: "/pl/printouts/:id?",
    component: _5d670b32,
    name: "printouts-id___pl"
  }, {
    path: "/ru/application/:id?",
    component: _39e69625,
    name: "application-id___ru"
  }, {
    path: "/ru/printouts/:id?",
    component: _5d670b32,
    name: "printouts-id___ru"
  }, {
    path: "/zh/application/:id?",
    component: _39e69625,
    name: "application-id___zh"
  }, {
    path: "/zh/printouts/:id?",
    component: _5d670b32,
    name: "printouts-id___zh"
  }, {
    path: "/cs/credit-purchase/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _4e85c7c2,
    name: "credit-purchase-creditPurchaseUuid-customer-customerUuid___cs"
  }, {
    path: "/en/credit-purchase/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _4e85c7c2,
    name: "credit-purchase-creditPurchaseUuid-customer-customerUuid___en"
  }, {
    path: "/et/credit-purchase/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _4e85c7c2,
    name: "credit-purchase-creditPurchaseUuid-customer-customerUuid___et"
  }, {
    path: "/lt/credit-purchase/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _4e85c7c2,
    name: "credit-purchase-creditPurchaseUuid-customer-customerUuid___lt"
  }, {
    path: "/lv/credit-purchase/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _4e85c7c2,
    name: "credit-purchase-creditPurchaseUuid-customer-customerUuid___lv"
  }, {
    path: "/pl/credit-purchase/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _4e85c7c2,
    name: "credit-purchase-creditPurchaseUuid-customer-customerUuid___pl"
  }, {
    path: "/ru/credit-purchase/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _4e85c7c2,
    name: "credit-purchase-creditPurchaseUuid-customer-customerUuid___ru"
  }, {
    path: "/zh/credit-purchase/:creditPurchaseUuid?/customer/:customerUuid?",
    component: _4e85c7c2,
    name: "credit-purchase-creditPurchaseUuid-customer-customerUuid___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
