/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Product from '~/models/Product'

export const state = () => ({
  product: new Product(),
})

export const mutations = {
  setProduct(state, product) {
    state.product = new Product({ ...product })
  },
}

export const actions = {
  setProduct({ commit }, product) {
    commit('setProduct', product)
  },
}
