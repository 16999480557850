<template>
  <div />
</template>
<script>
import _ from 'lodash'

import { useSessionUpdater } from '~/use/SessionUpdater'

const DEBOUNCE_TIME = 10000
export default {
  name: 'SessionUpdater',
  setup(props, context) {
    return {
      ...useSessionUpdater(context),
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll: _.debounce(function () {
      this.backgroundSessionRefresh()
    }, DEBOUNCE_TIME, { leading: true, trailing: false }),
  },
}
</script>
