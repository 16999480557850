/* eslint-disable no-magic-numbers */
import API from '~/services/api'
import RentalCalculation from '~/models/rental/RentalCalculation'
import RentalCalculationRequest from '~/models/rental/RentalCalculationRequest'
import RentalProduct from '~/models/rental/RentalProduct'
import { RentalQuote, Identifier } from '~/models/rental/RentalQuote'
import RentalQuoteRequest from '~/models/rental/RentalQuoteRequest'
import RentalCalculationRequestV2 from '~/models/rental/RentalCalculationRequestV2'
import RentalCalculationV2 from '~/models/rental/RentalCalculationV2'

export interface RentalModelParameters {
  shopUuid: string
  productCode: string
  manufacturerId: string
  categoryCode: string
}

export default {
  async getRentalProductByCodeV2(shopUuid: string, productCode: string): Promise<RentalProduct> {
    const response = await API.get(`/rental/v2/shops/${shopUuid}/products/${productCode}`)
    return response.data
  },

  async getRentalDeviceModels(params: RentalModelParameters) {
    const { data } = await API.get(
      `/rental/v2/shops/${params.shopUuid}/products/${params.productCode}/asset-models?manufacturerId=${params.manufacturerId}&categoryCode=${params.categoryCode}`,
    )
    return data
  },

  async getRentalModelById(shopUuid: string, productCode: string, modelId: string) {
    const { data } = await API.get(`/rental/v2/shops/${shopUuid}/products/${productCode}/asset-models/${modelId}`)
    return data
  },

  async calculate(payload: RentalCalculationRequest): Promise<RentalCalculation> {
    const response = await API.post('/rental/v1/calculations', payload)
    return response.data
  },

  async calculateV2(
    shopUuid: string,
    productCode: string,
    payload: RentalCalculationRequestV2,
  ): Promise<RentalCalculationV2> {
    const { data } = await API.post(`/rental/v2/shops/${shopUuid}/products/${productCode}/quotes/calculation`, payload)
    return data
  },

  async createRentalQuote2(shopUuid: string, productCode: string, request: RentalQuoteRequest) {
    const response = await API.post(`/rental/v2/shops/${shopUuid}/products/${productCode}/quotes`, request)

    return response.data
  },

  async getRentalQuote(rentalQuoteUuid: string): Promise<RentalQuote> {
    const response = await API.get(`/rental/v2/quotes/${rentalQuoteUuid}`)
    return response.data
  },

  async collectAssets(rentalQuoteUuid: string, assetUuids: string[]) {
    const response = await API.patch(`/rental/v2/quotes/${rentalQuoteUuid}/assets/collection`, assetUuids)
    return response.data
  },

  async getEventLogs(rentalQuoteUuid: string, assetUuid: string, eventLogEntryType: string) {
    const response = await API.get(`/rental/v2/event-log/quotes/${rentalQuoteUuid}?assetUuid=${assetUuid}&eventLogEntryTypes=${eventLogEntryType}`)
    return response.data
  },

  updateIdentifiers(shopUuid: string, assetUuid: string, identifiers: Identifier[]) {
    return API.patch(`/rental/v2/shops/${shopUuid}/assets/${assetUuid}/identifiers`, identifiers)
  },

  async getRenewable(
    shopUuid: string,
    productCode: string,
    assetCategoryCode: string,
    customerUuid: string,
  ) {
    const { data } = await API.get(`/rental/v2/shops/${shopUuid}/products/${productCode}/quotes/renewable?assetCategoryCode=${assetCategoryCode}&customerUuid=${customerUuid}`)
    return data
  },
}
