<template>
  <div>
    <session-updater />
    <session-expired-modal />

    <b-navbar
      toggleable="sm"
      variant="light"
      class="global-nav navbar-main flex-nowrap justify-content-between px-2 px-md-3"
    >
      <b-navbar-brand :to="localePath('store')" class="global-nav__brand p-0">
        <img src="~/static/images/logo/inbank-partner-logo.svg" width="" height="36" alt="Inbank">
      </b-navbar-brand>

      <b-navbar-nav class="navbar-nav flex-row main-nav justify-content-end w-100 minw-0">
        <b-dropdown
          v-if="$i18n.locales.length > 1"
          variant="link"
          class="main-nav"
          toggle-class="global-nav__dd px-2 regular text-gray-medium"
          right
        >
          <template slot="button-content">
            <span class="icons icon--nav icon-user-circle text-gray px-0 px-md-2" />
            <span class="d-none d-md-inline">{{ safeUser.fullName }}</span>
          </template>

          <ul class="list-inline text-center py-2 px-3">
            <client-only v-for="(locale, index) in $i18n.locales" :key="index">
              <li
                :class="{ 'global-nav__dd-item--disabled': $i18n.locale == locale.code }"
                class="small text-uppercase"
              >
                <a href="javascript:void(0)" @click="switchLanguage(locale.code)">
                  {{ locale.code }}
                </a>
              </li>
            </client-only>
          </ul>
        </b-dropdown>

        <inb-button
          v-else-if="$i18n.locales.length <= 1"
          variant="link"
          toggle-class="px-1 px-md-2 regular text-gray-medium"
        >
          <span class="icons icon--nav icon-user-circle text-gray px-0 px-md-2" />
          <span>{{ safeUser.fullName }}</span>
        </inb-button>

        <inb-button
          variant="link"
          class="main-nav pl-3 pr-1 regular text-gray-medium"
          @click="logout"
        >
          <span class="d-none d-md-inline">{{ t('links.logout') }}</span>
          <span class="icons icon--nav icon-sign-out text-gray px-0 pl-md-2" />
        </inb-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col d-flex flex-column minw-0">
          <nuxt />
        </div>
      </div>
    </div>
    <app-footer />
    <cookie-consents-modal />
  </div>
</template>

<script>
import { InbButton } from 'inbank-frontend-commons/components/base-components'
import AppFooter from '~/components/common/AppFooter.vue'
import CookieConsentsModal from '~/components/modals/CookieConsentsModal.vue'

import User from '~/models/User'

import { useAuthLayout } from '~/use/authentication/Authentication'
import { useLanguage } from '~/use/Language'

export default {
  components: { InbButton, CookieConsentsModal, AppFooter },
  setup(props, context) {
    const { switchLanguage } = useLanguage(context)
    return {
      ...useAuthLayout(context),
      switchLanguage,
    }
  },
  computed: {
    safeUser() {
      return this.user || new User()
    },
  },
}
</script>
