import API from '~/services/api'

interface SmartIdAuthArgs {
  personalCode: string
  country: string
}

interface MobileIdAuthArgs {
  personalCode: string
  phoneNumber: string
}

interface PasswordAuthArgs {
  username: string
  password: string
  deviceCode: string
  context: { sessionLength: number }
}

interface AuthInitResponse {
  controlCode: string
}
export interface AuthStatusResponse {
  status: string
}

interface SmartCardAuthArgs {
  certificate: string
}

interface DokobitSmartCardAuthArgs {
  token: string
}

export default {
  async authenticateViaSmartId(payload: SmartIdAuthArgs): Promise<AuthInitResponse> {
    const response = await API.post('/auth/smartid', payload)
    return response.data
  },
  async checkSmartIdStatus(): Promise<AuthStatusResponse> {
    const response = await API.get('/auth/status/smartid')
    return response.data
  },
  async authenticateViaMobileId(payload: MobileIdAuthArgs): Promise<AuthInitResponse> {
    const response = await API.post('/auth/mobileid', payload)
    return response.data
  },
  async checkMobileIdStatus(): Promise<AuthStatusResponse> {
    const response = await API.get('/auth/status/mobileid')
    return response.data
  },
  async authenticateViaPassword(payload: PasswordAuthArgs) {
    const response = await API.post('/auth/password', payload)
    return response.data
  },
  async authenticateViaSmartCard(payload: SmartCardAuthArgs) {
    const response = await API.post('/auth/smartcard', payload)
    return response.data
  },
  async checkSmartCardStatus(payload: { signature: string }) {
    const response = await API.post('/auth/status/smartcard', payload)
    return response.data
  },
  async authenticateViaDokobitSmartCard(payload: DokobitSmartCardAuthArgs) {
    const response = await API.post('/auth/smartcard/token', payload)
    return response.data
  },
  async logout(store: any) {
    await API.post('/auth/logout')
    if (store) {
      store.dispatch('auth/logout')
    }
  },
}
